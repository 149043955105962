import {
	ACTION_POST_CREATED,
	ACTION_POST_DELETED,
	ACTION_POST_UPDATED,
	ACTION_VISIBLE_POSTS_UPDATED
} from './action_types';
import Post from '../pouchDB/models/Post';

export const visiblePostUpdatedAction = (posts) => ({
	type: ACTION_VISIBLE_POSTS_UPDATED,
	posts: posts
});

export const postCreated = (post) => ({
	type: ACTION_POST_CREATED,
	post: post
});

export const postUpdated = (postIndex, post) => ({
	type: ACTION_POST_UPDATED,
	postIndex: postIndex,
	post: post
});

export const postDeleted = (postIndex) => ({
	type: ACTION_POST_DELETED,
	postIndex: postIndex
});

export const fetchPostsMAction = () => {
	return (dispatch, getState) => {
		Post.fetchPosts(getState().auth.userId)
			.then((posts) => {
				dispatch(visiblePostUpdatedAction(posts));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};

export const createPostMAction = (text, notificationText, link, brand, postType, selectedEvent) => {
	return (dispatch, getState) => {
		return Post.createPost(
			getState().auth.userId,
			text,
			notificationText,
			link,
			brand,
			postType,
			selectedEvent
		).then((post) => {
			dispatch(postCreated(post));
			return Promise.resolve(post);
		});
	};
};

export const editPostMAction = (
	postIndex,
	postDocId,
	text,
	notificationText,
	link,
	brand,
	postType,
	selectedEvent
) => {
	return (dispatch, getState) => {
		return Post.updatePost(
			postDocId,
			getState().auth.userId,
			text,
			notificationText,
			link,
			brand,
			postType,
			selectedEvent
		).then((post) => {
			dispatch(postUpdated(postIndex, post));
			return Promise.resolve(post);
		});
	};
};

export const deletePostMAction = (postIndex, postDocId) => {
	return (dispatch, getState) => {
		Post.deletePost(getState().auth.userId, postDocId)
			.then(() => {
				dispatch(postDeleted(postIndex));
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
			});
	};
};
