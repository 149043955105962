import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../reducers/rootReducer'
import { routerMiddleware } from 'connected-react-router'

const configureStore = (history, preloadedState) => createStore(
   rootReducer(history),
   preloadedState,
   applyMiddleware(thunk, routerMiddleware(history))
)

export default configureStore
