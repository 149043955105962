import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';

import { Row, Col } from 'antd';
import { Input, Modal, Button, message, Select } from 'antd';
import {
	createBillingDetailsMAction,
	updateBillingDetailsMAction
} from '../actions/BillingDetailsActions';
import '../styles/EventHome.sass';

import { isValidGSTNumber, getStateFromGSTN } from '../helpers/BillingValidation';

import query from 'india-pincode-search';

// import pincodeData from '../data/pincode_db.json';
const { Option } = Select;

const FormItem = Form.Item;

class AddBillingDetails extends Component {
	state = {
		loading: false,
		isOtherState: false,
		stateDisabled: false,
		billingDetails: null
	};

	stateList = [
		'Andaman Nicobar',
		'Andhra Pradesh',
		'Andhra Pradesh (New)',
		'Arunachal Pradesh',
		'Assam',
		'Bihar',
		'Chandigarh',
		'Chhattisgarh',
		'Dadra Nagar Haveli',
		'Daman and Diu',
		'Delhi',
		'Goa',
		'Gujarat',
		'Haryana',
		'Himachal Pradesh',
		'Jammu and Kashmir',
		'Jharkhand',
		'Karnataka',
		'Kerala',
		'Ladakh',
		'Lakshadweep Islands',
		'Madhya Pradesh',
		'Maharashtra',
		'Manipur',
		'Meghalaya',
		'Mizoram',
		'Nagaland',
		'Odisha',
		'Pondicherry',
		'Punjab',
		'Rajasthan',
		'Sikkim',
		'Tamil Nadu',
		'Telangana',
		'Tripura',
		'Uttar Pradesh',
		'Uttarakhand',
		'West Bengal',
		'other'
	];

	// getCityAndStateFromPincode = (pincode) => {
	// 	const pincodeEntry = pincodeData.find((entry) => entry.pincode === pincode);
	// 	if (pincodeEntry) {
	// 		return {
	// 			city: pincodeEntry.city,
	// 			state: pincodeEntry.state
	// 		};
	// 	}
	// 	return {
	// 		city: '',
	// 		state: ''
	// 	};
	// };

	getCityAndStateFromPincode = (pincode) => {
		const results = query.search(pincode);
		if (results && results.length > 0) {
			return {
				city: results[0].city,
				state: results[0].state
			};
		}
		return {
			city: '',
			state: ''
		};
	};

	handlePincodeChange = (e) => {
		const pincode = e.target.value;
		const { city, state } = this.getCityAndStateFromPincode(pincode);
		this.props.form.setFieldsValue({ city, state });
		this.setState({ isOtherState: state === 'Other' });
	};

	componentDidMount() {
		console.log('Billing details:', this.props.editingBillingDetails);
		this.setState({
			billingDetails: this.props.editingBillingDetails
		});
	}

	handleGSTNChange = (e) => {
		const gstn = e.target.value;
		if (isValidGSTNumber(gstn)) {
			const stateFromGSTN = getStateFromGSTN(gstn);
			if (stateFromGSTN) {
				this.props.form.setFieldsValue({ state: stateFromGSTN });
				this.setState({ isOtherState: false, stateDisabled: true });
			} else {
				this.props.form.setFieldsValue({ state: null });
				this.setState({ isOtherState: false, stateDisabled: false });
			}
		} else {
			this.props.form.setFieldsValue({ state: null });
			this.setState({ isOtherState: false, stateDisabled: false });
		}
	};

	handleStateChange = (value) => {
		if (value === 'Other') {
			this.setState({ isOtherState: true });
			this.props.form.setFieldsValue({ state: value });
		} else {
			this.setState({ isOtherState: false });
			if (this.stateList.includes(value)) {
				this.props.form.setFieldsValue({ state: value });
			} else {
				// If the value is not in the stateList, set it as 'other'
				this.props.form.setFieldsValue({ state: 'other' });
				this.setState({ isOtherState: true });
			}
		}
	};

	createBillingDetails = (values) => {
		this.setState({ loading: true });
		const billingDetails = {
			billingName: values.name,
			gstNumber: values.gstn || '',
			addressLine1: values.addressLine1,
			addressLine2: values.addressLine2 || null,
			pincode: values.pincode,
			city: values.city,
			state: values.state !== 'Other' ? values.state : values.otherState,
			country: 'India'
		};

		this.props
			.createBillingDetails(billingDetails)
			.then((result) => {
				console.log('Billing details created successfully:', result);
				message.success('Billing details created successfully', () => {
					this.handleCancel();
				});
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.error('Error creating billing details:', error);
				message.error('Failed to create billing details. Please try again.');
				this.setState({ loading: false });
			});
	};

	editBillingDetails = (values) => {
		this.setState({ loading: true });
		const billingDetails = {
			billingName: values.name,
			gstNumber: values.gstn || '',
			addressLine1: values.addressLine1,
			addressLine2: values.addressLine2 || null,
			pincode: values.pincode,
			city: values.city,
			state: values.state !== 'Other' ? values.state : values.otherState,
			country: 'India'
		};

		this.props
			.updateBillingDetails(billingDetails)
			.then((result) => {
				console.log('Billing details updated successfully:', result);
				message.success('Billing details updated successfully', () => {
					this.handleCancel();
				});
				this.setState({ loading: false });
			})
			.catch((error) => {
				console.error('Error updating billing details:', error);
				message.error('Failed to update billing details. Please try again.');
				this.setState({ loading: false });
			});
	};

	// createBillingDetails = (values) => {
	// 	this.setState({ loading: true });
	// 	const billingDetails = {
	// 		billingName: values.name,
	// 		gstNumber: values.gstn || '',
	// 		addressLine1: values.addressLine1,
	// 		addressLine2: values.addressLine2 || null,
	// 		pincode: values.pincode,
	// 		city: values.city,
	// 		state: values.state !== 'Other' ? values.state : values.otherState,
	// 		country: 'India'
	// 	};

	// 	this.props
	// 		.createBillingDetails(billingDetails)
	// 		.then((result) => {
	// 			console.log('Billing details created successfully:', result);
	// 			this.handleCancel();
	// 			message.success('Billing details created successfully');
	// 			this.setState({ loading: false });
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error creating billing details:', error);
	// 			message.error('Failed to create billing details. Please try again.');
	// 			this.setState({ loading: false });
	// 		});
	// };

	// editBillingDetails = (values) => {
	// 	this.setState({ loading: true });
	// 	const billingDetails = {
	// 		billingName: values.name,
	// 		gstNumber: values.gstn || '',
	// 		addressLine1: values.addressLine1,
	// 		addressLine2: values.addressLine2 || null,
	// 		pincode: values.pincode,
	// 		city: values.city,
	// 		state: values.state !== 'Other' ? values.state : values.otherState,
	// 		country: 'India'
	// 	};

	// 	this.props
	// 		.updateBillingDetails(billingDetails)
	// 		.then((result) => {
	// 			console.log('Billing details updated successfully:', result);
	// 			this.handleCancel();
	// 			message.success('Billing details updated successfully');
	// 			this.setState({ loading: false });
	// 		})
	// 		.catch((error) => {
	// 			console.error('Error updating billing details:', error);
	// 			message.error('Failed to update billing details. Please try again.');
	// 			this.setState({ loading: false });
	// 		});
	// };

	handleOk = () => {
		this.props.form.validateFields((err, values) => {
			if (!err) {
				if (this.props.editingBillingDetails) {
					this.editBillingDetails(values);
				} else {
					this.createBillingDetails(values);
				}
			}
		});
	};

	handleCancel = () => {
		if (this.state.loading === true) {
			return;
		}

		this.props.onClose();
	};

	render = () => {
		const { getFieldDecorator } = this.props.form;
		const { isOtherState } = this.state;
		const { editingBillingDetails } = this.props;

		const modalTitle = this.state.billingDetails ? 'Update Billing Details' : 'Add Billing Details';

		return (
			<>
				<Modal
					width={580}
					open={true}
					title={<h3>{modalTitle}</h3>}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={this.state.loading}
							onClick={this.handleOk}>
							Save
						</Button>
					]}>
					{/*<p> For accurate invoicing and GST credit, please ensure to provide the correct GST number and address details</p>*/}
					<Form>
						<FormItem name="name" label="Name">
							{getFieldDecorator('name', {
								rules: [
									{ required: true, message: 'Please input the person/business name!' },
									{ min: 3, message: 'Name must be at least 3 characters long' }
								],
								initialValue: editingBillingDetails ? editingBillingDetails.billingName : null
							})(<Input placeholder="Enter Person/Business Name" />)}
						</FormItem>

						<FormItem name="gstn" label="GST">
							{getFieldDecorator('gstn', {
								rules: [
									{
										validator: (_, value) =>
											value
												? isValidGSTNumber(value)
													? Promise.resolve()
													: Promise.reject(new Error('Invalid GST number'))
												: Promise.resolve()
									}
								],
								initialValue: editingBillingDetails ? editingBillingDetails.gstNumber : null
							})(
								<Input
									onChange={this.handleGSTNChange}
									placeholder="Enter GST number if you have"
								/>
							)}
						</FormItem>
						<div>
							<h5>Billing Address</h5>
						</div>

						<FormItem name="addressLine1" label="Address Line 1">
							{getFieldDecorator('addressLine1', {
								rules: [{ required: true, message: 'Please enter the address line 1' }],
								initialValue: editingBillingDetails ? editingBillingDetails.addressLine1 : null
							})(<Input placeholder="Enter Address Line 1" />)}
						</FormItem>

						<FormItem name="addressLine2" label="Address Line 2">
							{getFieldDecorator('addressLine2', {
								rules: [{ required: true, message: 'Please enter the address line 2' }],

								initialValue: editingBillingDetails ? editingBillingDetails.addressLine2 : null
							})(<Input placeholder="Enter Address Line 2" />)}
						</FormItem>

						<Row gutter={16}>
							<Col span={10}>
								<FormItem name="pincode" label="Pincode">
									{getFieldDecorator('pincode', {
										rules: [
											{ required: true, message: 'Entter pincode!' },
											{ pattern: /^[0-9]{6}$/, message: 'Should be 6 digits' }
										],
										initialValue: editingBillingDetails ? editingBillingDetails.pincode : null
									})(<Input onChange={this.handlePincodeChange} placeholder="Area Pincode" />)}
								</FormItem>
							</Col>
						</Row>
						<Row gutter={16}>
							<Col span={10}>
								<FormItem name="city" label="City">
									{getFieldDecorator('city', {
										rules: [{ required: true, message: 'Please enter the city' }],
										initialValue: editingBillingDetails ? editingBillingDetails.city : null
									})(<Input placeholder="Enter city " />)}
								</FormItem>
							</Col>
							<Col span={14}>
								<FormItem name="state" label="State">
									{getFieldDecorator('state', {
										rules: [{ required: true, message: 'Please enter the state' }],
										initialValue: this.state.billingDetails ? this.state.billingDetails.state : null
									})(
										<Select
											showSearch
											placeholder="Select a state"
											optionFilterProp="children"
											onChange={this.handleStateChange}
											filterOption={(input, option) =>
												option.children.toLowerCase().includes(input.toLowerCase())
											}
											disabled={this.props.stateDisabled} // Disable state selection if GST number is valid
										>
											{this.stateList.map((stateOption) => (
												<Option key={stateOption} value={stateOption} placeholder="select state ">
													{stateOption}
												</Option>
											))}
										</Select>
									)}
								</FormItem>
							</Col>
						</Row>

						{isOtherState && (
							<FormItem name="otherState" label="Other State">
								{getFieldDecorator('otherState', {
									rules: [{ required: true, message: 'Please enter the state' }],

									initialValue: editingBillingDetails ? editingBillingDetails.state : null
								})(<Input />)}
							</FormItem>
						)}
					</Form>
				</Modal>
			</>
		);
	};
}

AddBillingDetails.propTypes = {
	onClose: PropTypes.func.isRequired,
	createBillingDetails: PropTypes.func.isRequired,
	updateBillingDetails: PropTypes.func.isRequired,
	editingBillingDetails: PropTypes.object,
	userId: PropTypes.string
};

const AddBillingDetailsForm = Form.create()(AddBillingDetails);

const mapStateToProps = (state) => ({
	userId: state.auth.userId
});

const mapDispatchToProps = (dispatch) => ({
	createBillingDetails: (billingDetails) => {
		return dispatch(createBillingDetailsMAction(billingDetails));
	},
	updateBillingDetails: (billingDetails) => {
		return dispatch(updateBillingDetailsMAction(billingDetails));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingDetailsForm);
