import { errorHandler, swaggerClient } from '../../swagger';

class Order {
	static orderList = async (userId) => {
		try {
			let result = await swaggerClient().apis.store.order_list({
				userId: userId
			});
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};

	static placeOrder = async (userId, orderItems, useWallet, eventId, rechargeAmount) => {
		try {
			let result = await swaggerClient().apis.store.place_order({
				userId: userId,
				orderItems: orderItems,
				useWallet: useWallet,
				eventDocId: eventId,
				rechargeAmount: rechargeAmount
			});
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};

	static placeValidityOrder = async (userId, useWallet, eventId) => {
		try {
			let result = await swaggerClient().apis.store.place_validity_order({
				userId: userId,
				eventDocId: eventId,
				useWallet: useWallet
			});

			console.log('result', result);
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};

	static placeFaceOrder = async (userId, useWallet, eventId, validityMonths) => {
		try {
			let result = await swaggerClient().apis.store.place_face_order({
				userId: userId,
				eventDocId: eventId,
				useWallet: useWallet,
				validityMonths: validityMonths
			});
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};

	static confirmOrder = async (userId, orderId, razorPayPaymentId, signature, eventId) => {
		try {
			let result = await swaggerClient().apis.store.confirm_order({
				userId: userId,
				orderId: orderId,
				razorPayPaymentId: razorPayPaymentId,
				signature: signature,
				eventDocId: eventId
			});
			return JSON.parse(result.data);
		} catch (err) {
			if (err.message) {
				throw new Error(err.message);
			}

			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				throw new Error(response.message);
			}

			throw new Error(
				'Some error occurred. Do not worry if your money got deducted. Please contact us'
			);
		}
	};
}

export default Order;
