import {
	CopyOutlined,
	InfoCircleOutlined,
	MailOutlined,
	WhatsAppOutlined
} from '@ant-design/icons';
import { Alert, Button, Input, message, Modal, Space, Switch, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { RENEWAL_BASE_URL } from '../config';

const { Text, Title, Paragraph } = Typography;

const RenewalFaceLinkModal = ({ event, showAsLink, daysUntilExpiry }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [copied, setCopied] = useState({ message: false, link: false });
	const [isMobile, setIsMobile] = useState(false);
	const [passDiscountToUser, setPassDiscountToUser] = useState(false);

	const isExpired = daysUntilExpiry < 0;

	useEffect(() => {
		const checkIfMobile = () => {
			setIsMobile(window.innerWidth <= 768);
		};

		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);

		return () => {
			window.removeEventListener('resize', checkIfMobile);
		};
	}, []);

	const fullPriceLink = `${RENEWAL_BASE_URL}renew/${event.channel}?default=face`;
	const discountedLink = `${RENEWAL_BASE_URL}renewal/${event.channel}?default=face`;

	const renewalLink = isExpired || !passDiscountToUser ? fullPriceLink : discountedLink;

	const formatDate = (dateString) => {
		const date = new Date(dateString);
		if (isNaN(date.getTime())) {
			return 'an unknown date';
		}
		return date.toLocaleDateString('en-US', {
			day: 'numeric',
			month: 'long',
			year: 'numeric'
		});
	};

	const expiryDate = formatDate(event.facesExpiresAt);

	const getRenewalMessage = (isWhatsApp = false) => {
		let messageStart;
		if (isExpired) {
			messageStart = isWhatsApp
				? '*Attention Needed: Your Face Recognition Has Expired*'
				: 'Attention Needed: Your Face Recognition Has Expired';
		} else {
			messageStart = isWhatsApp
				? '*Attention Needed: Your Face Recognition Is Expiring*'
				: 'Attention Needed: Your Face Recognition Is Expiring';
		}

		const eventNameFormat = isWhatsApp ? `*${event.name}*` : `"${event.name}"`;
		const pricing = isExpired
			? 'special rate'
			: passDiscountToUser
			? '50% discounted rate'
			: 'special rate';

		let statusMessage;
		if (isExpired) {
			statusMessage = `Face Recognition for your event ${eventNameFormat} expired on ${expiryDate}.`;
		} else if (daysUntilExpiry === 0) {
			statusMessage = `Face Recognition for your event ${eventNameFormat} is expiring today.`;
		} else {
			statusMessage = `Face Recognition for your event ${eventNameFormat} will expire on ${expiryDate} (in ${daysUntilExpiry} days).`;
		}

		const renewalLinkText = isWhatsApp ? '*Renewal Link:*' : 'Renewal Link:';
		const importantNoticeText = isWhatsApp ? '*Important Notice:*' : 'Important Notice:';
		const permanentlyDisabledText = isWhatsApp ? '*permanently disabled*' : 'permanently disabled';

		return `⚠️ ${messageStart} ⚠️

Hello,

${statusMessage} 📅

🚀 Action Required: Renew your Face Recognition now at ${pricing}! 💰

Steps:

1️⃣ Click the renewal link below
2️⃣ Review the renewal details
3️⃣ Complete the payment process

${renewalLinkText} ${renewalLink}

⚠️ ${importantNoticeText} After expiration, the Face Recognition feature will be ${permanentlyDisabledText} for your event. Renew now to ensure continued access to AI-powered facial recognition and smart galleries.

Best regards,
The Kamero Team 📷`;
	};

	const renewalMessage = getRenewalMessage();
	const whatsappMessage = getRenewalMessage(true);

	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const copyToClipboard = (text, type) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied({ ...copied, [type]: true });
				message.success('Copied to clipboard');
				setTimeout(() => setCopied({ ...copied, [type]: false }), 3000);
			})
			.catch(() => {
				message.error('Failed to copy');
			});
	};

	const openWhatsApp = () => {
		const encodedMessage = encodeURIComponent(whatsappMessage);
		window.open(`https://api.whatsapp.com/send?text=${encodedMessage}`, '_blank');
	};

	const openEmail = () => {
		const subject = encodeURIComponent(`Important: Face Recognition Renewal - ${event.name}`);
		const body = encodeURIComponent(renewalMessage);
		window.open(`mailto:?subject=${subject}&body=${body}`, '_blank');
	};

	const triggerElement = showAsLink ? (
		<a onClick={showModal}>Share Link</a>
	) : (
		<Button onClick={showModal}>Share Link</Button>
	);

	const ButtonContent = ({ icon, text, isCopied }) => (
		<>
			{icon}
			{!isMobile && <span style={{ marginLeft: '5px' }}>{isCopied ? 'Copied!' : text}</span>}
		</>
	);

	return (
		<>
			{triggerElement}
			<Modal
				title={<Title level={4}>Share Face Recognition Renewal Link: {event.name}</Title>}
				open={isModalVisible}
				onCancel={handleCancel}
				footer={null}
				width={isMobile ? '100%' : 700}>
				<Space direction="vertical" size="large" style={{ width: '100%' }}>
					{!isExpired && (
						<Alert
							message={
								<div className="pricing-options">
									<Title level={4} style={{ marginBottom: '16px' }}>
										{passDiscountToUser
											? 'You are passing 50% discount to users/clients'
											: 'You will get 50% bonus on renewal using link'}
									</Title>
									<Space align="center" style={{ marginBottom: '16px' }}>
										<Switch
											checked={passDiscountToUser}
											onChange={(checked) => setPassDiscountToUser(checked)}
										/>
										<Text strong style={{ fontSize: '16px' }}>
											Pass 50% discount to the client/users
										</Text>
										<Tooltip title="Toggle this switch to change between full price and discounted renewal links">
											<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '16px' }} />
										</Tooltip>
									</Space>
									<Paragraph style={{ fontSize: '16px', marginBottom: 0 }}>
										{passDiscountToUser ? (
											<Text>
												You've chosen to pass the 50% discount directly to your client. The renewal
												link below offers a 50% discount on the Face Recognition renewal price.
											</Text>
										) : (
											<Text>
												You've chosen to share the full-price renewal link. When a client renews
												using this link, you'll receive a 50% bonus (excluding GST) directly in your
												wallet.
											</Text>
										)}
									</Paragraph>
								</div>
							}
							type="info"
							showIcon
						/>
					)}
					{isExpired && (
						<Alert
							message="Face Recognition has expired"
							description="The renewal link will be for the full price as re-indexing of all photos is required after expiry."
							type="warning"
							showIcon
						/>
					)}
					<div>
						<Title level={5}>Renewal Message</Title>
						<Input.TextArea
							value={renewalMessage}
							rows={12}
							readOnly
							style={{ fontSize: '14px', marginBottom: '16px' }}
						/>

						<div
							style={{ display: 'flex', justifyContent: 'center', gap: '16px', flexWrap: 'wrap' }}>
							<Button
								size={isMobile ? 'middle' : 'large'}
								onClick={() => copyToClipboard(renewalMessage, 'message')}
								style={{ minWidth: isMobile ? '40px' : 'auto' }}>
								<ButtonContent icon={<CopyOutlined />} text="Copy" isCopied={copied.message} />
							</Button>
							<Button
								onClick={openWhatsApp}
								size={isMobile ? 'middle' : 'large'}
								style={{
									borderColor: '#25D366',
									color: '#25D366',
									minWidth: isMobile ? '40px' : 'auto'
								}}
								className="whatsapp-button">
								<WhatsAppOutlined />
								{isMobile ? ' Send' : ' Send via WhatsApp'}
							</Button>
							<Button
								onClick={openEmail}
								size={isMobile ? 'middle' : 'large'}
								style={{
									borderColor: '#4a86e8',
									color: '#4a86e8',
									minWidth: isMobile ? '40px' : 'auto'
								}}
								className="email-button">
								<ButtonContent icon={<MailOutlined />} text="Send Email" isCopied={false} />
							</Button>
						</div>
					</div>

					<div>
						<Title level={5}>Renewal Link</Title>
						<Space.Compact style={{ width: '100%' }}>
							<Input value={renewalLink} readOnly style={{ flexGrow: 1, fontSize: '14px' }} />
							<Button onClick={() => copyToClipboard(renewalLink, 'link')}>
								{copied.link ? 'Copied!' : 'Copy'}
							</Button>
						</Space.Compact>
					</div>
				</Space>
			</Modal>
			<style jsx>{`
				.pricing-options {
					padding: 0 16px 16px;
					border-radius: 8px;
				}
				.whatsapp-button,
				.email-button {
					display: flex;
					align-items: center;
					justify-content: center;
				}
				@media (max-width: 768px) {
					.whatsapp-button,
					.email-button {
						padding: 0 10px;
					}
				}
				.whatsapp-button {
					border-color: #25d366 !important;
					color: #25d366 !important;
				}
				.whatsapp-button:hover {
					border-color: #6f4898 !important;
					color: #6f4898 !important;
				}
				.whatsapp-button .anticon {
					color: #25d366;
				}
				.whatsapp-button:hover .anticon {
					color: #6f4898;
				}
				.email-button {
					border-color: #4a86e8 !important;
					color: #4a86e8 !important;
				}
				.email-button:hover {
					border-color: #6f4898 !important;
					color: #6f4898 !important;
				}
				.email-button .anticon {
					color: #4a86e8;
				}
				.email-button:hover .anticon {
					color: #6f4898;
				}
			`}</style>
		</>
	);
};

RenewalFaceLinkModal.propTypes = {
	event: PropTypes.object.isRequired,
	showAsLink: PropTypes.bool,
	daysUntilExpiry: PropTypes.number.isRequired
};

export default RenewalFaceLinkModal;
