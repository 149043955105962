import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';
import { Input } from 'antd';

class TextFieldComp extends Component {
	constructor(props) {
		super(props);
		this.state = { value: this.props.initialValue ? this.props.initialValue : '' };
		bindAll(this, ['handleChange', 'handleKeyPress']);
	}

	handleChange = (e) => {
		this.setState({ value: e.target.value });

		const { textChanged } = this.props;
		textChanged(e.target.value);
	};

	handleKeyPress = (e) => {
		e.preventDefault();
	};

	render() {
		const { maxLength, placeholder, shouldAutoFocus, className } = this.props;
		return (
			<Input
				className={className}
				type="text"
				defaultValue={this.props.initialValue}
				value={this.state.value}
				placeholder={placeholder}
				onChange={this.handleChange}
				maxLength={maxLength}
				autoFocus={shouldAutoFocus}
				onPressEnter={this.handleKeyPress}
				onClick={(e) => {
					e.stopPropagation();
				}}
				{...this.props.inputProps}
			/>
		);
	}
}

TextFieldComp.propTypes = {
	textChanged: PropTypes.func.isRequired,
	maxLength: PropTypes.number.isRequired,
	placeholder: PropTypes.string.isRequired,
	initialValue: PropTypes.string,
	inputProps: PropTypes.any
};

export default TextFieldComp;
