import { connect } from 'react-redux'
import CreateEventComp from '../components/CreateEventComp'
import { goBack } from 'connected-react-router'
import Purchase from '../pouchDB/models/Purchase'
import Event from '../pouchDB/models/Event'
import { updateMyEventsMAction } from '../actions/pouchDBActions'

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  email: state.auth.email,
  whitelabelId: state.auth.whitelabelId
})

const mapDispatchToProps = (dispatch) => ({
  goBack: () => {
    dispatch(goBack())
  },
  fetchPurchasedPacks: async (userId, whitelabelId, consistent) => {
    return await Purchase.fetchPurchases(userId, whitelabelId, consistent)
  },
  createEvent: async (userId, name, packId, purchaseId,isGuestUploadEnabled,guestMaxPhotos) => {
    return await Event.createEvent(userId, name, packId, purchaseId,isGuestUploadEnabled,guestMaxPhotos)
  },
  eventCreated: async () => {
    await dispatch(updateMyEventsMAction(1, true))
    dispatch(goBack())
  }
})

const CreateEvent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEventComp)

export default CreateEvent
