import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextFieldComp from './TextFieldComp';
import { DeleteTwoTone, EditOutlined, EditTwoTone, WarningOutlined } from '@ant-design/icons';
import { Row, Col, Spin, Popconfirm, Modal, Button } from 'antd';
import { iconButtonColor, iconTheme } from '../styles/icons';
import thumbImage from '../svg/addThumbnail.svg';
import classnames from 'classnames';

class NameEditDeleteItemComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showEditPopup: false,
			showDeletePopup: false,
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	getEditButton = (isActive, isBeingDeleted) => {
		if (!isActive || isBeingDeleted) {
			return null;
		}

		const { name, editItem, editItemPopupPlaceholder, itemNameMaxLength } = this.props;

		if (this.state.isMobile) {
			return (
				<>
					<EditOutlined
						style={{ fontSize: '1rem', marginRight: '10px' }}
						onClick={(e) => {
							this.editingEvent = this.props.event;
							this.editingEventName = name;
							this.setState({ showEditPopup: true });
							e.stopPropagation();
						}}
					/>
					<Modal
						open={this.state.showEditPopup}
						closable={false}
						footer={null}
						onCancel={() => {
							this.setState({ showEditPopup: false });
						}}
						centered>
						<Row align="middle">
							<EditOutlined style={{ fontSize: '1rem', marginRight: '10px' }} />
							<h6 style={{ color: '#2D3748', fontWeight: 400, fontSize: '16px' }}>
								Edit Album Name
							</h6>
						</Row>
						<Row align="middle">
							<TextFieldComp
								maxLength={itemNameMaxLength}
								placeholder={editItemPopupPlaceholder}
								initialValue={name}
								textChanged={(newName) => {
									this.newItemName = newName;
								}}
							/>
						</Row>
						<Row align="middle" style={{ paddingTop: '10px' }} justify="end">
							<Button
								type="text"
								style={{ color: '#6F4898', fontWeight: 500, fontSize: '16px' }}
								onClick={(e) => {
									e.stopPropagation();
									this.setState({ showEditPopup: false });
								}}>
								Cancel
							</Button>
							<Button
								type="text"
								style={{ color: '#6F4898', fontWeight: 500, fontSize: '16px' }}
								onClick={(e) => {
									e.stopPropagation();
									if (
										this.newItemName &&
										this.newItemName.length > 0 &&
										name !== this.newItemName
									) {
										editItem(this.newItemName);
										this.setState({ showEditPopup: false });
									}
								}}>
								Save
							</Button>
						</Row>
					</Modal>
				</>
			);
		}

		return (
			<Popconfirm
				title={this.getEditPopOver()}
				icon={<EditOutlined />}
				onConfirm={(e) => {
					e.stopPropagation();
					if (this.newItemName && this.newItemName.length > 0 && name !== this.newItemName) {
						editItem(this.newItemName);
					}
				}}
				onCancel={(e) => {
					e.stopPropagation();
				}}
				okText="Save"
				cancelText="Cancel">
				<div
					onClick={(e) => {
						this.editingEvent = this.props.event;
						this.editingEventName = name;
						e.stopPropagation();
					}}>
					<EditTwoTone
						className="tab-action-icon clickable"
						style={{ marginRight: '4px' }}
						twoToneColor={iconButtonColor}
					/>
				</div>
			</Popconfirm>
		);
	};

	getEditPopOver() {
		const { name, editItemPopupTitle, editItemPopupPlaceholder, itemNameMaxLength } = this.props;

		return (
			<div>
				<p>{editItemPopupTitle}</p>
				<TextFieldComp
					maxLength={itemNameMaxLength}
					placeholder={editItemPopupPlaceholder}
					initialValue={name}
					textChanged={(newName) => {
						this.newItemName = newName;
					}}
				/>
			</div>
		);
	}

	getDeleteButton = (isActive, isBeingDeleted) => {
		if (!isActive || isBeingDeleted) {
			return null;
		}

		const { deleteItem } = this.props;

		if (this.state.isMobile) {
			return (
				<>
					<DeleteTwoTone
						className="tab-action-icon clickable"
						style={{ fontSize: '1rem' }}
						onClick={(e) => {
							this.setState({ showDeletePopup: true });
							e.stopPropagation();
						}}
						twoToneColor={iconButtonColor}
					/>
					<Modal
						open={this.state.showDeletePopup}
						closable={false}
						footer={null}
						onCancel={() => {
							this.setState({ showDeletePopup: false });
						}}
						centered>
						<Row align="middle">
							<WarningOutlined style={{ fontSize: '1rem', marginRight: '10px' }} />
							<h6 style={{ color: '#2D3748', fontWeight: 400, fontSize: '16px' }}>Are you sure?</h6>
						</Row>
						<Row align="middle">
							<p style={{ color: '#27272A', fontWeight: 400, fontSize: '16px' }}>
								This will delete all photos inside this album.
							</p>
						</Row>
						<Row align="middle" style={{ paddingTop: '10px' }} justify="end">
							<Button
								type="text"
								style={{ color: '#6F4898', fontWeight: 500, fontSize: '14px' }}
								onClick={(e) => {
									e.stopPropagation();
									this.setState({ showDeletePopup: false });
								}}>
								Cancel
							</Button>
							<Button
								type="text"
								style={{ color: '#6F4898', fontWeight: 500, fontSize: '14px' }}
								onClick={(e) => {
									e.stopPropagation();
									deleteItem();
									this.setState({ showDeletePopup: false });
								}}>
								Delete anyway!
							</Button>
						</Row>
					</Modal>
				</>
			);
		}

		return (
			<Popconfirm
				title={this.getDeletePopOver()}
				icon={<WarningOutlined />}
				onConfirm={(e) => {
					e.stopPropagation();
					deleteItem();
				}}
				onCancel={(e) => {
					e.stopPropagation();
				}}
				okText="Delete Anyway!"
				cancelText="Cancel">
				<div
					onClick={(e) => {
						e.stopPropagation();
					}}>
					<DeleteTwoTone
						className="tab-action-icon clickable"
						style={{ marginRight: '0px' }}
						twoToneColor={iconButtonColor}
					/>
				</div>
			</Popconfirm>
		);
	};

	getDeletePopOver() {
		const { deleteItemPopupTitle, deleteItemPopupDetail } = this.props;

		return (
			<div>
				<p>{deleteItemPopupTitle}</p>
				<p>{deleteItemPopupDetail}</p>
			</div>
		);
	}

	getAddThumbnailButton = (isActive, isBeingDeleted) => {
		const { type, onThumbnailClicked } = this.props;
		if (!isActive || isBeingDeleted || type == 'Album') {
			return null;
		}

		return (
			<div
				style={{ display: 'flex', alignItems: 'center', height: '100%', marginRight: '4px' }}
				onClick={() => onThumbnailClicked()}>
				<img src={thumbImage} />
			</div>
		);
	};

	render() {
		const { name, isActive, isBeingDeleted } = this.props;

		return (
			<Spin spinning={isBeingDeleted}>
				<Row
					className={classnames('name-editdelete-tab-row', { selected: isActive })}
					type="flex"
					justify="space-between">
					<Col
						span={15}
						style={{
							textOverflow: 'ellipsis',
							overflow: 'hidden',
							fontSize: '16px',
							color: '#3F3F46',
							fontWeight: 500
						}}
						onClick={(e) => {
							e.preventDefault();
							if (this.props.onItemClick) {
								this.props.onItemClick();
							}
						}}>
						{isBeingDeleted ? 'Deleting..' : name}
					</Col>
					<Col span={9}>
						<Row type="flex" justify="end">
							<Col>{this.getAddThumbnailButton(isActive, isBeingDeleted)}</Col>
							<Col>{this.getEditButton(isActive, isBeingDeleted)}</Col>
							<Col>{this.getDeleteButton(isActive, isBeingDeleted)}</Col>
						</Row>
					</Col>
				</Row>
			</Spin>
		);
	}
}

NameEditDeleteItemComp.propTypes = {
	name: PropTypes.string.isRequired,
	isActive: PropTypes.bool.isRequired,
	editItem: PropTypes.func.isRequired,
	deleteItem: PropTypes.func.isRequired,
	isBeingDeleted: PropTypes.bool.isRequired,
	itemNameMaxLength: PropTypes.number.isRequired,
	editItemPopupTitle: PropTypes.string.isRequired,
	editItemPopupPlaceholder: PropTypes.string.isRequired,
	deleteItemPopupTitle: PropTypes.string.isRequired,
	deleteItemPopupDetail: PropTypes.string.isRequired
};

export default NameEditDeleteItemComp;
