import { Component } from 'react';
import PropTypes from 'prop-types';
import TextFieldComp from './TextFieldComp';
import { bindAll } from 'lodash';
import GuestUploads from './GuestUploads';
import BuyProductsComp from './BuyProductsComp';
import { PRODUCT_TYPE } from '../pouchDB/models/Product';
import '../styles/Input.sass';
import '../styles/CreateEvent.sass';
import { ReactComponent as BackButtonSVG } from '../svg/BackArrow.svg';
import CircleTitle from './CircleTitle';
import { message, Typography, InputNumber, Switch, Button } from 'antd';
import FullScreenIndicator from './FullScreenIndicator';
import { ArrowLeft } from 'lucide-react';

const { Title } = Typography;
export const EVENT_NAME_LENGTH = 35;

class CreateEventComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			eventName: '',
			myPurchases: [],
			showIndicator: false,
			isGuestUploadEnabled: false,
			guestMaxPhotos: 0,
			isMobile: window.screen.width <= 576
		};
		bindAll(this, [
			'handleEventNameChange',
			'paidPacksComps',
			'myPacksComps',
			'guestUploads',
			'handleWindowResize'
		]);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
		this.fetchPurchases(false);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	handleEventNameChange(newName) {
		this.setState({ eventName: newName });
	}

	fetchPurchases = async (consistent) => {
		const { userId, whitelabelId, fetchPurchasedPacks } = this.props;
		try {
			let purchases = await fetchPurchasedPacks(userId, whitelabelId, consistent);
			this.setState({ myPurchases: purchases });
		} catch (err) {
			message.error(err.message);
		}
	};

	createEvent = async (purchase) => {
		console.log(purchase.photos, 'purchase details');
		const { createEvent, userId, eventCreated } = this.props;
		const { eventName, isGuestUploadEnabled, guestMaxPhotos } = this.state;
		console.log(this.state.guestMaxPhotos, 'purchase details');
		try {
			this.setState({ showIndicator: true });
			if (purchase.photos > this.state.guestMaxPhotos) {
				await createEvent(
					userId,
					eventName,
					undefined,
					purchase.id,
					isGuestUploadEnabled,
					guestMaxPhotos
				);
				await eventCreated();
				this.setState({ showIndicator: false });
			} else {
				message.error('Enter photo count less than selected pack count');
				this.setState({ showIndicator: false });
			}
		} catch (err) {
			message.error('Enter number of photos for guests to upload');
			this.setState({ showIndicator: false });
		}
	};

	myPacksComps() {
		return (
			<>
				<div style={this.state.isMobile ? { width: '100%' } : {}}>
					{/* <h3 className='pack-title'>
            Select an Event Pack
    </h3> */}
					<Title level={5}>
						<h5 className="create-event-input" style={{ height: '100%' }}>
							Select an Event Pack
						</h5>
					</Title>
					{/* <CircleTitle className='pack-title' title="MY PACKS" /> */}
					<div className="pack-expand" style={this.state.isMobile ? { margin: 0 } : {}}>
						{this.state.myPurchases.map((purchase) => (
							<GuestUploads
								key={purchase.id}
								name={purchase.title}
								notableDetail={
									purchase.unusedQuantity === 9999
										? 'UNLIMITED'
										: purchase.unusedQuantity + ' Unused'
								}
								// detail1={'Event valid for ' + purchase.validity + ' months'}
								// detail2={purchase.isOpen ? 'All guests can upload photos' : 'Only selected guests can upload photos'}
								// detail3={purchase.photos + ' photos with configurable watermark'}
								// detail4=''
								buttonClass="btn-default-notable"
								buttonText="Use & Create Event"
								serverId={purchase}
								buttonTapped={this.createEvent}
							/>
						))}
					</div>
				</div>
			</>
		);
	}

	guestUploads() {
		return (
			<>
				<div>
					{/* <h3 className='pack-title'>
            Select an Event Pack
    </h3> */}
					<Title level={5}>
						<h5 className="create-event-input" style={{ height: '100%' }}>
							Guest Uploads
						</h5>
					</Title>
					{/* <CircleTitle className='pack-title' title="MY PACKS" /> */}
					<div className="pack-expand" style={{ margin: 0 }}>
						<div
							style={{
								display: 'flex',
								flexFlow: 'row',
								alignItems: 'center',
								justifyContent: 'space-between',
								marginRight: '10px'
							}}>
							<h3 style={{ marginBottom: '12px', fontSize: '16px' }}>Enable Guest Uploads</h3>
							<Switch
								checked={this.state.isGuestUploadEnabled}
								onChange={(checked) => {
									this.setState({ isGuestUploadEnabled: checked });
								}}
							/>
						</div>
						{this.state.isGuestUploadEnabled === true && (
							<>
								<div style={{ width: '100%', marginTop: '8px', marginBottom: '16px' }}>
									<div style={{ height: '1px', width: '100%', backgroundColor: '#EEE' }} />
								</div>
								<div
									style={{
										marginRight: '10px',
										display: 'flex',
										flexFlow: 'row',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}>
									<h3 style={{ marginBottom: '12px', fontSize: '16px' }}>
										Edit number of photos for Guests to upload
									</h3>
									<InputNumber
										type="number"
										min={0}
										max={this.state.myPurchases.photos}
										defaultValue={0}
										value={this.state.guestMaxPhotos}
										onChange={(value) => {
											this.setState({ guestMaxPhotos: value });
										}}
									/>
								</div>
							</>
						)}
					</div>
					<h5>Note: In Settings you can edit photo limit for Guest</h5>
				</div>
			</>
		);
	}

	paidPacksComps() {
		return (
			<div className="buy-packs">
				{<CircleTitle className="pack-title" title="BUY MORE" />}
				{
					<BuyProductsComp
						productType={PRODUCT_TYPE.photos_pack}
						purchaseNeedsUpdate={async () => {
							try {
								this.setState({ showIndicator: true });
								await this.fetchPurchases(true);
								this.setState({ showIndicator: false });
								message.success('Payment success! Your packs are now loaded.');
							} catch (err) {
								this.setState({ showIndicator: false });
								message.error(err.message);
							}
						}}
					/>
				}
			</div>
		);
	}

	render() {
		const { goBack } = this.props;

		if (this.state.isMobile) {
			return (
				<div style={{ width: '100%' }}>
					<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
						<Button
							onClick={(e) => goBack()}
							icon={<ArrowLeft />}
							style={{ marginRight: 20 }}></Button>
						<h3 style={{ marginTop: 5, fontSize: '20px', color: '#18181B', fontWeight: 700 }}>
							Create Event
						</h3>
					</div>
					<Title level={5}>Event Name</Title>
					<TextFieldComp
						className="create-event-input"
						maxLength={EVENT_NAME_LENGTH}
						placeholder="Enter Event Name"
						shouldAutoFocus={true}
						textChanged={this.handleEventNameChange}
						inputProps={{ style: { width: '100%' } }}
					/>
					{this.state.eventName.length > 0 ? (
						!this.state.myPurchases || this.state.myPurchases.length === 0 ? (
							<div className="my-packs">
								<h3 className="pack-title">You have no packs.</h3>
							</div>
						) : (
							<div className="packs">
								{this.guestUploads()}
								{this.myPacksComps()}
							</div>
						)
					) : null}
				</div>
			);
		}

		return (
			<div>
				<div>
					<div className="pageTitleBar">
						<Button
							onClick={(e) => goBack()}
							icon={<ArrowLeft />}
							style={{ marginRight: 20 }}></Button>
						<h1 className="pageTitle">Create Event</h1>
					</div>
				</div>

				<div>
					<Title level={5}>
						<h5 className="create-event-input">Event Name</h5>
					</Title>
					<TextFieldComp
						className="create-event-input"
						maxLength={EVENT_NAME_LENGTH}
						placeholder="Enter Event Name"
						shouldAutoFocus={true}
						textChanged={this.handleEventNameChange}
					/>
				</div>

				{this.state.eventName.length > 0 ? (
					!this.state.myPurchases || this.state.myPurchases.length === 0 ? (
						<div className="my-packs">
							<h3 className="pack-title">You have no packs.</h3>
						</div>
					) : (
						<div className="packs">
							{this.guestUploads()}
							{this.myPacksComps()}
						</div>
					)
				) : null}

				{/* {this.state.eventName.length > 0 ? (
					<div className="packs">
						{this.guestUploads()}
						{this.paidPacksComps()}
						<p>
							*On-site support available only in Mumbai, Pune & Surat subject to booking at least 10
							days prior to the Event.
						</p>
					</div>
				) : null}

				{this.state.eventName.length > 0 ? (
					<div className="packs">
						{this.myPacksComps()}
						{this.paidPacksComps()}
						<p>
							*On-site support available only in Mumbai, Pune & Surat subject to booking at least 10
							days prior to the Event.
						</p>
					</div>
				) : null} */}

				{this.state.showIndicator ? <FullScreenIndicator /> : null}
			</div>
		);
	}
}

CreateEventComp.propTypes = {
	userId: PropTypes.string.isRequired,
	whitelabelId: PropTypes.string.isRequired,
	fetchPurchasedPacks: PropTypes.func.isRequired,
	createEvent: PropTypes.func.isRequired,
	goBack: PropTypes.func.isRequired,
	eventCreated: PropTypes.func.isRequired
};

export default CreateEventComp;
