import { combineReducers } from 'redux';
import auth from './authReducer';
import replication from './replicationReducer';
import pouchDB from './pouchDBReducer';
import upload from './uploadReducer';
import videoLink from './videoLinkReducer';
import post from './postReducer';
import profile from './profileReducer';
import { connectRouter } from 'connected-react-router';

const rootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		auth,
		replication,
		pouchDB,
		upload,
		videoLink,
		post,
		profile
	});

export default rootReducer;
