import {parse} from 'url';
import fetch from 'isomorphic-fetch'
// import { uploadPhoto } from '../aws'

//extract id from url path
const RE_VIMEO = /^(?:\/video|\/channels\/[\w-]+|\/groups\/[\w-]+\/videos)?\/(\d+)$/;
const RE_YOUTUBE = /^(?:\/embed)?\/([\w-]{10,12})$/;
// const RE_FACEBOOK = /^\/[\w-]+\/videos\/(\d+)(\/)?$/;

// const S3_KEY_PREFIX_VIDEO = 'video-thumbnails/'

const VideoSourceTypes = Object.freeze({"YOUTUBE":1, "VIMEO":2})

export const getThumbnailURL = (url, fbToken) => {
        url = url || '';

        const urlobj = parse(url, true);

        //youtube
        if (['www.youtube.com', 'youtube.com', 'youtu.be'].indexOf(urlobj.host) !== -1) {
            let video_id = null;
            if ('v' in urlobj.query) {
                if (urlobj.query.v && urlobj.query.v.match(/^[\w-]{10,12}$/)) {
                    video_id = urlobj.query.v;
                }
            } else {
                const match = RE_YOUTUBE.exec(urlobj.pathname);
                if (match) {
                    video_id = match[1];
                }
            }

            if (video_id) {
                return Promise.resolve({
                    url: `http://img.youtube.com/vi/${video_id}/hqdefault.jpg`,
                    source: VideoSourceTypes.YOUTUBE
                })
            }
        }

        //vimeo
        if (['www.vimeo.com', 'vimeo.com', 'player.vimeo.com'].indexOf(urlobj.host) !== -1) {
            const match = RE_VIMEO.exec(urlobj.pathname);
            if (match) {
                const video_id = match[1];

                return new Promise((resolve, reject) => {
                    return fetch(`https://vimeo.com/api/v2/video/${video_id}.json`)
                        .then(response => {
                            if (response.status >= 400) {
                                throw new Error("Invalid Vimeo URL");
                            }
                            return response.json()
                        })
                        .then((json) => {
                            if (!json || json.length <= 0){
                                throw new Error("Invalid Vimeo URL");
                            }
                            resolve({
                                url: json[0].thumbnail_large,
                                source: VideoSourceTypes.VIMEO
                            })
                        }).catch((error) => {
                            resolve({
                                url: undefined,
                                source: undefined
                            })
                        });
                })
            }
        }

        //facebook
        // if (['facebook.com', 'www.facebook.com'].indexOf(urlobj.host) !== -1) {
        //     const match = RE_FACEBOOK.exec(urlobj.pathname);
        //
        //     if (match) {
        //         const video_id = match[1];
        //         return fetch(`https://graph.facebook.com/${video_id}/thumbnails?access_token=${fbToken}`)
        //             .then(response => {
        //                 if (response.status >= 400) {
        //                     throw new Error("Bad response from server");
        //                 }
        //                 return response.json()
        //             })
        //             .then((json) => {
        //                 return s3UploadFromThumbnailURL(json.data[0].uri, 'fb-' + video_id)
        //             })
        //     }
        // }

        return Promise.resolve({
            url: undefined,
            source: undefined
        })
}


// const s3UploadFromThumbnailURL = (url, video_id) => {
//     var s3Key = undefined
//     return fetch(url)
//         .then((response) => {
//             if (response.status >= 400) {
//                 throw new Error("Bad response from server");
//             }
//             return response.blob()
//         })
//         .then((imageBlob) => {
//             s3Key = S3_KEY_PREFIX_VIDEO + video_id + '.jpg';
//             console.log('Uploading video thumbnail: ' + s3Key )
//             return uploadPhoto(imageBlob, s3Key)
//         })
//         .then((blob) => {
//             return s3Key
//         })
// }