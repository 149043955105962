import { swaggerClient } from '../../swagger';
import { getThumbnailURL } from '../../helpers/VideoLinkThumbnail';

class VideoLink {
	static fetchVideoLinks = (eventDocId) => {
		return swaggerClient()
			.apis.video.videolink_list({
				eventDocId: eventDocId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static createVideoLink = (userId, fbToken, eventDocId, videoLinkName, videoLinkLink) => {
		return getThumbnailURL(videoLinkLink, fbToken)
			.then((videoLinkThumbnailResult) => {
				return swaggerClient().apis.video.create_videolink({
					userId: userId,
					eventDocId: eventDocId,
					videoLink: {
						name: videoLinkName,
						link: videoLinkLink,
						thumbnailURL: videoLinkThumbnailResult.url,
						videoSource: videoLinkThumbnailResult.source
					}
				});
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static updateVideoLinkName(userId, videoLinkDocId, newName) {
		return swaggerClient().apis.video.edit_videolink_name({
			userId: userId,
			videoLinkDocId: videoLinkDocId,
			newName: newName
		});
	}

	static updateVideoLinkThumbnail(userId, videoLinkDocId, name, newThumbnailUrl) {
		return swaggerClient().apis.video.edit_videolink_name({
			userId: userId,
			videoLinkDocId: videoLinkDocId,
			newName: name,
			newThumbnailUrl: newThumbnailUrl
		});
	}

	static uploadThumbnailImage = (userId, image) => {
		console.log(userId, image);
		return swaggerClient().apis.upload.upload_photo_by_folder({
			userId: userId,
			file: image,
			type: 'thumbnail'
		});
	};

	static deleteVideoLink(userId, videoLinkDocId) {
		return swaggerClient().apis.video.delete_videolink({
			userId: userId,
			videoLinkDocId: videoLinkDocId
		});
	}
}

export default VideoLink;
