import { ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED, ACTION_REPLICATION_CONFIGURED } from '../actions/action_types'

const replication = (state = {continousReplicationConfigured : false}, action) => {
  switch (action.type) {
    case ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED:
      return {
        ...state,
        'state' : action.newState
      }
    case ACTION_REPLICATION_CONFIGURED:
      return {
        ...state,
        'continousReplicationConfigured' : true
      }
    default:
      return state;
  }
}

export default replication
