import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextFieldButtonComp from './TextFieldButtonComp'
import { ReactComponent as NoAlbumsSVG} from '../svg/NoAlbumPlaceholder.svg'

class NoAlbumsComp extends Component{
  render(){
    const { createAlbum, className } = this.props

    return (
      <div className={className}>
        <NoAlbumsSVG/>
        <h3>No Albums</h3>
        <div style={{marginTop:'20px'}}>
          <TextFieldButtonComp textFieldUpdated={createAlbum} placeholder='Add new album' actionButton='Add'/>
        </div>
      </div>
    )
  }
}

NoAlbumsComp.propTypes = {
  createAlbum: PropTypes.func.isRequired
}

export default NoAlbumsComp
