const pica = require('pica')();
import { PICA_OPTIONS } from './Resize'

const kBigWatermarkSizeRespectToMainImage = 0.34
const kNormalWatermarkSizeRespectToMainImage = 0.17
const kPaddingRespectToMainImage = 0.017
const kPaddingOffsetForHorizontalMargin = 1.5

const WatermarkPosition = Object.freeze({'TopLeft': 0, 'TopRight': 1, 'BottomLeft': 2, 'BottomRight' : 3})
const WatermarkSize = Object.freeze({'Normal': 0, 'Big': 1})

const kTitleFontSize = 50
const kTitleTextHeight = kTitleFontSize * 1.3
const kSubTitleFontSize = 35
const kSubTitleTextHeight = kSubTitleFontSize * 1.3

const kTitleSubtitlePadding = 0
const kWatermarkFontWeight = '100'
const kWatermarkFont = 'Palatino'

const getSizeMaintainingAspectRatio = (image, maxWidth, maxHeight) => {
  const imageWidth = image.width
  const imageHeight = image.height
  //Let's find the max available width for scaled image
  var ratio = imageWidth/imageHeight;
  var aspectWidth = Math.min(maxWidth, ratio*maxHeight);
  var aspectHeight = Math.floor(aspectWidth/ratio)

  return {
    width: imageWidth > aspectWidth ? aspectWidth : imageWidth,
    height: imageHeight > aspectHeight ? aspectHeight : imageHeight
  }
}

const getWatermarkSize = (image, width, height) => {
  if (image.width < image.height) {
    return getSizeMaintainingAspectRatio(image, width, height)
  }else{
    return getSizeMaintainingAspectRatio(image, height, width)
  }
}

const getWatermarkRect = (mainImage, watermark, position, size) => {
  const sizeRespectToMainImage = (size === WatermarkSize.Big) ? kBigWatermarkSizeRespectToMainImage : kNormalWatermarkSizeRespectToMainImage

  let watermarkHeight
  let watermarkWidth
  let padding

  if (mainImage.width > mainImage.height) {
    watermarkHeight = mainImage.height * sizeRespectToMainImage
    watermarkWidth = mainImage.width * sizeRespectToMainImage
    padding = mainImage.width * kPaddingRespectToMainImage
  }else{
    watermarkHeight = mainImage.width * sizeRespectToMainImage
    watermarkWidth = mainImage.height * sizeRespectToMainImage
    padding = mainImage.height * kPaddingRespectToMainImage
  }

  const watermarkSize = getWatermarkSize(watermark, watermarkWidth, watermarkHeight)

  switch (position) {
    case WatermarkPosition.TopLeft:
      return {
        x: padding*kPaddingOffsetForHorizontalMargin,
        y: padding,
        width: watermarkSize.width,
        height: watermarkSize.height
      }
    case WatermarkPosition.TopRight:
      return {
        x: mainImage.width - watermarkSize.width - (padding * kPaddingOffsetForHorizontalMargin),
        y: padding,
        width: watermarkSize.width,
        height: watermarkSize.height
      }
    case WatermarkPosition.BottomLeft:
      return {
        x: padding*kPaddingOffsetForHorizontalMargin,
        y: mainImage.height - watermarkSize.height - padding,
        width: watermarkSize.width,
        height: watermarkSize.height
      }
    case WatermarkPosition.BottomRight:
      return {
        x: mainImage.width - watermarkSize.width - (padding * kPaddingOffsetForHorizontalMargin),
        y: mainImage.height - watermarkSize.height - padding,
        width: watermarkSize.width,
        height: watermarkSize.height
      }
    default:
      return  {
        x: 0,
        y: 0,
        width: 0,
        height: 0
      }
  }
}

export function atPosWithSize(watermark, position, size){
  return function(target){
    return new Promise(async (resolve, reject) => {
      const imageWithWatermarkCanvas = document.createElement('canvas')
      const imageWithWatermarkCtx = imageWithWatermarkCanvas.getContext('2d')

      imageWithWatermarkCanvas.width = target.width
      imageWithWatermarkCanvas.height = target.height
      imageWithWatermarkCtx.drawImage(target, 0, 0)
      imageWithWatermarkCtx.save()

      const watermarkPosition = getWatermarkRect(target, watermark, position, size)

      const watermarkCanvas = document.createElement('canvas')
      watermarkCanvas.width = watermarkPosition.width
      watermarkCanvas.height = watermarkPosition.height

      let options = PICA_OPTIONS
      options.alpha = true

      try {
        await pica.resize(watermark, watermarkCanvas, options)
        imageWithWatermarkCtx.drawImage(
           watermarkCanvas,
           watermarkPosition.x,
           watermarkPosition.y)

        resolve(imageWithWatermarkCanvas)
      } catch (e) {
        reject(e)
      }
    })
  }
}

export function addWatermark(mainImage, watermarkImage, position, size) {
  return new Promise((resolve, reject) => {
    var image = document.createElement('img');
    image.onload =  (e) => {

      var watermark = document.createElement('img');
      watermark.onload =  (e) => {

        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = image.width
        canvas.height = image.height
        context.drawImage(image, 0, 0, image.width, image.height);

        atPosWithSize(watermark, position, size)(canvas)
        .then((withWatermarkImage) => {
          resolve(withWatermarkImage.toDataURL('image/jpeg', 0.7))
        })
        .catch((err) => reject(err))
      }
      watermark.onerror = (err) => reject(err)
      watermark.src = watermarkImage;
    }
    image.onerror = (err) => reject(err)
    image.src = mainImage
  })
}

export function addTextWatermark(mainImage, title, subTitle, position, size){
  return new Promise((resolve, reject) => {
    getImageFromWatermarkText(title, subTitle)
    .then((textCanvas) => {
      const image = document.createElement('img')
      image.onload =  (e) => {

        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        canvas.width = image.width
        canvas.height = image.height
        context.drawImage(image, 0, 0, image.width, image.height);

        atPosWithSize(textCanvas, position, size)(canvas)
        .then((withWatermarkImage) => {
          resolve(withWatermarkImage.toDataURL('image/jpeg', 0.7))
        })
        .catch((err) => reject(err))
      }
      image.onerror = (err) => reject(err)
      image.src = mainImage
    })
    .catch((err) => reject(err))
  })
}

export function getImageFromWatermarkText(title, subTitle){
  return new Promise((resolve, reject) => {
    if (!title || title.length === 0) {
      reject('getImageFromWatermarkText: Title must be present')
      return
    }

    var textCanvas = document.createElement('canvas')
    var textCanvasContext = textCanvas.getContext("2d")
    textCanvasContext.font = kWatermarkFontWeight + " " + kTitleFontSize + "px " + kWatermarkFont

    let titleWidth = textCanvasContext.measureText(title).width + kTitleSubtitlePadding

    if (subTitle && subTitle.length > 0) {
      textCanvasContext.font =  kWatermarkFontWeight + " " + kSubTitleFontSize + "px " + kWatermarkFont
      let subTitleWidth = textCanvasContext.measureText(subTitle).width + kTitleSubtitlePadding

      textCanvas.height = kTitleTextHeight + kTitleSubtitlePadding + kSubTitleTextHeight
      textCanvas.width = titleWidth > subTitleWidth ? titleWidth : subTitleWidth

      textCanvasContext.fillStyle="#fff";
      textCanvasContext.shadowColor = "#ccc";
      textCanvasContext.shadowOffsetX = 2;
      textCanvasContext.shadowOffsetY = 2;
      textCanvasContext.shadowBlur = 4;

      if (titleWidth > subTitleWidth) {
        textCanvasContext.font =  kWatermarkFontWeight + " " + kTitleFontSize + "px " + kWatermarkFont
        textCanvasContext.fillText(title, 0, kTitleFontSize)

        textCanvasContext.font =  kWatermarkFontWeight + " " + kSubTitleFontSize + "px " + kWatermarkFont
        let subtitleY = (titleWidth - subTitleWidth)/2
        textCanvasContext.fillText(subTitle, subtitleY, kTitleTextHeight + kTitleSubtitlePadding + kSubTitleFontSize)
      }else{
        textCanvasContext.font =  kWatermarkFontWeight + " " + kTitleFontSize + "px " + kWatermarkFont
        let titleY = (subTitleWidth - titleWidth)/2
        textCanvasContext.fillText(title, titleY, kTitleFontSize)

        textCanvasContext.font =  kWatermarkFontWeight + " " + kSubTitleFontSize + "px " + kWatermarkFont
        textCanvasContext.fillText(subTitle, 0, kTitleTextHeight + kTitleSubtitlePadding + kSubTitleFontSize)
      }

    }else{
      textCanvas.width = titleWidth
      textCanvas.height = kTitleTextHeight

      textCanvasContext.fillStyle="#fff";
      textCanvasContext.shadowColor = "#ccc";
      textCanvasContext.shadowOffsetX = 2;
      textCanvasContext.shadowOffsetY = 2;
      textCanvasContext.shadowBlur = 4;

      textCanvasContext.font =  kWatermarkFontWeight + " " + kTitleFontSize + "px " + kWatermarkFont
      textCanvasContext.fillText(title, 0, kTitleFontSize)
    }

    textCanvasContext.save()
    resolve(textCanvas)
  })
}

/**
 * Return a function for positioning a watermark on a target canvas
 *
 * @param {Function} xFn - a function to determine an x value
 * @param {Function} yFn - a function to determine a y value
 * @param {Number} alpha
 * @return {Function}
 */
export function atPos(xFn, yFn, alpha) {
  alpha || (alpha = 1.0);
  return function (target, watermark) {
    const context = target.getContext('2d');
    context.save();

    context.globalAlpha = alpha;
    context.drawImage(watermark, xFn(target, watermark), yFn(target, watermark));

    context.restore();
    return target;
  }
}


/**
 * Place the watermark in the lower right corner of the target
 * image
 *
 * @param {Number} alpha
 * @return {Function}
 */
export function lowerRight(alpha) {
  return atPos(
    (target, mark) => target.width - (mark.width + 10),
    (target, mark) => target.height - (mark.height + 10),
    alpha
  );
}

/**
 * Place the watermark in the upper right corner of the target
 * image
 *
 * @param {Number} alpha
 * @return {Function}
 */
export function upperRight(alpha) {
  return atPos(
    (target, mark) => target.width - (mark.width + 10),
    (target, mark) => 10,
    alpha
  );
}

/**
 * Place the watermark in the lower left corner of the target
 * image
 *
 * @param {Number} alpha
 * @return {Function}
 */
export function lowerLeft(alpha) {
  return atPos(
    (target, mark) => 10,
    (target, mark) => target.height - (mark.height + 10),
    alpha
  );
}

/**
 * Place the watermark in the upper left corner of the target
 * image
 *
 * @param {Number} alpha
 * @return {Function}
 */
export function upperLeft(alpha) {
  return atPos(
    (target, mark) => 10,
    (target, mark) => 10,
    alpha
  );
}

/**
 * Place the watermark in the center of the target
 * image
 *
 * @param {Number} alpha
 * @return {Function}
 */
export function center(alpha) {
  return atPos(
    (target, mark) => (target.width - mark.width) / 2,
    (target, mark) => (target.height - mark.height) / 2,
    alpha
  );
}
