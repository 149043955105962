import React from 'react'
import PropTypes from 'prop-types'
import {Provider} from 'react-redux'
import {Route} from "react-router-dom"
import App from "./App"
import { ConnectedRouter } from 'connected-react-router'

const Root = ({ store, history }) => (
   <Provider store={store}>
      <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */ }
         <> { /* your usual react-router v4/v5 routing */ }
            <Route path="/" component={App} />
         </>
      </ConnectedRouter>
   </Provider>
)

Root.propTypes = {
   store: PropTypes.object.isRequired,
   history: PropTypes.object.isRequired
}

export default Root
