import { replace } from 'connected-react-router';
import Cookie from 'js-cookie';
import ls from 'local-storage';
import {
	configureSwaggerClient,
	loginWithCredentials,
	loginWithFB,
	updateUserPhoneNumber,
	verifySession
} from '../swagger';
import {
	ACTION_CLEAR_LOGIN_ERR,
	ACTION_LOGGED_OUT,
	ACTION_LOGIN_FAILED,
	ACTION_LOGIN_START_ON_BUTTON_TAP,
	ACTION_LOGIN_SUCCESS,
	ACTION_NEEDS_LOGIN,
	ACTION_UPDATE_PHONE_NUMBER
} from './action_types';
import { goPouchDBPostLogin } from './pouchDBActions';
export const loginStarted = () => ({
	type: ACTION_LOGIN_START_ON_BUTTON_TAP
});

export const clearLoginErr = () => ({
	type: ACTION_CLEAR_LOGIN_ERR
});

const needsLoginAction = () => ({
	type: ACTION_NEEDS_LOGIN
});

export const loginSuccess = (
	token,
	userId,
	name,
	email,
	whitelabelId,
	phone,
	isWhitelabelAdmin
) => ({
	type: ACTION_LOGIN_SUCCESS,
	token: token,
	userId: userId,
	name: name,
	email: email,
	whitelabelId: whitelabelId,
	phone: phone,
	isWhitelabelAdmin: isWhitelabelAdmin
});

export const loginFailed = (error) => ({
	type: ACTION_LOGIN_FAILED,
	lastError: error
});

export const logoutAction = () => ({
	type: ACTION_LOGGED_OUT
});

export const needsLogin = () => {
	return (dispatch) => {
		// window.location.replace('https://devlogin.kamero.in/login');
		dispatch(replace('/login'));
		dispatch(needsLoginAction());
	};
};

// export const loginCompleted = (
// 	token,
// 	userId,
// 	name,
// 	email,
// 	whitelabelId,
// 	phone,
// 	isWhitelabelAdmin,
// 	companyName,
// 	cookie,
// 	sessionId
// ) => {
// 	return async (dispatch, getState) => {
// 		dispatch(loginSuccess(token, userId, name, email, whitelabelId, phone, isWhitelabelAdmin));

// 		console.log('Login completed');
// 		console.log('Company Name: ', companyName);
// 		console.log('Cookie: ', cookie);
// 		console.log('Session ID: ', sessionId);
// 		console.log('Token: ', token);
// 		console.log('User ID: ', userId);
// 		console.log('Name: ', name);
// 		console.log('Email: ', email);
// 		console.log('Whitelabel ID: ', whitelabelId);
// 		console.log('Phone: ', phone);
// 		console.log('Is Whitelabel Admin: ', isWhitelabelAdmin);

// 		let pathName = getState().router.location.pathname;

// 		if (pathName === '/' || pathName === '/login') {
// 			// if (companyName) {
// 			// const domain = `${companyName?.toLowerCase().replace(' ', '-')}.kamero.in`;
// 			// const domain = 'devlogin.kamero.in';
// 			// if (sessionId) {
// 			// Cookie.set('SyncGatewaySession', sessionId, { domain: '.kamero.in' });
// 			// Cookie.remove('SyncGatewaySession', { path: '/kamero' });
// 			// Cookie.remove('SyncGatewaySession', { path: '/' });

// 			// Cookie.set('kameroUserStore', JSON.stringify({ token, userId, name, email }), {
// 			// 	domain: '.kamero.in'
// 			// });
// 			// var query = new URLSearchParams();
// 			// query.append('sessionId', sessionId);
// 			// window.location.replace(`https://${d omain}/dashboard`);
// 			// }
// 			// } else
// 			dispatch(replace('/dashboard')); // Route to events only if coming from root
// 		}

// 		// loadBigEvents(userId)
// 		ls.set('token', token);
// 		ls.set('userId', userId);
// 		ls.set('name', name);
// 		ls.set('email', email);
// 		ls.set('whitelabelId', whitelabelId);

// 		//Fetch events
// 		dispatch(goPouchDBPostLogin());
// 	};
// };

export const loginCompleted = (
	token,
	userId,
	name,
	email,
	whitelabelId,
	phone,
	isWhitelabelAdmin,
	companyName,
	cookie,
	sessionId
) => {
	return async (dispatch, getState) => {
		if (!userId) {
			console.error('Invalid login response: userId is undefined');
			dispatch(loginFailed('Invalid login response'));
			return;
		}

		dispatch(loginSuccess(token, userId, name, email, whitelabelId, phone, isWhitelabelAdmin));

		console.log('Login completed');
		console.log('Company Name: ', companyName);
		console.log('Cookie: ', cookie);
		console.log('Session ID: ', sessionId);
		console.log('Token: ', token);
		console.log('User ID: ', userId);
		console.log('Name: ', name);
		console.log('Email: ', email);
		console.log('Whitelabel ID: ', whitelabelId);
		console.log('Phone: ', phone);
		console.log('Is Whitelabel Admin: ', isWhitelabelAdmin);

		let pathName = getState().router.location.pathname;

		if (pathName === '/' || pathName === '/login') {
			dispatch(replace('/dashboard'));
		}

		ls.set('token', token);
		ls.set('userId', userId);
		ls.set('name', name);
		ls.set('email', email);
		ls.set('whitelabelId', whitelabelId);

		dispatch(goPouchDBPostLogin());
	};
};

export const updatePhoneNumber = (userId, phoneNumber) => {
	return async (dispatch) => {
		try {
			await updateUserPhoneNumber(userId, phoneNumber);
			dispatch({
				type: ACTION_UPDATE_PHONE_NUMBER,
				phone: phoneNumber
			});
		} catch (err) {
			console.error('Failed to update phone number:', err);
			throw err;
		}
	};
};

export const logout = () => {
	return (dispatch) => {
		if (window && window.FB) {
			let FB = window.FB;
			FB.getLoginStatus((response) => {
				if (response.status && response.status === 'connected') {
					console.log('Logging out from Facebook');
					FB.logout((response) => {});
				}
			});
		}
		console.log('Clearing local storage');
		localStorage.clear();
		dispatch(needsLogin());
		dispatch(logoutAction());
		Cookie.remove('SyncGatewaySession', { path: '/kamero' });
		Cookie.remove('SyncGatewaySession', { path: '/' });
		// Cookie.remove('SyncGatewaySession', { domain: '.kamero.in' });
	};
};

export const clearAuth = () => {
	console.log('Clearing local storage');
	localStorage.clear();
};

const logoutPostAction = () => {
	return (dispatch) => {
		dispatch(needsLogin());
		dispatch(logoutAction());
		Cookie.remove('SyncGatewaySession', { path: '/kamero' });
		Cookie.remove('SyncGatewaySession', { path: '/' });
		Cookie.remove('SyncGatewaySession', { domain: '.kamero.in' });
	};
};

export const appLaunched = () => {
	return async (dispatch, getState) => {
		console.log('App Launched');

		await configureSwaggerClient();
		if (Cookie.get('SyncGatewaySession')) {
			try {
				let response = await verifySession();
				dispatch(
					loginCompleted(
						undefined,
						response.userId,
						response.name,
						response.email,
						response.whitelabelId,
						response.phone,
						response.isWhitelabelAdmin,
						response.companyName,
						response.cookie,
						response.sessionId
					)
				);
			} catch (e) {
				console.log('verification failed ' + e.message);
				dispatch(replace('/login'));
			}
		} else {
			console.log('Logged in user not found.');
			dispatch(replace('/login'));
		}
	};
};

export const login = (token, email, password, otp) => {
	return async (dispatch, getState) => {
		console.log('Logging in couchbase');
		try {
			dispatch(loginStarted());
			let response;
			if (token) {
				response = await loginWithFB(token);
			} else if (email && password && email.length > 0 && password.length > 0) {
				response = await loginWithCredentials(email, password, otp);
			} else {
				throw new Error('Invalid login credentials');
			}

			console.log('Login response', response);

			if (!response || !response.userId) {
				throw new Error('Invalid login response');
			}

			dispatch(
				loginCompleted(
					token,
					response.userId,
					response.name,
					response.email,
					response.whitelabelId,
					response.phone,
					response.isWhitelabelAdmin,
					response.companyName,
					response.cookie,
					response.sessionId
				)
			);
		} catch (err) {
			console.error('Login failed', err);
			let errorMessage = 'An unexpected error occurred';
			if (err.status === 401) {
				errorMessage = otp
					? 'Invalid OTP. Please try again with the correct OTP.'
					: 'Invalid email or password. Please try again with correct credentials.';
			} else if (err.message) {
				errorMessage = err.message;
			}
			dispatch(loginFailed(errorMessage));
		}
	};
};
