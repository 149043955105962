import { connect } from 'react-redux';
import VideoListComp from '../components/VideoListComp';
import {
	videoLinkClickedMAction,
	editVideoLinkNameMAction,
	deleteVideoLinkMAction,
	editVideoLinkThumbnailMAction,
	uploadThumbnailImage
} from '../actions/videoLinkActions';

const mapStateToProps = (state) => ({
	videoLinks: state.videoLink.visibleVideoLinks,
	visibleEvent: state.pouchDB.visibleEvent,
	allReduxState: state
});

const mapDispatchToProps = (dispatch) => ({
	onVideoClick: (videoLink) => {
		dispatch(videoLinkClickedMAction(videoLink));
	},
	videoLinkNameEdited: (index, event, newName, videoLink) => {
		dispatch(editVideoLinkNameMAction(index, event, videoLink, newName));
	},
	videoLinkThumbnailEdited: (
		index,
		event,
		name,
		videoLink,
		newThumbnailUrl,
		openNotificationWithIcon
	) => {
		dispatch(
			editVideoLinkThumbnailMAction(
				index,
				event,
				videoLink,
				name,
				newThumbnailUrl,
				openNotificationWithIcon
			)
		);
	},
	uploadThumbnailImage: (image, videoLink, openNotificationWithIcon) => {
		dispatch(uploadThumbnailImage(image, videoLink, openNotificationWithIcon));
	},
	deleteVideoLink: (index, event, videoLink) => {
		dispatch(deleteVideoLinkMAction(index, event, videoLink.id));
	}
});

const VideoLinks = connect(mapStateToProps, mapDispatchToProps)(VideoListComp);

export default VideoLinks;
