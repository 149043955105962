import { swaggerClient } from '../../swagger'

class Purchase {
  static fetchPurchases = async (userId, whitelabelId, consistent) => {
    try {
      let result = await swaggerClient().apis.purchase.purchase_list({
        userId: userId,
        whitelabelId: whitelabelId,
        consistent: consistent
      })
      return JSON.parse(result.data)
    } catch (err) {
      if (err.message) {
        throw new Error(err.message)
      }

      let response = JSON.parse(err.response.text)
      if (response && response.message) {
        throw new Error(response.message)
      }

      throw new Error('Server Error. Try again.')
    }
  }
}

export default Purchase
