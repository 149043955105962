import { ACTION_INVOICE_DOWNLOAD_SUCCESS, ACTION_INVOICE_DOWNLOAD_FAILURE } from './action_types';
import Invoice from '../pouchDB/models/Invoice';

export const invoiceDownloadSuccessAction = (url) => ({
	type: ACTION_INVOICE_DOWNLOAD_SUCCESS,
	url
});
export const invoiceDownloadFailureAction = (error) => ({
	type: ACTION_INVOICE_DOWNLOAD_FAILURE,
	error
});

export const downloadInvoiceMAction = (orderId, createdAt) => {
	return (dispatch, getState) => {
		const { userId } = getState().auth;
		return Invoice.downloadInvoice(userId, orderId, createdAt)
			.then((response) => {
				dispatch(invoiceDownloadSuccessAction(response.url));
				return Promise.resolve(response.url);
			})
			.catch((error) => {
				dispatch(invoiceDownloadFailureAction(error));
				console.log(error);
				return Promise.reject(error);
			});
	};
};
