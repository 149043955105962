import { CloseOutlined, InfoCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Modal, Space, Table, Tag, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExpiredFacesEventsMAction } from '../actions/pouchDBActions';
import RenewFaceModal from './RenewFaceModal';
import RenewalFaceLinkModal from './RenewalFaceLinkModal';

const { Text, Paragraph } = Typography;

const ExpiringFaceEventsBanner = () => {
	const dispatch = useDispatch();
	const expiredFacesEvents = useSelector((state) => state.pouchDB?.expiredFacesEvents);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [isBannerVisible, setIsBannerVisible] = useState(true);

	useEffect(() => {
		dispatch(fetchExpiredFacesEventsMAction());
		const checkIfMobile = () => setIsMobile(window.innerWidth <= 768);
		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);
		return () => window.removeEventListener('resize', checkIfMobile);
	}, [dispatch]);

	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleRenewed = () => {
		dispatch(fetchExpiredFacesEventsMAction());
	};

	const getDaysUntilExpiry = (facesExpiresAt) => {
		if (!facesExpiresAt) return 0;
		const today = new Date();
		const expiryDate = new Date(facesExpiresAt);
		const timeDiff = expiryDate.getTime() - today.getTime();
		const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
		return daysDiff;
	};

	const columns = [
		{
			title: 'Event Name',
			dataIndex: 'name',
			key: 'name'
		},
		{
			title: 'Event ID',
			dataIndex: 'eventId',
			key: 'eventId',
			render: (text, record) => record.hashId || record.channel
		},
		!isMobile && {
			title: 'Face Recognition Status',
			key: 'status',
			render: (_, record) => {
				const daysUntilExpiry = getDaysUntilExpiry(record.facesExpiresAt);
				if (daysUntilExpiry < 0) {
					return <Tag color="red">Expired {Math.abs(daysUntilExpiry)} days ago</Tag>;
				} else if (daysUntilExpiry === 0) {
					return <Tag color="red">Expires today</Tag>;
				} else if (daysUntilExpiry <= 30) {
					return <Tag color="orange">Expires in {daysUntilExpiry} days</Tag>;
				} else {
					return <Tag color="green">Active</Tag>;
				}
			}
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => {
				const daysUntilExpiry = getDaysUntilExpiry(record.facesExpiresAt);
				return isMobile ? (
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
						<RenewFaceModal event={record} onRenewed={handleRenewed} showAsLink={true} />
						<RenewalFaceLinkModal
							event={record}
							showAsLink={true}
							daysUntilExpiry={daysUntilExpiry}
						/>
					</div>
				) : (
					<Space>
						<RenewFaceModal event={record} onRenewed={handleRenewed} showAsLink={false} />
						<RenewalFaceLinkModal
							event={record}
							showAsLink={false}
							daysUntilExpiry={daysUntilExpiry}
						/>
					</Space>
				);
			}
		}
	].filter(Boolean);

	const expiredOrExpiringFacesEvents =
		expiredFacesEvents && Array.isArray(expiredFacesEvents)
			? expiredFacesEvents.filter(
					(event) => event && event.facesExpiresAt && getDaysUntilExpiry(event.facesExpiresAt) <= 30
			  )
			: [];

	if (expiredOrExpiringFacesEvents.length === 0 || !isBannerVisible) {
		return null;
	}

	const OfferBanner = ({ daysUntilExpiry }) => {
		if (daysUntilExpiry < 0) return null;
		return (
			<div
				style={{
					border: '2px solid #6F4898',
					backgroundColor: '#F0EBF7',
					padding: '12px',
					borderRadius: '9px',
					marginTop: '20px',
					marginBottom: '20px',
					textAlign: 'center'
				}}>
				<Text
					strong
					style={{
						fontSize: '18px',
						color: '#6F4898'
					}}>
					Limited Time Offer: 50% Bonus on Face Recognition Renewals!
				</Text>
			</div>
		);
	};

	return (
		<div className="expiring-face-events-banner">
			<Alert
				message={
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							marginRight: '10px'
						}}>
						<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<Text strong style={{ fontSize: '16px' }}>
								Attention: Face Recognition for {expiredOrExpiringFacesEvents.length} event
								{expiredOrExpiringFacesEvents.length > 1 ? 's' : ''}{' '}
								{expiredOrExpiringFacesEvents.length > 1 ? 'are' : 'is'} expired or expiring soon!
							</Text>
							{!isMobile && (
								<Tag
									color="#6F4898"
									style={{
										borderRadius: '20px',
										padding: '2px 10px',
										fontSize: '12px',
										fontWeight: 'bold',
										textTransform: 'uppercase',
										boxShadow: '0 2px 4px rgba(111, 72, 152, 0.3)',
										animation: 'pulse 2s infinite',
										marginLeft: '10px'
									}}>
									Renew Before Expiry 50% off
								</Tag>
							)}
						</div>
						<Button type="primary" onClick={showModal}>
							{isMobile ? 'Details' : 'View Details'}
						</Button>
					</div>
				}
				type="error"
				showIcon
				icon={<WarningOutlined style={{ color: 'red', fontSize: '16px' }} />}
			/>
			<div className="close-button-wrapper" onClick={() => setIsBannerVisible(false)}>
				<div className="close-button">
					<CloseOutlined className="close-icon" />
				</div>
			</div>
			<style jsx>{`
				.expiring-face-events-banner {
					position: relative;
				}
				.close-button-wrapper {
					position: absolute;
					top: -12px;
					right: -12px;
					cursor: pointer;
					transition: all 0.3s ease;
					z-index: 1;
				}
				.close-button {
					width: 24px;
					height: 24px;
					border-radius: 50%;
					background-color: rgba(255, 255, 255, 0.8);
					display: flex;
					justify-content: center;
					align-items: center;
					box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
					transition: all 0.3s ease;
				}
				.close-icon {
					font-size: 12px;
					color: #6f4898;
					transition: all 0.3s ease;
				}
				.close-button-wrapper:hover {
					transform: scale(1.1);
				}
				.close-button-wrapper:hover .close-button {
					background-color: #6f4898;
				}
				.close-button-wrapper:hover .close-icon {
					color: white;
				}

				@media (max-width: 768px) {
					.close-button-wrapper {
						top: -6px;
						right: -6px;
					}
					.close-button {
						width: 20px;
						height: 20px;
					}
					.close-icon {
						font-size: 10px;
					}
				}
			`}</style>

			<Modal
				open={isModalVisible}
				title={
					<Space align="center">
						<WarningOutlined style={{ color: 'orange', fontSize: '24px' }} />
						<h3 style={{ marginLeft: '10px' }}>
							Attention: Face Recognition for {expiredOrExpiringFacesEvents.length} event
							{expiredOrExpiringFacesEvents.length !== 1 ? 's' : ''} expired or expiring soon!
						</h3>
					</Space>
				}
				onCancel={handleCancel}
				footer={null}
				width={isMobile ? '100%' : 800}>
				<Paragraph>
					Face Recognition for some of your events has expired or will expire within the next 30
					days.
				</Paragraph>
				<Paragraph
					style={{
						color: '#D32F2F',
						borderLeft: '4px solid #D32F2F',
						backgroundColor: '#FFEBEE',
						padding: '10px',
						fontWeight: 500,
						marginBottom: '15px'
					}}>
					<WarningOutlined style={{ marginRight: '8px' }} />
					Important: After expiration, the Face Recognition feature will be disabled for the event.
					Renew before expiry to enjoy a 50% bonus on the paid amount (before GST).
				</Paragraph>

				<OfferBanner
					daysUntilExpiry={Math.min(
						...expiredOrExpiringFacesEvents.map((e) => getDaysUntilExpiry(e.facesExpiresAt))
					)}
				/>

				<Alert
					message="Face Recognition Renewal Information"
					description={
						<div>
							<Paragraph>Please note the following about Face Recognition renewals:</Paragraph>
							<ul>
								<li>
									Renew before or on the expiry date to get a 50% bonus on the paid amount (before
									GST).
								</li>
								<li>Renewals after the expiry date will be at full price.</li>
								<li>
									For expired events, we need to re-index all photos, which is why full price
									applies.
								</li>
							</ul>
							<Paragraph>
								Renew now to keep enjoying AI-powered facial recognition and smart galleries for
								your events!
							</Paragraph>
						</div>
					}
					type="info"
					showIcon
					icon={<InfoCircleOutlined style={{ color: '#1890ff', fontSize: '24px' }} />}
					style={{ marginBottom: '20px' }}
				/>

				<Table
					columns={columns}
					dataSource={expiredOrExpiringFacesEvents}
					rowKey="id"
					pagination={false}
					style={{
						marginTop: '20px',
						...(isMobile
							? {}
							: {
									border: '1px solid #6F4898',
									borderRadius: '4px',
									overflow: 'hidden'
							  })
					}}
					className={isMobile ? 'mobile-table' : ''}
				/>
			</Modal>
		</div>
	);
};

export default ExpiringFaceEventsBanner;
