import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';

import { Row, Col, Input, Button } from 'antd';

const ALBUM_NAME_LENGTH = 20;
class TextFieldButtonComp extends Component {
	constructor(props) {
		super(props);
		this.state = { value: '', isMobile: window.screen.width <= 576 };
		bindAll(this, ['handleChange', 'handleKeyPress', 'handleWindowResize']);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	handleChange = (e) => {
		this.setState({ value: e.target.value });
	};

	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();

			if (this.state.value.length > 0) {
				const { textFieldUpdated } = this.props;

				textFieldUpdated(this.state.value);
				this.setState({ value: '' });
			}
		}
	};

	render() {
		const { placeholder, actionButton, textFieldUpdated } = this.props;

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Input
					style={{ fontSize: '16px', marginRight: '10px' }}
					type="text"
					value={this.state.value}
					placeholder={placeholder}
					onChange={this.handleChange}
					maxLength={ALBUM_NAME_LENGTH}
					onPressEnter={this.handleKeyPress}
				/>
				<Button
					disabled={this.state.value.length <= 0}
					onClick={(e) => {
						e.preventDefault();
						if (this.state.value.length <= 0) {
							return;
						}
						textFieldUpdated(this.state.value);
						this.setState({ value: '' });
					}}>
					{actionButton}
				</Button>
			</div>
			// <Row justify="space-between" type="flex" align="middle">
			// 	<Col span={this.state.isMobile ? 20 : 17}>
			// 		<Input
			// 			style={{ fontSize: '16px' }}
			// 			type="text"
			// 			value={this.state.value}
			// 			placeholder={placeholder}
			// 			onChange={this.handleChange}
			// 			maxLength={ALBUM_NAME_LENGTH}
			// 			onPressEnter={this.handleKeyPress}
			// 		/>
			// 	</Col>
			// 	<Col>
			// 		<Button
			// 			disabled={this.state.value.length <= 0}
			// 			onClick={(e) => {
			// 				e.preventDefault();
			// 				if (this.state.value.length <= 0) {
			// 					return;
			// 				}
			// 				textFieldUpdated(this.state.value);
			// 				this.setState({ value: '' });
			// 			}}>
			// 			{actionButton}
			// 		</Button>
			// 	</Col>
			// </Row>
		);
	}
}

TextFieldButtonComp.propTypes = {
	textFieldUpdated: PropTypes.func.isRequired,
	placeholder: PropTypes.string.isRequired,
	actionButton: PropTypes.string.isRequired
};

export default TextFieldButtonComp;
