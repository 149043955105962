import { connect } from 'react-redux'
import LoginComp from '../components/LoginComp'
import { clearLoginErr, login} from "../actions/loginActions"
import { sendOTP } from "../swagger"

const mapStateToProps = (state) => ({
  isLogging: state.auth.isLoggingIn,
  lastError: state.auth.lastError
})

const mapDispatchToProps = (dispatch) => ({
  login: async (token, email, password, otp) => {
    dispatch(await login(token, email, password, otp))
  },
  sendOTP: async (email) => {
    return await sendOTP(email)
  },
  clearLoginErr: () => {
    dispatch(clearLoginErr())
  }
})


const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComp)


export default Login
