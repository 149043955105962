import React, {Component} from 'react'
import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg'

class FullScreenIndicator extends Component{
  render(){
    return (
      <div className='flexCenterFullScreenContainer' style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
        <div className="flexCenterPageChild">
          <IndicatorSVG/>
        </div>
      </div>
    )
  }
}

export default FullScreenIndicator
