import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddBillingDetailsForm from './AddBillingDetails';
import { Empty, Badge, message, Collapse, Button } from 'antd';
import Order from '../pouchDB/models/Order';
import { fetchBillingDetailsMAction } from '../actions/BillingDetailsActions';
import { DownloadOutlined } from '@ant-design/icons';
import { downloadInvoiceMAction } from '../actions/downloadInvoice';
import { Spin } from 'antd';

const { Panel } = Collapse;

class OrderListComp extends Component {
	state = {
		orders: undefined,
		showAddBillingForm: false,
		editingBusiness: false,
		loading: true,
		billingDetails: null
	};

	componentDidMount() {
		this.fetchBillingDetails();
		this.fetchOrders();
	}

	fetchBillingDetails = async () => {
		const { fetchBillingDetails } = this.props;
		try {
			const billingDetails = await fetchBillingDetails();
			this.setState({ billingDetails, loading: false });
		} catch (error) {
			console.error('Error fetching billing details:', error);
			message.warning(
				'You have not added billing details yet. Please add billing details for proper invoice.'
			);
			this.setState({ loading: false });
		}
	};

	fetchOrders = async () => {
		const { userId, fetchOrders } = this.props;
		try {
			let orders = await fetchOrders(userId);
			console.log(orders);
			this.setState({ orders: orders });
		} catch (err) {
			message.error(err.message);
			this.setState({ orders: [] });
		}
	};

	downloadInvoice = async (userId, orderId) => {
		const { downloadInvoice } = this.props;
		try {
			const url = await downloadInvoice(userId, orderId);
			window.open(url, '_blank');
		} catch (error) {
			message.error('Error downloading invoice: ' + error.message);
		}
	};

	getOrdersSection(orders) {
		if (orders === undefined || orders.length === 0) {
			return <Empty description="No Orders" style={{ marginTop: '250px' }} />;
		}

		console.log('orders', orders);

		return (
			<div className="orders-container">
				{orders &&
					orders.map((order) => (
						<Collapse key={order.id} className="order-card">
							<div className="order-id-collapse">
								<Collapse>
									<Panel
										header={
											<div>
												<p>
													<strong>Order ID:</strong> {order.id}
												</p>
												<div className="order-details">
													<p>
														<strong>Amount:</strong>{' '}
														{(order.amount / 100).toLocaleString('en-IN', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
															style: 'currency',
															currency: 'INR'
														})}
													</p>
													<p>
														<strong>Date:</strong>{' '}
														{new Date(order.createdAt).toLocaleDateString('en-GB', {
															day: '2-digit',
															month: '2-digit',
															year: '2-digit'
														})}
													</p>
													<p style={{ marginTop: '-10px' }}>
														<strong>State:</strong>{' '}
														<Badge
															className="pack-badge"
															count={order.state}
															style={{
																backgroundColor: order.state === 'confirmed' ? '#6F4898' : '#52c41a'
															}}
														/>
													</p>
													{order.invoiceNumber ? (
														<Button
															icon={<DownloadOutlined />}
															onClick={() => this.downloadInvoice(order.id, order.createdAt)}>
															Download Invoice
														</Button>
													) : null}
												</div>
											</div>
										}
										key="1">
										<>
											{order.items.map((item) => (
												<div key={item.product.id}>
													<p>
														<strong>Product ID&nbsp;:</strong>&nbsp;{item.product.id}
													</p>
													<p>
														<strong>Quantity&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong>&nbsp;
														{item.quantity}
													</p>
												</div>
											))}
											<div>&nbsp;</div>
											{order.invoiceBreakup && (
												<div>
													{order.invoiceBreakup.Subtotal > 0 && (
														<p>
															<strong>Subtotal&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong>{' '}
															{order.invoiceBreakup.Subtotal.toLocaleString('en-IN', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
																style: 'currency',
																currency: 'INR'
															})}
														</p>
													)}
													{order.invoiceBreakup.Tax > 0 && (
														<p>
															<strong>GST (Tax)&nbsp;&nbsp;:</strong>{' '}
															{order.invoiceBreakup.Tax.toLocaleString('en-IN', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
																style: 'currency',
																currency: 'INR'
															})}
														</p>
													)}
													{order.invoiceBreakup.Total > 0 && (
														<p>
															<strong>
																Total&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
															</strong>{' '}
															{order.invoiceBreakup.Total.toLocaleString('en-IN', {
																minimumFractionDigits: 2,
																maximumFractionDigits: 2,
																style: 'currency',
																currency: 'INR'
															})}
														</p>
													)}
													{order.razorPayPaymentId && (
														<p>
															<strong>Payment ID:</strong> {order.razorPayPaymentId}
														</p>
													)}
												</div>
											)}
										</>
									</Panel>
								</Collapse>
							</div>
						</Collapse>
					))}
			</div>
		);
	}

	render() {
		const { orders, loading, billingDetails } = this.state;

		if (loading) {
			return <Spin />;
		}

		return (
			<div>
				<div className="pageTitleBar">
					<h1 className="pageTitle">My Orders</h1>
					{billingDetails ? (
						<Button
							type="primary"
							className="pageRightDetail"
							onClick={() => this.setState({ showAddBillingForm: true })}>
							Update Billing Details
						</Button>
					) : (
						<Button
							type="primary"
							className="pageRightDetail"
							onClick={() => this.setState({ showAddBillingForm: true })}>
							Add Billing Details
						</Button>
					)}
				</div>
				{this.state.showAddBillingForm ? (
					<AddBillingDetailsForm
						editingBillingDetails={billingDetails ? billingDetails : null}
						onClose={() => this.setState({ showAddBillingForm: false, editingBusiness: undefined })}
					/>
				) : null}
				{this.getOrdersSection(orders)}
			</div>
		);
	}
}

OrderListComp.propTypes = {
	userId: PropTypes.string.isRequired,
	fetchOrders: PropTypes.func.isRequired,
	fetchBillingDetails: PropTypes.func.isRequired,
	downloadInvoice: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchOrders: async (userId) => {
		return await Order.orderList(userId);
	},
	fetchBillingDetails: () => {
		return dispatch(fetchBillingDetailsMAction());
	},
	downloadInvoice: (orderId, createdAt) => {
		return dispatch(downloadInvoiceMAction(orderId, createdAt));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderListComp);
