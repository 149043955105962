import { swaggerClient } from '../../swagger';

class Invoice {
	static downloadInvoice = async (userId, orderId, createdAt) => {
		try {
			const result = await swaggerClient().apis.invoice.download_invoice({
				userId: userId,
				orderId: orderId,
				createdAt: createdAt
			});
			return JSON.parse(result.data);
		} catch (err) {
			if (err.message) {
				throw new Error(err.message);
			}
			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				throw new Error(response.message);
			}
			throw new Error('Server Error. Try again.');
		}
	};
}

export default Invoice;
