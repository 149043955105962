import { errorHandler, swaggerClient } from '../../swagger';
import Photo from './Photo';

export const EVENT_PAGE_SIZE = 16;
class Event {
	static dataChannel = (channel) => channel + '-data';

	static getUploadImageResolutionForEvent = (event, userId) => {
		return Promise.resolve(DEFAULT_IMAGE_RESOLUTION);
	};

	static createEvent = async (
		userId,
		name,
		packId,
		purchaseId,
		isGuestUploadEnabled,
		guestMaxPhotos
	) => {
		try {
			let result = await swaggerClient().apis.event.create_event({
				userId: userId,
				eventToCreate: {
					name: name,
					packageId: packId,
					purchaseId: purchaseId,
					isGuestUploadEnabled: isGuestUploadEnabled,
					guestMaxPhotos: guestMaxPhotos
				}
			});
			return JSON.parse(result.data);
		} catch (err) {
			if (err.message) {
				throw new Error(err.message);
			}

			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				throw new Error(response.message);
			}

			throw new Error('Server Error. Try again.');
		}
	};

	static makeChannel = () => {
		var text = '';
		var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (var i = 0; i < 6; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text.toLowerCase();
	};

	static getTotalEventCount = (userId) => {
		return swaggerClient()
			.apis.event.event_count_by_user({
				userId: userId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static fetchEvent = async (eventDocId) => {
		try {
			let result = await swaggerClient().apis.event.event({
				eventDocId: eventDocId
			});

			console.log('event full', result.data);
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	};

	static fetchEvents = (userId, pageNumber, consistent) => {
		return swaggerClient()
			.apis.event.event_list_by_user({
				userId: userId,
				limit: EVENT_PAGE_SIZE.toString(),
				skip: (pageNumber * EVENT_PAGE_SIZE).toString(),
				consistent: consistent
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static fetchEventCounts = (eventDocIds) => {
		return swaggerClient()
			.apis.photo.photo_count_by_events({
				eventDocIds: eventDocIds
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static fetchEventHome = (event, album, consistent) => {
		return swaggerClient()
			.apis.event.event_home({
				eventDocId: event.id,
				albumDocId: album ? album.id : undefined,
				consistent: consistent ? consistent : false,
				sortBy: event.sortBy,
				skip: '0',
				limit: Photo.photosPerPage().toString()
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static updateEventName(userId, eventDocId, newEventName) {
		return swaggerClient().apis.event.edit_event_name({
			userId: userId,
			eventDocId: eventDocId,
			newName: newEventName
		});
	}

	static updateEventExpiry(userId, eventDocId, newExpiry) {
		return swaggerClient().apis.event.edit_event_expiry({
			userId: userId,
			eventDocId: eventDocId,
			newExpiry: newExpiry
		});
	}

	static updateAlbumsOrder(userId, eventDocId, albums) {
		return swaggerClient().apis.event.reorder_albums({
			userId: userId,
			eventDocId: eventDocId,
			albums: albums
		});
	}

	static searchEvents(userId, searchText) {
		return swaggerClient()
			.apis.event.search_events_for_user({
				userId: userId,
				searchString: searchText
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static updateEventSortBy(userId, eventDocId, newSortBy) {
		return swaggerClient().apis.event.edit_event_sortby({
			userId: userId,
			eventDocId: eventDocId,
			sortBy: newSortBy
		});
	}

	static assignHashId(userId, eventDocId, newEventId) {
		return swaggerClient().apis.event.assign_event_hashid({
			userId: userId,
			eventDocId: eventDocId,
			hashId: newEventId
		});
	}

	static deleteEvent(eventId, userId) {
		return swaggerClient().apis.event.archive_event({
			userId: userId,
			eventDocId: eventId
		});
	}

	static getEventId(event) {
		if (event.hashId) {
			return event.hashId;
		}
		return event.channel.toUpperCase();
	}

	static setCoverPhoto(userId, photoDocId) {
		return swaggerClient()
			.apis.event.set_event_cover({
				userId: userId,
				photoDocId: photoDocId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static async fetchShareDetail(eventDocId) {
		try {
			let result = await swaggerClient().apis.event.share_detail({
				eventDocId: eventDocId
			});
			return JSON.parse(result.data);
		} catch (err) {
			errorHandler(err);
		}
	}
	static fetchAdminRequestList(userId, eventDocId) {
		// console.log(userId, eventDocId,"userId, eventDocId")
		console.log(swaggerClient().apis.event, 'swagger');

		return swaggerClient()
			.apis.event?.admin_permission_list({
				userId: userId,
				eventDocId: eventDocId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}
	static adminPermission(userId, eventDocId, permissionDocId, type) {
		// console.log(userId, eventDocId,"userId, eventDocId")
		console.log(userId, eventDocId, permissionDocId, type, 'check ');

		return swaggerClient()
			.apis.event?.admin_permission({
				userId: userId,
				eventDocId: eventDocId,
				permissionDocId: permissionDocId,
				action: type
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static editEventBoolKey(userId, eventDocId, key, value) {
		return swaggerClient().apis.event.edit_event_boolkey({
			userId: userId,
			eventDocId: eventDocId,
			key: key,
			value: value
		});
	}
	static editEventGuestMaxPhotos(userId, eventDocId, value) {
		return swaggerClient().apis.event?.edit_event_guestmaxphotos({
			userId: userId,
			eventDocId: eventDocId,
			newGuestMaxphotos: value
		});
	}
	static async getRegistrations(userId, eventDocId) {
		try {
			let result = await swaggerClient().apis.event.registrations({
				userId: userId,
				eventDocId: eventDocId
			});
			return JSON.parse(result.data);
		} catch (err) {
			if (err.response.status === 404) {
				return [];
			} else {
				errorHandler(err);
			}
		}
	}

	static async downloadAllPhotos(eventDocId, email, pin) {
		try {
			let result = await swaggerClient().apis.event.zip_subscribe({
				eventDocId: eventDocId,
				email: email,
				pin: pin
			});

			if (result.status === 200) {
				return 'You will receive a link to download all photos via email within an hour.';
			}
			return { message: 'Something went wrong' };
		} catch (err) {
			return { message: 'Something went wrong' };
		}
	}

	static fetchExpiredEvents = (userId) => {
		return swaggerClient()
			.apis.event.get_expired_events({
				userId: userId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			})
			.catch((err) => {
				errorHandler(err);
			});
	};

	static fetchFaceExpiringEvents = (userId) => {
		console.log('fetchFaceExpiringEvents', userId);
		return swaggerClient()
			.apis.event.get_expired_face_events({
				userId: userId
			})
			.then((result) => {
				console.log('result', result);
				return Promise.resolve(JSON.parse(result.data));
			})
			.catch((err) => {
				console.log('err', err);
				errorHandler(err);
			});
	};
}

export default Event;
