import React, { useState } from 'react';
import { CopyOutlined, WhatsAppOutlined, MailOutlined } from '@ant-design/icons';
import { Tooltip, Button } from 'antd';

const ShareMessage = ({ shareDetail, auth }) => {
	const [copied, setCopied] = useState(0);

	const copyToClipboard = (text, int) => {
		navigator.clipboard
			.writeText(text)
			.then(() => {
				setCopied(int);
				setTimeout(() => {
					setCopied(false);
				}, 3000);
			})
			.catch((err) => {
				console.error('Failed to copy text: ', err);
			});
	};

	const openWhatsApp = () => {
		const url = shareDetail.whatsAppLink;
		window.open(url, '_blank');
	};

	const openEmailApp = () => {
		const subject = encodeURIComponent(shareDetail.emailSubject);
		const body = encodeURIComponent(shareDetail.emailBody);
		const mailtoLink = `mailto:?subject=${subject}&body=${body}`;
		window.open(mailtoLink, '_blank');
	};

	return (
		<>
			<span style={{ color: '#555', userSelect: 'none', fontSize: '16px', fontWeight: 400 }}>
				Click COPY to share the app download links with the Event ID.
			</span>
			<div
				className="share-copy-wrapper"
				style={{ padding: '24px', marginTop: '16px' }}
				onClick={() => copyToClipboard(shareDetail.appLinks.replaceAll('\\\\\\\\\\\\\\\\n', '\n'))}>
				<pre style={{ marginBottom: '0px', whiteSpace: 'pre-wrap' }}>
					{shareDetail.appLinks.replaceAll('\\\\\\\\\\\\\\\\n', '\n')}
				</pre>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '8px'
				}}>
				<Button
					icon={<MailOutlined />}
					style={{ color: '#D44638', fontSize: '18px', marginRight: '8px' }}
					onClick={openEmailApp}
				/>
				<Button
					icon={<WhatsAppOutlined />}
					style={{ color: '#25D366', fontSize: '18px', marginRight: '8px' }}
					onClick={openWhatsApp}
				/>
				<Tooltip title={copied ? 'Copied!' : 'Copy Invite'}>
					<Button
						icon={<CopyOutlined style={{ color: copied ? '##6F4898' : '#525154' }} />}
						style={{ fontSize: '18px', marginRight: '8px' }}
						onClick={() =>
							copyToClipboard(shareDetail.appLinks.replaceAll('\\\\\\\\\\\\\\\\n', '\n'), 1)
						}
					/>
				</Tooltip>
			</div>
			<br />
			<strong style={{ fontSize: '16px', fontWeight: 500, color: '#4A5568' }}>WebLink</strong>
			<div
				className="share-copy-wrapper"
				style={{ marginTop: '4px', marginBottom: '4px', display: 'flex', alignItems: 'center' }}
				onClick={() => copyToClipboard(shareDetail.webLink, 2)}>
				<span
					style={{ marginBottom: '0px', fontSize: '16px', fontWeight: 400, marginRight: '8px' }}>
					{shareDetail.webLink}
				</span>
				{copied === 2 ? (
					<div className="share-copy copied" style={{ fontSize: '14px', fontWeight: 600 }}>
						Copied!
					</div>
				) : (
					<div className="share-copy" style={{ fontSize: '14px', fontWeight: 600 }}>
						COPY
					</div>
				)}
			</div>
			<span style={{ color: '#666', fontSize: '14px', fontWeight: 400 }}>
				Share this unique event URL with your clients to access photos directly through a web
				browser
			</span>
		</>
	);
};

export default ShareMessage;
