import { ACTION_REPLICATION_CONFIGURED, ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED } from './action_types'

const replicationChanged = (newState) => ({
  type : ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED,
  'newState' : newState
})

export const replicationConfigured = () => ({
  type : ACTION_REPLICATION_CONFIGURED
})


export const continousReplicationChanged = (userId, newState, info) =>{
  return (dispatch) => {
    dispatch(replicationChanged(newState))
  }
}
