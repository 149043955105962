import { swaggerClient } from '../../swagger'

class Push {
  static sendPush = (userId, event, album) => {
    return swaggerClient().apis.push.create_push_newphotos({
      userId: userId,
      pushDoc: {
        eventDocId: event.id,
        albumDocId: album.id
      }
    })
  }
}

export default Push
