import { Component } from 'react';
//import { swaggerClient, errorHandler } from '../../swagger';
import { swaggerClient } from '../swagger';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';

import { Typography, Row, Col, Space } from 'antd';
const { Title, Paragraph, Text, Link } = Typography;
import { Input, Modal, Button, message, Radio } from 'antd';
import { createPostMAction, editPostMAction } from '../actions/PostActions';
import { SearchOutlined, BarcodeOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import '../styles/EventHome.sass';
import debounce from 'lodash.debounce';

const FormItem = Form.Item;
const { TextArea } = Input;
//const formItemLayout = null;

const EventSearchCard = ({ event, isSelected, onClick }) => {
	return (
		<Row
			onClick={onClick}
			className="eventListHover"
			style={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				cursor: 'pointer',
				backgroundColor: isSelected && '#ffecee'
			}}>
			<Col xs={16} sm={20}>
				<span style={{ flex: 1, fontSize: '16px', color: '#2D3748', fontWeight: 400 }}>
					{event?.name}
				</span>
			</Col>
			<Col xs={4} sm={4}>
				<Space align="center" size={0}>
					<BarcodeOutlined className="eventListHover" style={{ fontSize: '14px' }} />
					<span style={{ marginLeft: '5px', fontSize: '14px', color: '#2D3748', fontWeight: 400 }}>
						{event.eventId}
					</span>
				</Space>
			</Col>
		</Row>
	);
};

class PostAd extends Component {
	state = {
		loading: false,
		brand: '',
		modalType: 'post', // for post model-modalType:'post', for search event modal-modalType:'event'
		postType: 'allUser',
		searchedEvents: [],
		searchEventText: '',
		selectedEvent: {},
		loadingSearch: false
	};

	handleOk = () => {
		this.props.form.validateFields((err, values) => {
			console.log(values);
			values.brand = this.state.brand;
			values.postType = this.state.postType;
			values.selectedEvent = this.state.selectedEvent;
			if (!err) {
				if (this.props.editingIndex === undefined) {
					this.createPost(values);
				} else {
					this.editPost(this.props.editingIndex, this.props.editingPost.id, values);
				}
			}
		});
	};

	createPost = (values) => {
		this.setState({ loading: true });
		console.log(values);
		this.props
			.createPost(
				values.text,
				values.notificationText,
				values.link,
				values.brand,
				values.postType,
				values.selectedEvent
			)
			.then((post) => {
				this.setState({ loading: false });
				this.props.onClose();
			})
			.catch((err) => {
				let response = JSON.parse(err.response.text);
				if (response && response.message) {
					message.error(response.message);
				}
				this.setState({ loading: false });
			});
	};

	editPost = (index, postDocId, values) => {
		this.setState({ loading: true });
		this.props
			.editPost(
				index,
				postDocId,
				values.text,
				values.notificationText,
				values.link,
				values.brand,
				values.postType,
				values.selectedEvent
			)
			.then((post) => {
				this.setState({ loading: false });
				this.props.onClose();
			})
			.catch((error) => {
				console.log(error);
				this.setState({ loading: false });
			});
	};

	handleCancel = () => {
		if (this.state.loading === true) {
			return;
		}

		this.props.onClose();
	};

	onRadioChange = (e) => {
		this.setState({
			postType: e.target.value,
			modalType: e.target.value == 'allUser' ? 'post' : 'event',
			selectedEvent: e.target.value == 'allUser' ? {} : this.state.selectedEvent
		});
	};

	onChangeSearch = (e) => {
		if (e.target.value) {
			swaggerClient()
				.apis.event.search_events_for_user({
					userId: this.props.userId,
					searchString: this.state.searchEventText
				})
				.then((result) => {
					this.setState({ loadingSearch: false });
					const data = JSON.parse(result.data);
					this.setState({ searchedEvents: data });
				})
				.catch((err) => {
					console.log(err);
					this.setState({ loadingSearch: false });
				});
		} else {
			this.setState({ loadingSearch: false });
		}
	};

	optimizedSearch = debounce((e) => this.onChangeSearch(e), 1000);

	componentDidMount() {
		this.setState({
			brand: this.props.editingPost?.brand,
			postType: this.props.editingPost?.postType || '',
			selectedEvent: this.props.editingPost?.selectedEvent || {}
		});
	}

	render = () => {
		const { getFieldDecorator } = this.props.form;
		const { editingPost, events } = this.props;
		// console.log(this.props,"editingPost, events")
		return (
			<>
				<Modal
					width={580}
					open={this.state.modalType == 'post' ? true : false}
					title="Engage with Users"
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					footer={[
						<Button key="back" onClick={this.handleCancel}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={this.state.loading}
							onClick={this.handleOk}>
							Post
						</Button>
					]}>
					{this.props.isWhitelabelAdmin === true && (
						<Row gutter={[10, 10]}>
							<Col span={24}>
								<Title level={5}>
									<h5
										style={{
											fontSize: '18px',
											height: '100%',
											color: '#2D3748',
											fontWeight: '500'
										}}>
										Promote your Brand
									</h5>
								</Title>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
								<Button
									danger={this.state.brand != 'Instagram'}
									type={this.state.brand == 'Instagram' ? 'primary' : ''}
									style={{
										width: '100%',
										height: '100%',
										fontSize: '16px',
										color: this.state.brand == 'Instagram' ? 'white' : '#6F4898',
										textAlign: 'center',
										fontWeight: '500'
									}}
									onClick={() => this.setState({ brand: 'Instagram' })}>
									Promote Instagram
								</Button>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
								<Button
									danger={this.state.brand != 'Youtube'}
									type={this.state.brand == 'Youtube' ? 'primary' : ''}
									style={{
										width: '100%',
										height: '100%',
										fontSize: '16px',
										color: this.state.brand == 'Youtube' ? 'white' : '#6F4898',
										textAlign: 'center',
										fontWeight: '500'
									}}
									onClick={() => this.setState({ brand: 'Youtube' })}>
									Promote Youtube
								</Button>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
								<Button
									danger={this.state.brand != 'AppStore'}
									type={this.state.brand == 'AppStore' ? 'primary' : ''}
									style={{
										width: '100%',
										height: '100%',
										fontSize: '16px',
										color: this.state.brand == 'AppStore' ? 'white' : '#6F4898',
										textAlign: 'center',
										fontWeight: '500'
									}}
									onClick={() => this.setState({ brand: 'AppStore' })}>
									App Store Rating
								</Button>
							</Col>
						</Row>
					)}
					<Form>
						<div style={{ padding: '8px', backgroundColor: '#fafafa' }}>
							<Col span={24}>
								<Title level={5}>
									<h5 style={{ fontSize: '18px', color: '#2D3748', fontWeight: '500' }}>
										Create Post
									</h5>
								</Title>
							</Col>
							<FormItem label="Post Text">
								{getFieldDecorator('text', {
									rules: [{ required: true, message: 'Please input Post Text!' }],
									initialValue: editingPost ? editingPost.text : null
								})(
									<TextArea
										spellCheck="false"
										rows={4}
										placeholder="What users will read on the Posts tab of your Mobile App"
									/>
								)}
							</FormItem>
							<FormItem label="Notification Text">
								{getFieldDecorator('notificationText', {
									rules: [{ required: true, message: 'Please input Notification Text!' }],
									initialValue: editingPost ? editingPost.notificationText : null
								})(
									<Input
										spellCheck="false"
										placeholder="What users will read on the Push Notification received"
									/>
								)}
							</FormItem>
							<FormItem label="Weblink for Post">
								{getFieldDecorator('link', {
									initialValue: editingPost ? editingPost.link : null
								})(
									<Input
										spellCheck="false"
										placeholder="You can directly paste links from Social Media, YouTube, Blogs, Websites etc. here"
									/>
								)}
							</FormItem>
							<Radio.Group
								name="radiogroup"
								onChange={this.onRadioChange}
								value={this.state.postType}
								style={{ width: '100%' }}>
								<Row gutter={[16, 16]}>
									{this.props.isWhitelabelAdmin === true && (
										<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 10 }}>
											<div style={{ backgroundColor: '#f4f4f5', padding: '5px' }}>
												<Radio value={'allUser'} style={{ fontSize: '16px', fontWeight: 400 }}>
													Post for all app user
												</Radio>
											</div>
										</Col>
									)}
									<Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 14 }}>
										<div
											style={{
												backgroundColor: '#f4f4f5',
												padding: '5px',
												display: 'flex',
												justifyContent: 'space-between'
											}}>
											<Radio value={'specificEvent'} style={{ fontSize: '16px', fontWeight: 400 }}>
												{Object.keys(this.state.selectedEvent).length > 0 &&
												this.state.postType == 'specificEvent'
													? this.state.selectedEvent.name
													: 'Post for specific event'}
											</Radio>
											{Object.keys(this.state.selectedEvent).length > 0 &&
												this.state.postType == 'specificEvent' && (
													<span
														style={{
															fontSize: '14px',
															color: '#6F4898',
															fontWeight: '500',
															cursor: 'pointer'
														}}
														onClick={() =>
															this.setState({
																modalType: 'event'
															})
														}>
														Edit
													</span>
												)}
										</div>
									</Col>
								</Row>
							</Radio.Group>
						</div>

						{/* <h4>
                    IMPORTANT : All Users of your App will receive Push Notifications on their phones
                    Whenever you Post.
                </h4> */}
						<p
							style={{
								fontSize: '14px',
								color: '#3F3F46',
								fontWeight: '500'
							}}>
							<strong>Note: </strong>For the benefit of your Users, We Recommend you restrict the
							number of posts to Maximum 2 per day unless extremely Important content
						</p>
					</Form>
				</Modal>
				<Modal
					width={580}
					closable={false}
					open={this.state.modalType == 'event' ? true : false}
					onOk={() => this.setState({ modalType: 'post' })}
					onCancel={() => this.setState({ modalType: 'post', postType: 'allUser' })}
					footer={null}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<Input
							placeholder="Search event"
							size="large"
							prefix={<SearchOutlined />}
							value={this.state.searchEventText}
							onChange={(e) => {
								this.optimizedSearch(e);
								this.setState({ searchEventText: e.target.value });
								this.setState({ loadingSearch: true });
							}}
							className="postSearch"
							style={{ width: '100%', flex: 1 }}
						/>
						{this.state.loadingSearch ? (
							<Spin style={{ marginLeft: '10px' }} />
						) : (
							<h6
								onClick={() =>
									this.setState({
										searchedEvents: [],
										searchEventText: '',
										modalType: 'post'
									})
								}
								style={{
									cursor: 'pointer',
									marginLeft: '10px',
									fontSize: '16px',
									color: '#6F4898',
									fontWeight: 400
								}}>
								Cancel
							</h6>
						)}
					</div>
					{/* <Row gutter={[16, 16]} align="middle">
                    <Col span={22}></Col>
                    <Col span={2}></Col>
                </Row> */}

					<Row gutter={[8, 8]}>
						<Col span={24}>
							<h5
								style={{
									fontSize: '16px',
									color: '#3F3F46',
									fontWeight: '600'
								}}>
								Select Event
							</h5>
						</Col>
					</Row>

					{this.state.searchEventText && this.state.searchedEvents.length > 0 ? (
						this.state.searchedEvents.map((event) => (
							<EventSearchCard
								key={event.id}
								event={event}
								isSelected={this.state.selectedEvent.id == event.id}
								onClick={() =>
									this.setState({
										selectedEvent: event,
										modalType: 'post',
										searchedEvents: [],
										searchEventText: ''
									})
								}
							/>
						))
					) : this.state.searchEventText ? (
						<Row
							gutter={[8, 8]}
							className="eventListHover"
							style={{
								cursor: 'pointer'
							}}>
							<Col xs={{ span: 18 }} md={{ span: 20 }}>
								No Event found for {this.state.searchEventText}
							</Col>
						</Row>
					) : events.length > 0 ? (
						events.map((event) => (
							<EventSearchCard
								key={event.id}
								event={event}
								isSelected={this.state.selectedEvent.id == event.id}
								onClick={() =>
									this.setState({
										selectedEvent: event,
										modalType: 'post',
										searchedEvents: [],
										searchEventText: ''
									})
								}
							/>
						))
					) : (
						<Row
							gutter={[8, 8]}
							className="eventListHover"
							style={{
								cursor: 'pointer'
							}}>
							<Col span={24}>No Event found.</Col>
						</Row>
					)}
				</Modal>
			</>
		);
	};
}

PostAd.propTypes = {
	onClose: PropTypes.func.isRequired,
	createPost: PropTypes.func.isRequired,
	editingPost: PropTypes.shape({
		id: PropTypes.string.isRequired,
		notificationText: PropTypes.string.isRequired
	}),
	editingIndex: PropTypes.number,
	events: PropTypes.array,
	userId: PropTypes.string
};

const PostAdForm = Form.create()(PostAd);

const mapStateToProps = (state) => ({
	events: state.pouchDB.myEvents,
	userId: state.auth.userId,
	isWhitelabelAdmin: state.auth.isWhitelabelAdmin
});

const mapDispatchToProps = (dispatch) => ({
	createPost: (text, notificationText, link, brand, postType, selectedEvent) => {
		return dispatch(
			createPostMAction(text, notificationText, link, brand, postType, selectedEvent)
		);
	},
	editPost: (
		postIndex,
		postDocId,
		text,
		notificationText,
		link,
		brand,
		postType,
		selectedEvent
	) => {
		return dispatch(
			editPostMAction(
				postIndex,
				postDocId,
				text,
				notificationText,
				link,
				brand,
				postType,
				selectedEvent
			)
		);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(PostAdForm);
