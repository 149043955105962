import {
	ACTION_UPDATE_ABOUT_COMPANY,
	ACTION_UPDATE_COMPANY_NAME,
	ACTION_UPDATE_CURRENT_PAGE,
	ACTION_UPDATE_PERSONAL_DETAILS,
	ACTION_UPDATE_BRAND_COLOR,
	ACTION_UPDATE_SOCIAL_LINKS,
	ACTION_UPDATE_WHATSAPP_DETAILS,
	ACTION_UPDATE_EVENT_ID,
	UPDATE_PROFILE_IF_CREATED,
	ACTION_PROFILE_NOT_CREATED,
	GET_PROFILE_DATA_STARTED,
	GET_PROFILE_DATA_FAILED,
	ACTION_PROFILE_CREATION_SUCCESS,
	ACTION_PROFILE_CREATION_FAILED,
	ACTION_PROFILE_CREATION_STARTED,
	UPDATE_PROFILE_PHOTO_LINK,
	UPDATE_COVER_PHOTO_LINK,
	ACTION_PHOTO_UPLOAD_STARTED,
	ACTION_PHOTO_UPLOAD_FAILED
} from './action_types';
import Profile from '../pouchDB/models/Profile';

export const updateCurrentPage = (pageNo) => ({
	type: ACTION_UPDATE_CURRENT_PAGE,
	payload: pageNo
});
export const updateCompanyName = (name) => ({
	type: ACTION_UPDATE_COMPANY_NAME,
	payload: name
});

export const updateAboutCompany = (about) => ({
	type: ACTION_UPDATE_ABOUT_COMPANY,
	payload: about
});

export const updatePersonalDetails = (details) => ({
	type: ACTION_UPDATE_PERSONAL_DETAILS,
	payload: details
});

export const updateBrandColor = (color) => ({
	type: ACTION_UPDATE_BRAND_COLOR,
	payload: color
});

export const updateSocialLinks = (links) => ({
	type: ACTION_UPDATE_SOCIAL_LINKS,
	payload: links
});

export const updateWhatsappDetails = (details) => ({
	type: ACTION_UPDATE_WHATSAPP_DETAILS,
	payload: details
});

export const updateEventId = (eventId) => ({
	type: ACTION_UPDATE_EVENT_ID,
	payload: eventId
});

export const getProfileDataStarted = () => ({
	type: GET_PROFILE_DATA_STARTED
});

export const profileNotCreated = () => ({
	type: ACTION_PROFILE_NOT_CREATED
});

export const updateProfileIfCreated = (profileData) => ({
	type: UPDATE_PROFILE_IF_CREATED,
	payload: profileData
});

export const getProfileDataFailed = () => ({
	type: GET_PROFILE_DATA_FAILED
});

export const getProfileData = () => {
	return (dispatch, getState) => {
		dispatch(getProfileDataStarted());
		Profile.fetchProfile(getState().auth.userId)
			.then((profile) => {
				if (Object.keys(profile).length > 0 && profile.aboutCompany && profile.aboutCompany != '') {
					dispatch(updateProfileIfCreated(profile));
				} else {
					dispatch(profileNotCreated());
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(getProfileDataFailed());
			});
	};
};

export const createProfileStarted = () => ({
	type: ACTION_PROFILE_CREATION_STARTED
});

export const createProfileFailed = () => ({
	type: ACTION_PROFILE_CREATION_FAILED
});

export const createProfileSuccess = () => ({
	type: ACTION_PROFILE_CREATION_SUCCESS
});

export const createProfile = (
	companyName,
	aboutCompany,
	personalDetails,
	brandColor,
	profileImageLink,
	coverImageLink,
	socialMediaLinks,
	whatsappDetails,
	eventId,
	openNotificationWithIcon
) => {
	return (dispatch, getState) => {
		dispatch(createProfileStarted());
		Profile.createProfile(
			companyName,
			aboutCompany,
			personalDetails,
			brandColor,
			profileImageLink,
			coverImageLink,
			socialMediaLinks,
			whatsappDetails,
			eventId,
			getState().auth.userId
		)
			.then((profile) => {
				dispatch(createProfileSuccess());
				dispatch(getProfileData());
				openNotificationWithIcon('success', 'Profile created successfully');
			})
			.catch((error) => {
				console.log(error);
				dispatch(createProfileFailed());
				openNotificationWithIcon('error', 'Something went wrong');
			});
	};
};

export const updateProfile = (
	companyName,
	aboutCompany,
	personalDetails,
	brandColor,
	profileImageLink,
	coverImageLink,
	socialMediaLinks,
	whatsappDetails,
	eventId,
	docId,
	openNotificationWithIcon
) => {
	return (dispatch, getState) => {
		//dispatch(createProfileStarted());
		Profile.updateProfile(
			companyName,
			aboutCompany,
			personalDetails,
			brandColor,
			profileImageLink,
			coverImageLink,
			socialMediaLinks,
			whatsappDetails,
			eventId,
			getState().auth.userId,
			docId
		)
			.then((profile) => {
				//dispatch(createProfileSuccess());
				//dispatch(getProfileData());
				openNotificationWithIcon('success', 'Profile updated successfully');
				console.log('profile......', profile);
			})
			.catch((error) => {
				console.log(error);
				openNotificationWithIcon('error', 'Error', 'Something went wrong');
				//dispatch(createProfileFailed());
			});
	};
};

export const updateProfilePhotoLink = (link) => ({
	type: UPDATE_PROFILE_PHOTO_LINK,
	payload: link
});

export const updateCoverPhotoLink = (link) => ({
	type: UPDATE_COVER_PHOTO_LINK,
	payload: link
});

export const photoUploadStarted = () => ({
	type: ACTION_PHOTO_UPLOAD_STARTED
});
export const photoUploadFailed = () => ({
	type: ACTION_PHOTO_UPLOAD_FAILED
});

export const uploadPhotos = (image, imageFor, openNotificationWithIcon) => {
	return (dispatch, getState) => {
		dispatch(photoUploadStarted());
		Profile.uploadProfileAndCoverPhoto(image, getState().auth.userId)
			.then((key) => {
				// const link = `https://devimages.kamero.in/1/none/high/thumb/?key=${key?.obj?.filePath}`;
				const link = key?.obj?.filePath;
				if (imageFor == 'profile') {
					dispatch(updateProfilePhotoLink(link));
					openNotificationWithIcon('success', 'Profile photo uploaded successfully');
				} else if (imageFor == 'cover') {
					dispatch(updateCoverPhotoLink(link));
					openNotificationWithIcon('success', 'Cover photo uploaded successfully');
				}
			})
			.catch((error) => {
				console.log(error);
				console.log('API_ERROPR');
				dispatch(photoUploadFailed());
				openNotificationWithIcon('error', 'Something went wrong while uploading photo');
			});
	};
};
