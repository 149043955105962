import { connect } from 'react-redux';
import AlbumListComp from '../components/AlbumListComp';
import {
	albumClicked,
	editAlbumName,
	deleteAlbum,
	albumSortOrderChanged
} from '../actions/pouchDBActions';

const mapStateToProps = (state) => ({
	albums: state.pouchDB.visibleAlbums,
	visibleEvent: state.pouchDB.visibleEvent,
	clickedAlbum: state.pouchDB.clickedAlbum,
	deletingAlbums: state.pouchDB.deletingAlbums,
	guestImageCount: state.pouchDB.photoCounts['guestUploads']
		? state.pouchDB.photoCounts['guestUploads'].count
		: 0,
	guestUploadsLoading: state.pouchDB.guestUploadsLoading
});

const mapDispatchToProps = (dispatch) => ({
	onGuestImageClick: (event, album) => dispatch(albumClicked(event, album)),
	onAlbumClick: (event, album) => dispatch(albumClicked(event, album)),
	albumNameEdited: (index, event, newAlbumName, album) => {
		dispatch(editAlbumName(index, event, album.id, newAlbumName));
	},
	albumSortOrderChanged: async (oldIndex, newIndex) => {
		return dispatch(await albumSortOrderChanged(oldIndex, newIndex));
	},
	deleteAlbum: (index, event, album) => {
		dispatch(deleteAlbum(index, event, album.id));
	}
});

const Albums = connect(mapStateToProps, mapDispatchToProps)(AlbumListComp);

export default Albums;
