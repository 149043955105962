

import { swaggerClient, errorHandler } from '../../swagger'

export const PRODUCT_TYPE = Object.freeze({ "photos_pack": 0, "facial_support": 1, "wallet": 2 })
class Product {
    static fetchProducts = async (userId, type) => {
        try {
            let result = await swaggerClient().apis.store.product_list({
                userId: userId,
                type: type
            })
            return JSON.parse(result.data)
        } catch (err) {
            errorHandler(err)
        }
    }
}

export default Product