import Swagger from 'swagger-client';

let clientInternal = undefined;

export const configureSwaggerClient = () => {
	return Swagger('/swagger.json').then((client) => {
		if (client.errors.length > 0) {
			console.log(client.errors);
		}
		clientInternal = client;
	});
};

export const swaggerClient = () => {
	return clientInternal;
};

export const loginWithFB = async (token) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.register({
			'auth-type': 0,
			token: token
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const loginWithCredentials = async (email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.login({
			email: email,
			password: password,
			otp: otp
		});
		const response = JSON.parse(result.data);
		if (!response || !response.userId) {
			throw new Error('Invalid response from server');
		}
		return response;
	} catch (err) {
		console.error('Login error:', err);
		throw errorHandler(err);
	}
};

export const verifySession = async () => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.verify_session();
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};

export const sendOTP = async (email) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.send_otp({ email: email });
	} catch (err) {
		errorHandler(err);
	}
};

export const SignupResponse = Object.freeze({
	success: 1,
	needsOTP: 2,
	goToSetPassword: 3,
	error: 4,
	invalidOTP: 5
});

export const signup = async (name, email, password, otp) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		await clientInternal.apis.login.signup({
			name: name,
			password: password,
			email: email,
			otp: otp
		});
		return SignupResponse.success;
	} catch (err) {
		console.log(err);
		if (err.status === 409) {
			return SignupResponse.goToSetPassword;
		} else if (err.status === 425) {
			return SignupResponse.needsOTP;
		} else if (err.status === 401) {
			return SignupResponse.invalidOTP;
		} else {
			let response = JSON.parse(err.response.text);
			if (response && response.message) {
				return response.message;
			}
			return 'An unexpected error occurred. Please try again.';
		}
	}
};

export const errorHandler = (err) => {
	if (err.response && err.response.obj) {
		return {
			status: err.response.status,
			message: err.response.obj.message || 'An unexpected error occurred'
		};
	}
	return {
		status: err.status || 500,
		message: err.message || 'An unexpected error occurred'
	};
};

export const updateUserPhoneNumber = async (userId, phoneNumber) => {
	if (!clientInternal) {
		await configureSwaggerClient();
	}

	try {
		let result = await clientInternal.apis.login.updatePhone({
			userId: userId,
			phone: phoneNumber
		});
		return JSON.parse(result.data);
	} catch (err) {
		errorHandler(err);
	}
};
