import 'blueimp-canvas-to-blob';
import loadImage from '../../node_modules/blueimp-load-image/js/'

export const PICA_OPTIONS = {
	unsharpAmount: 80,
	unsharpRadius: 0.6,
	unsharpThreshold: 2
}

let LOAD_IMAGE_RETRY = 3

class Resize{

	constructor(debug, outputQuality) {
		this.outputQuality = (outputQuality === 'undefined' ? 0.8 : outputQuality)
		this.debug = debug
		if (this.debug) {
			console.log('set outputQuality: ' + this.outputQuality)
		}
	}

	resizeAndGetThumbnail = (file, maxSize, contextModifier) => {
		var that = this
		var clickedAt = undefined

		console.time('ImageRead')
		return this.fileToResizedImagePromise(file, maxSize)
			.then((result) => {
				console.timeEnd('ImageRead')
				console.time('ImageResize')
				console.log(result)
				let exifIFD = result.data && result.data.exif && result.data.exif.get('Exif')
				if (exifIFD) {
					let dateTimeOriginal = exifIFD.get('DateTimeOriginal')
					if (!dateTimeOriginal || dateTimeOriginal.length === 0) {
						dateTimeOriginal = result.data.exif.get('DateTime');
					}

					if (dateTimeOriginal && dateTimeOriginal.length > 0) {
						let dateComps = dateTimeOriginal.replace(/:/g,',').replace(/ /g,',').split(',')
						if (dateComps.length >= 6) {
							clickedAt = new Date(dateComps[0],
								dateComps[1],
								dateComps[2],
								dateComps[3],
								dateComps[4],
								dateComps[5]).toISOString()
						}
					}
				}

				return that.resizeImageWithWatermark(result.imageCanvas, maxSize, contextModifier)
			})
			.then((result) => {
				console.timeEnd('ImageResize')
				return Promise.resolve({
					resizedImage: result.blob,
					clickedAt: clickedAt
				})
			})
	}

	fileToResizedImagePromise = (file, maxSize) => {
		let that = this
		return new Promise((resolve, reject) => {
			let options = {
				maxWidth: maxSize,
				maxHeight: maxSize,
				downsamplingRatio: 0.5,
				canvas: true,
				orientation: true,
				meta: true,
				disableExif: false,
				disableExifThumbnail: true,
				disableExifSub: false,
				disableExifGps: true,
				includeExifTags: {
					0x0132: true, // DateTime
					0x8769: {
						// ExifIFDPointer
						0x9003: true // DateTimeOriginal Check https://github.com/blueimp/JavaScript-Load-Image/blob/cb5e5d0736101894d22ab1094f2fede402a89be0/js/load-image-exif-map.js for options
					}
				}
			}

			that.loadImageWithRetry(0, file, options, (result, err) => {
				if (err) {
					reject(err)
					return
				}
				resolve(result)
			})
		})
	}

	loadImageWithRetry = (currentRetry, file, options, callback) => {
		let that = this

		loadImage(
			file,
			function(image, data){
				if (image.type === "error") {
					if (currentRetry < LOAD_IMAGE_RETRY) {
						currentRetry += 1
						setTimeout(() => {
							that.loadImageWithRetry(currentRetry, file, options, callback)
						}, 1000)
						return
					}
					callback(null, {message: 'Image could not be loaded from disk. Please try again'})
					return
				}
				callback({
					imageCanvas: image,
					data: data
				}, null)
			},
			options
		)
	}

	resizeImageWithWatermark = (imageCanvas, maxSize, contextModifier) => {
		const that = this;
		return new Promise((resolve, reject) => {
			let destCanvas = imageCanvas
			console.log(destCanvas)
			let contextModifierPromise
			if (contextModifier) {
				contextModifierPromise = contextModifier(destCanvas)
			}else{
				contextModifierPromise = Promise.resolve(destCanvas)
			}
			contextModifierPromise
				.then((canvasWithWatermark) => {
					console.log(canvasWithWatermark)
					return that.canvasToBlob(canvasWithWatermark)
				})
				.then((file) => {
					console.log(file)
					resolve({
						canvas: destCanvas,
						blob: file
					})
				})
				.catch((err) => {
					console.log(err)
					reject({message: 'Image resize failed. Please try again.'})
				})
		})
	}

	getSquareCropRect = (width, height, paddingPercent) => {
		let cropRect
		if (width > height) {
			let padding = (height * paddingPercent)/100

			let cropSize = height - (padding * 2)
			let cropStart = padding + ((width - cropSize) * 0.5)

			cropRect = {
				x: cropStart,
				y: padding,
				width: cropSize,
				height: cropSize
			}
		} else {
			let padding = (width * paddingPercent)/100
			let cropSize = width - (padding * 2)
			let cropStart = padding + ((height - cropSize) * 0.5)

			cropRect = {
				x: padding,
				y: cropStart,
				width: cropSize,
				height: cropSize
			}
		}
		return cropRect
	}

	canvasToBlob = (canvas) => {
		return new Promise((resolve, reject) => {
			canvas.toBlob(function (blob) {
				resolve(blob);
			}, 'image/jpeg', this.outputQuality);
		})
	}

}

export default Resize
