import { connect } from 'react-redux'
import UploadComp  from '../components/UploadComp'
import { uploadFinished, uploadStartedAction, uploadProgressUpdatedAction, uploadErrorOccuredAction } from '../actions/uploadActions'
import { photosUploadedMAction } from '../actions/pouchDBActions'

const mapStateToProps = (state) => ({
  userId: state.auth.userId,
  event: state.pouchDB.visibleEvent,
  eventPhotoCount : state.pouchDB.visibleEvent && state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id] ? state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id].count : undefined,
  album: state.pouchDB.clickedAlbum,
  uploading: state.upload.uploading,
  pending: state.upload.pending,
  total: state.upload.total,
  error: state.upload.errorDetail,
  successMessage: state.upload.successMessage
})

const mapDispatchToProps = (dispatch) => ({
    uploadStarted : (total) => dispatch(uploadStartedAction(total)),
    uploadFinished : (message) => {
      dispatch(uploadFinished(message))
    },
    photosUploaded: (event, album) => {
      dispatch(photosUploadedMAction(event, album))
    },
    uploadProgressUpdated : (pending) => dispatch(uploadProgressUpdatedAction(pending)),
    uploadError: (error) => {
      dispatch(uploadErrorOccuredAction(error))
      // dispatch(logout())
    }
})

const Uploads = connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadComp)


export default Uploads
