import { connect } from 'react-redux';
import Profile from '../components/Profile';
import {
	updateAboutCompany,
	updateCompanyName,
	updateCurrentPage,
	updatePersonalDetails,
	updateBrandColor,
	updateSocialLinks,
	updateWhatsappDetails,
	updateEventId,
	getProfileData,
	createProfile,
	updateProfile,
	uploadPhotos
} from '../actions/profileActions';
// import { sendOTP } from "../swagger"

const mapStateToProps = (state) => ({
	auth: state.auth,
	profile: state.profile
});

const mapDispatchToProps = (dispatch) => ({
	updateAboutCompany: (about) => {
		dispatch(updateAboutCompany(about));
	},

	updateCompanyName: (name) => {
		dispatch(updateCompanyName(name));
	},

	updateCurrentPage: (page) => {
		dispatch(updateCurrentPage(page));
	},

	updatePersonalDetails: (details) => {
		dispatch(updatePersonalDetails(details));
	},

	updateBrandColor: (color) => {
		dispatch(updateBrandColor(color));
	},

	updateSocialLinks: (links) => {
		dispatch(updateSocialLinks(links));
	},

	updateWhatsappDetails: (details) => {
		dispatch(updateWhatsappDetails(details));
	},

	updateEventId: (eventId) => {
		dispatch(updateEventId(eventId));
	},

	getProfileData: () => {
		dispatch(getProfileData());
	},

	createProfile: (
		companyName,
		aboutCompany,
		personalDetails,
		brandColor,
		profileImageLink,
		coverImageLink,
		socialMediaLinks,
		whatsappDetails,
		eventId,
		openNotificationWithIcon
	) => {
		dispatch(
			createProfile(
				companyName,
				aboutCompany,
				personalDetails,
				brandColor,
				profileImageLink,
				coverImageLink,
				socialMediaLinks,
				whatsappDetails,
				eventId,
				openNotificationWithIcon
			)
		);
	},

	updateProfile: (
		companyName,
		aboutCompany,
		personalDetails,
		brandColor,
		profileImageLink,
		coverImageLink,
		socialMediaLinks,
		whatsappDetails,
		eventId,
		docId,
		openNotificationWithIcon
	) => {
		dispatch(
			updateProfile(
				companyName,
				aboutCompany,
				personalDetails,
				brandColor,
				profileImageLink,
				coverImageLink,
				socialMediaLinks,
				whatsappDetails,
				eventId,
				docId,
				openNotificationWithIcon
			)
		);
	},

	uploadPhotos: (image, imageFor, openNotificationWithIcon) => {
		dispatch(uploadPhotos(image, imageFor, openNotificationWithIcon));
	}
});

const ProfileContainer = connect(mapStateToProps, mapDispatchToProps)(Profile);

export default ProfileContainer;
