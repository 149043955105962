export function updateAtIndexArray(array, updateIndex, newItem) {
    return array.map( (item, index) => {
        if(index !== updateIndex) {
            return item;
        }

        return {
            ...item,
            ...newItem
        };
    });
}

export function removeAtIndexArray(array, removeIndex) {
    return array.filter( (item, index) => index !== removeIndex);
}

export function addAtIndexArray(array, addInex, album){
    let newArray = array.slice();
    newArray.splice(addInex, 0, album);
    return newArray;
}


export function removeItemArray(array, item) {
    let removeIndex = array.indexOf(item);
    if (item === -1){
        return array
    }

    return removeAtIndexArray(array, removeIndex)
}

export function insertUniqueItemArray(array, item) {
    let removeIndex = array.indexOf(item);
    if (removeIndex !== -1){
        return array
    }

    return [
        ...array,
        item
    ]
}