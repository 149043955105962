import {
	ACTION_UPDATE_ABOUT_COMPANY,
	ACTION_UPDATE_COMPANY_NAME,
	ACTION_UPDATE_CURRENT_PAGE,
	ACTION_UPDATE_PERSONAL_DETAILS,
	ACTION_UPDATE_BRAND_COLOR,
	ACTION_UPDATE_SOCIAL_LINKS,
	ACTION_UPDATE_WHATSAPP_DETAILS,
	ACTION_UPDATE_EVENT_ID,
	ACTION_PROFILE_NOT_CREATED,
	UPDATE_PROFILE_IF_CREATED,
	GET_PROFILE_DATA_STARTED,
	ACTION_PROFILE_CREATION_SUCCESS,
	ACTION_PROFILE_CREATION_FAILED,
	ACTION_PROFILE_CREATION_STARTED,
	ACTION_PHOTO_UPLOAD_STARTED,
	ACTION_PHOTO_UPLOAD_FAILED,
	UPDATE_PROFILE_PHOTO_LINK,
	UPDATE_COVER_PHOTO_LINK
} from '../actions/action_types';

const profile = (
	state = {
		currentPage: 1,
		companyName: '',
		aboutCompany: '',
		personalDetails: {
			phoneNo: '',
			websiteLink: '',
			emailId: ''
		},
		brandColor: '',
		profilePhoto: '',
		coverPhoto: '',
		socialMediaLinks: {
			facebook: '',
			instagram: '',
			twitter: '',
			whatsapp: '',
			pinterest: '',
			youtube: '',
			vimeo: ''
		},
		whatsappDetails: {
			whatsappNo: '',
			countryCode: '+91-IN'
		},
		eventId: '',
		loading: false,
		profileCreated: false,
		docId: '',
		errorMessage: '',
		successMessage: ''
	},
	action
) => {
	console.log(action);
	switch (action.type) {
		case ACTION_UPDATE_CURRENT_PAGE:
			return {
				...state,
				currentPage: action.payload
			};
		case ACTION_UPDATE_COMPANY_NAME:
			return {
				...state,
				companyName: action.payload
			};
		case ACTION_UPDATE_ABOUT_COMPANY:
			return {
				...state,
				aboutCompany: action.payload
			};

		case ACTION_UPDATE_PERSONAL_DETAILS:
			return {
				...state,
				personalDetails: action.payload
			};
		case ACTION_UPDATE_BRAND_COLOR:
			return {
				...state,
				brandColor: action.payload
			};
		case ACTION_UPDATE_SOCIAL_LINKS:
			return {
				...state,
				socialMediaLinks: action.payload
			};
		case ACTION_UPDATE_WHATSAPP_DETAILS:
			return {
				...state,
				whatsappDetails: action.payload
			};
		case ACTION_UPDATE_EVENT_ID:
			return {
				...state,
				eventId: action.payload
			};
		case GET_PROFILE_DATA_STARTED:
			return {
				...state,
				loading: true
			};
		case ACTION_PROFILE_NOT_CREATED:
			return {
				...state,
				loading: false,
				profileCreated: false,
				docId: ''
			};

		case UPDATE_PROFILE_IF_CREATED:
			return {
				...state,
				currentPage: 1,
				companyName: action.payload.companyName,
				aboutCompany: action.payload.aboutCompany,
				personalDetails: action.payload.personalDetails,
				brandColor: action.payload.brandColor,
				profilePhoto: action.payload.profileImageLink,
				coverPhoto: action.payload.coverImageLink,
				socialMediaLinks: action.payload.socialMediaLinks,
				whatsappDetails: action.payload.whatsappDetails,
				eventId: action.payload.portfolioEventId,
				loading: false,
				profileCreated: true,
				docId: action.payload.docId
			};

		case ACTION_PROFILE_CREATION_STARTED:
			return {
				...state,
				loading: true
			};
		case ACTION_PROFILE_CREATION_FAILED:
			return {
				...state,
				loading: false,
				errorMessage: 'Something went wrong while creating profile'
			};
		case ACTION_PROFILE_CREATION_SUCCESS:
			return {
				...state,
				loading: false,
				successMessage: 'Profile created successfully'
			};
		case ACTION_PHOTO_UPLOAD_STARTED:
			return {
				...state
			};
		case ACTION_PHOTO_UPLOAD_FAILED:
			return {
				...state
			};
		case UPDATE_PROFILE_PHOTO_LINK:
			return {
				...state,
				profilePhoto: action.payload
			};
		case UPDATE_COVER_PHOTO_LINK:
			return {
				...state,
				coverPhoto: action.payload
			};
		// case ACTION_VISIBLE_PHOTOS_PAGE_UPDATED:
		//     return {
		//         ...state,
		//         currentPage: action.currentPage,
		//         visiblePhotos: action.photos
		//     }
		// case ACTION_PHOTO_COUNT_UPDATED:
		//     return {
		//         ...state,
		//         photoCounts: action.photoCounts
		//     }
		// case ACTION_ALBUM_DELETE_STARTED:{
		//     return {
		//         ...state,
		//         deletingAlbums: insertUniqueItemArray(state.deletingAlbums, action.albumId)
		//     }
		// }
		// case ACTION_ALBUM_DELETE_FINISHED:{
		//     return {
		//         ...state,
		//         deletingAlbums: removeItemArray(state.deletingAlbums, action.albumId)
		//     }
		// }
		// case ACTION_API_ERROR_OCCURED:{
		//     return {
		//         ...state,
		//         apiError: action.error
		//     }
		// }
		default:
			return state;
	}
};

export default profile;
