import {
    ACTION_VISIBLE_POSTS_UPDATED,
    ACTION_POST_CREATED,
    ACTION_POST_UPDATED,
    ACTION_POST_DELETED
} from '../actions/action_types'
import {
    addAtIndexArray,
    removeAtIndexArray,
    updateAtIndexArray
} from './arrayFunctions'

const post = (state = {
    visiblePosts : undefined
}, action) => {
    switch (action.type) {
        case ACTION_VISIBLE_POSTS_UPDATED:
            return {
                ...state,
                visiblePosts: action.posts
            }
        case ACTION_POST_CREATED:
            var posts = state.visiblePosts
            if (posts === undefined) {
                posts = []
            }
            return {
                ...state,
                visiblePosts : addAtIndexArray(posts, 0, action.post)
            }
        case ACTION_POST_UPDATED:
            return {
                ...state,
                visiblePosts : updateAtIndexArray(state.visiblePosts, action.postIndex, action.post)
            }
        case ACTION_POST_DELETED:
            return {
                ...state,
                visiblePosts : removeAtIndexArray(state.visiblePosts, action.postIndex)
            }
        default:
            return state;
    }
}

export default post
