import { generate } from '@ant-design/colors';
import { TinyColor } from '@ctrl/tinycolor';

const primaryColor = '#6F4898';
const secondaryColor = '#FFB700';

const generateColors = (baseColor) => {
	const colors = generate(baseColor);
	const primaryColors = colors.slice(0, 3);
	const secondaryColors = generate(secondaryColor).slice(0, 3);

	return [...primaryColors, ...secondaryColors];
};

export const theme = {
	token: {
		colorPrimary: primaryColor,
		colorPrimaryBg: generateColors(primaryColor)[0],
		colorPrimaryBorder: generateColors(primaryColor)[1],
		colorPrimaryContentHover: generateColors(primaryColor)[2],
		colorBgContainer: '#fff'
	},

	components: {
		Button: {
			controlHeight: 36
		},
		Layout: {
			headerHeight: 46,
			headerBg: primaryColor,
			itemActiveBg: secondaryColor
		},

		Input: {
			controlHeight: 36
		},
		Select: {
			controlHeight: 36
		},
		Menu: {
			darkItemSelectedBg: secondaryColor
		}
	}
};
