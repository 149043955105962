import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, message, Modal, Row, Tooltip } from 'antd';
import { ScanFace } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RazorPayWrapper from '../containers/RazorPayWrapper';
import { calculateFaceDetectionPrice } from '../helpers/calculateFaceDetectionPrice';
import Order from '../pouchDB/models/Order';
import Wallet from '../pouchDB/models/Wallet';

const FACE_VALIDITY_MONTHS = 12;
const TILL_EXPIRY_RENEW_DISCOUNT = 0.5; // 50% discount

const FaceDetectionModal = ({ userId, email, mobile, event, fetchBalance, onComplete }) => {
	const [showModal, setShowModal] = useState(false);
	const [paymentInProgress, setPaymentInProgress] = useState(false);
	const [checkoutInProgress, setCheckoutInProgress] = useState(false);
	const [order, setOrder] = useState(undefined);
	const [shouldUseWallet, setShouldUseWallet] = useState(true);
	const [walletBalance, setWalletBalance] = useState(null);

	useEffect(() => {
		fetchWalletBalance();
	}, []);

	const fetchWalletBalance = async () => {
		try {
			const wallet = await fetchBalance(userId);
			setWalletBalance(wallet.balance / 100);
		} catch (err) {
			console.error(err);
			message.error('Failed to fetch wallet balance');
		}
	};

	const openModal = () => setShowModal(true);
	const closeModal = () => setShowModal(false);

	const placeOrder = async () => {
		try {
			setPaymentInProgress(true);
			let order = await Order.placeFaceOrder(
				userId,
				shouldUseWallet,
				event.id,
				FACE_VALIDITY_MONTHS
			);
			if (order.razorPayOrderId) {
				setCheckoutInProgress(true);
				setOrder(order);
			} else {
				await confirmOrder(order.id, undefined, undefined);
			}
		} catch (err) {
			console.error(err);
			setPaymentInProgress(false);
			message.error('Failed to place order');
		}
	};

	const confirmOrder = async (orderId, paymentId, signature) => {
		try {
			await Order.confirmOrder(userId, orderId, paymentId, signature, event.id);
			setCheckoutInProgress(false);
			setOrder(undefined);
			setPaymentInProgress(false);
			setShowModal(false);
			message.success('Face detection enabled successfully');
			if (onComplete && typeof onComplete === 'function') {
				onComplete(); // Call the onComplete prop to refresh the event
			}
		} catch (err) {
			console.error(err);
			setCheckoutInProgress(false);
			setOrder(undefined);
			setPaymentInProgress(false);
			message.error('Failed to confirm order');
		}
	};

	const checkout = (order) => {
		const props = {
			email,
			mobile,
			amount: order.amount,
			orderId: order.razorPayOrderId,
			paymentAuthorized: (response) => {
				confirmOrder(order.id, response.razorpay_payment_id, response.razorpay_signature);
			}
		};
		return <RazorPayWrapper {...props} />;
	};

	if (walletBalance === null) {
		return <div>Loading...</div>;
	}

	const maxPhotos = event.maxPhotos;
	const baseCost = calculateFaceDetectionPrice(maxPhotos);
	const isRenewal = event.facesEnabledAt !== undefined && event.facesEnabledAt !== null;
	const currentDate = new Date();
	const expiryDate = event.facesExpiresAt ? new Date(event.facesExpiresAt) : null;
	const thirtyDaysFromNow = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

	const calculateNewExpiryDate = () => {
		if (isRenewal) {
			if (expiryDate && expiryDate > currentDate) {
				// If renewing before expiry, add FACE_VALIDITY_MONTHS to current expiry date
				const newDate = new Date(expiryDate);
				newDate.setMonth(newDate.getMonth() + FACE_VALIDITY_MONTHS);
				return newDate;
			} else {
				// If renewing after expiry or if expiryDate is null, add FACE_VALIDITY_MONTHS to current date
				const newDate = new Date(currentDate);
				newDate.setMonth(newDate.getMonth() + FACE_VALIDITY_MONTHS);
				return newDate;
			}
		} else {
			// For new enablement, add FACE_VALIDITY_MONTHS to current date
			const newDate = new Date(currentDate);
			newDate.setMonth(newDate.getMonth() + FACE_VALIDITY_MONTHS);
			return newDate;
		}
	};

	const newExpiryDate = calculateNewExpiryDate();

	let totalCost = baseCost;
	if (isRenewal && expiryDate && expiryDate > currentDate) {
		totalCost = baseCost * TILL_EXPIRY_RENEW_DISCOUNT;
	}

	let payableAmount = totalCost;
	let creditsUsed = 0;
	if (shouldUseWallet && walletBalance > 0) {
		if (totalCost - walletBalance > 0) {
			payableAmount = totalCost - walletBalance;
			creditsUsed = walletBalance;
		} else {
			payableAmount = 0;
			creditsUsed = totalCost;
		}
	}

	const gstAmount = payableAmount > 0 ? payableAmount * 0.18 : 0;
	const grandTotal = payableAmount + gstAmount;

	let buttonText = 'Enable Face Detection';
	let buttonType = 'primary';
	let buttonIcon = <ScanFace size={12} />;
	let buttonStyle = { backgroundColor: '#FFB700', borderColor: '#FFB700', color: 'black' };

	if (isRenewal) {
		if (expiryDate && expiryDate <= currentDate) {
			buttonText = 'Renew Face Detection';
			buttonType = 'danger';
			buttonIcon = <WarningOutlined />;
			buttonStyle = { backgroundColor: 'red', borderColor: 'red', color: 'white' };
		} else if (expiryDate && expiryDate < thirtyDaysFromNow) {
			buttonText = 'Renew Face Detection';
			buttonType = 'warning';
			buttonStyle = { backgroundColor: '#FFB700', borderColor: '#FFB700', color: 'white' };
		} else if (expiryDate && expiryDate >= currentDate) {
			buttonText = 'Face Detection Active';
			buttonType = 'default';
			buttonIcon = <CheckCircleOutlined style={{ color: 'green' }} />;
			buttonStyle = {};
		}
	}

	const modalTitle = isRenewal
		? `Extend Face Detection Validity: ${event.name}`
		: 'Enable Face Detection';

	return (
		<>
			<Tooltip title={buttonText}>
				<Button
					type={buttonType}
					onClick={openModal}
					icon={buttonIcon}
					size="small"
					style={{ marginRight: '10px', ...buttonStyle }}>
					{buttonText}
				</Button>
			</Tooltip>

			<Modal
				title={modalTitle}
				open={showModal}
				onCancel={closeModal}
				footer={[
					<Button key="back" onClick={closeModal}>
						Cancel
					</Button>,
					<Button key="submit" type="primary" loading={paymentInProgress} onClick={placeOrder}>
						{payableAmount === 0 ? (isRenewal ? 'Extend' : 'Enable') : 'Proceed'}
					</Button>
				]}>
				<Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
					<Col span={24}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								backgroundColor: '#f0f2f5',
								padding: '16px',
								borderRadius: '8px'
							}}>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<Checkbox
									style={{ marginRight: '8px' }}
									checked={shouldUseWallet}
									onChange={(e) => setShouldUseWallet(e.target.checked)}
								/>
								<span style={{ fontSize: '16px', fontWeight: 500 }}>Use Credits</span>
							</div>
							<span style={{ fontSize: '16px', fontWeight: 500 }}>
								{walletBalance.toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
									style: 'currency',
									currency: 'INR'
								})}
							</span>
						</div>
					</Col>

					<Col span={24}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
								backgroundColor: '#f0f2f5',
								padding: '16px',
								borderRadius: '8px'
							}}>
							<Row gutter={[16, 16]}>
								<Col span={12}>Event Photos:</Col>
								<Col span={12}>{event.maxPhotos}</Col>

								{isRenewal && expiryDate && (
									<>
										<Col span={12}>Current Expiry Date:</Col>
										<Col span={12}>{expiryDate.toLocaleDateString()}</Col>
									</>
								)}

								<Col span={12}>Validity:</Col>
								<Col span={12}>{FACE_VALIDITY_MONTHS} Months</Col>

								<Col span={12}>{isRenewal ? 'New Expiry Date:' : 'Expiry Date:'}</Col>
								<Col span={12}>{newExpiryDate.toLocaleDateString()}</Col>

								<Col span={12}>{isRenewal ? 'Renewal Price:' : 'Price:'}</Col>
								<Col span={12}>
									{totalCost.toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
									{isRenewal && expiryDate && expiryDate > currentDate && (
										<span style={{ marginLeft: '5px', color: 'green' }}>(50% off)</span>
									)}
								</Col>

								{walletBalance > 0 && shouldUseWallet && (
									<>
										<Col span={12}>Credits Used:</Col>
										<Col span={12}>
											{creditsUsed.toLocaleString('en-IN', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
												style: 'currency',
												currency: 'INR'
											})}
										</Col>
									</>
								)}

								{gstAmount > 0 && (
									<>
										<Col span={12}>GST (18%):</Col>
										<Col span={12}>
											{gstAmount.toLocaleString('en-IN', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
												style: 'currency',
												currency: 'INR'
											})}
										</Col>
									</>
								)}

								<Col span={12}>
									<span style={{ fontSize: '16px', fontWeight: 500 }}>Total:</span>
								</Col>
								<Col span={12}>
									<span style={{ fontSize: '16px', fontWeight: 500 }}>
										{grandTotal.toLocaleString('en-IN', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
											style: 'currency',
											currency: 'INR'
										})}
									</span>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</Modal>
			{checkoutInProgress && order && checkout(order)}
		</>
	);
};

FaceDetectionModal.propTypes = {
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	event: PropTypes.object.isRequired,
	fetchBalance: PropTypes.func.isRequired,
	onComplete: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	email: state.auth.email,
	mobile: state.auth.mobile
});

const mapDispatchToProps = (dispatch) => ({
	fetchBalance: async (userId) => {
		return await Wallet.fetchBalance(userId);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(FaceDetectionModal);
