import { connect } from 'react-redux';
import MobileImage from '../svg/mobile.png';
import coverImg from '../svg/coverImg.png';
import profileImg from '../svg/IconKamero.svg';
import { ReactComponent as Call } from '../svg/ic_call.svg';
import { ReactComponent as Website } from '../svg/ic_website.svg';
import '../styles/profile.sass';
import { Row, Col } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import { IMAGES_URL } from '../config';

// const ImageBaseUrl = 'https://devimages.kamero.in/1/none/high/thumb/?key=';
// const ImageBaseUrl = 'https://images.kamero.in/1/none/high/thumb/?key=';

const ImageBaseUrl = IMAGES_URL + '/1/none/high/thumb/?key=';

function ProfileMobile(props) {
	const { profile, selectedIcon } = props;

	return (
		<>
			<div
				className="mobile"
				style={{
					backgroundImage: `url(${MobileImage})`
				}}>
				<div
					className="mobileNav"
					style={{
						color:
							profile.brandColor == '#ffffff'
								? '#000000'
								: profile.brandColor == ''
								? '#000000'
								: '#ffffff',
						backgroundColor: profile.brandColor
					}}>
					Event Name
				</div>
				<div
					className="mobileCover"
					style={{
						backgroundImage:
							profile.coverPhoto == ''
								? `url(${coverImg})`
								: `url(${ImageBaseUrl}${profile.coverPhoto})`
					}}>
					PORTFOLIO
				</div>
				<div style={{ paddingLeft: '10px' }}>
					<Row gutter={[5, 5]}>
						<Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
							<div className="mobileProfileImg">
								<img
									src={
										profile.profilePhoto == ''
											? profileImg
											: `${ImageBaseUrl}${profile.profilePhoto}`
									}
									style={{ objectFit: 'fill', borderRadius: '50%', height: '26px', width: '26px' }}
								/>
							</div>
						</Col>
						<Col span={16} className="mobileCompanyName">
							<div style={{ width: '100%' }}>{profile.companyName}</div>
							{props.validatePersonalDetails() && (
								<div className="personalDetailsIcons">
									<Call fill={profile.brandColor} />
									<MailOutlined style={{ color: profile.brandColor }} />
									<Website fill={profile.brandColor} />
								</div>
							)}
						</Col>
					</Row>
				</div>
				<div style={{ backgroundColor: '#F3F4F6' }}>
					<div className="mobileAboutCompany">{profile.aboutCompany}</div>
					<div className="mobileBorder"> </div>
					<div className="iconContainerMob">
						{props.selectedIcon.map((element, index) => (
							<img
								key={index}
								src={element.icon}
								className="iconMob"
								style={{
									width: element.name == 'whatsapp' ? '21px' : '18px',
									height: element.name == 'whatsapp' ? '21px' : '18px'
								}}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	profile: state.profile
});

const ProfileMobileUi = connect(mapStateToProps)(ProfileMobile);

export default ProfileMobileUi;
