function calcCheckSum(gstin) {
	var GSTN_CODEPOINT_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	var factor = 2;
	var sum = 0;
	var checkCodePoint = 0;
	var mod = GSTN_CODEPOINT_CHARS.length;
	var i;

	for (i = gstin.length - 2; i >= 0; i--) {
		var codePoint = -1;
		for (var j = 0; j < GSTN_CODEPOINT_CHARS.length; j++) {
			if (GSTN_CODEPOINT_CHARS[j] === gstin[i]) {
				codePoint = j;
			}
		}
		var digit = factor * codePoint;
		factor = factor === 2 ? 1 : 2;
		digit = Math.floor(digit / mod) + (digit % mod);
		sum += digit;
	}
	checkCodePoint = (mod - (sum % mod)) % mod;
	return GSTN_CODEPOINT_CHARS[checkCodePoint];
}

function validateGSTNPattern(gstin) {
	const gstinRegexPattern =
		/^([0-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTK][A-Z]\d{4}[A-Z][A-Z0-9][Z][A-Z0-9]$/;
	return gstinRegexPattern.test(gstin);
}

export function isValidGSTNumber(gstin) {
	gstin = gstin.toUpperCase();
	if (gstin.length !== 15) {
		return false;
	}
	if (validateGSTNPattern(gstin)) {
		return gstin[14] === calcCheckSum(gstin.toUpperCase());
	}
	return false;
}

export function getStateFromGSTN(gstin) {
	const states = [
		{ state_name: 'Andaman and Nicobar Islands', state_code: '35' },
		{ state_name: 'Andhra Pradesh', state_code: '28' },
		{ state_name: 'Andhra Pradesh (New)', state_code: '37' },
		{ state_name: 'Arunachal Pradesh', state_code: '12' },
		{ state_name: 'Assam', state_code: '18' },
		{ state_name: 'Bihar', state_code: '10' },
		{ state_name: 'Chandigarh', state_code: '04' },
		{ state_name: 'Chattisgarh', state_code: '22' },
		{ state_name: 'Dadra and Nagar Haveli', state_code: '26' },
		{ state_name: 'Daman and Diu', state_code: '25' },
		{ state_name: 'Delhi', state_code: '07' },
		{ state_name: 'Goa', state_code: '30' },
		{ state_name: 'Gujarat', state_code: '24' },
		{ state_name: 'Haryana', state_code: '06' },
		{ state_name: 'Himachal Pradesh', state_code: '02' },
		{ state_name: 'Jammu and Kashmir', state_code: '01' },
		{ state_name: 'Jharkhand', state_code: '20' },
		{ state_name: 'Karnataka', state_code: '29' },
		{ state_name: 'Kerala', state_code: '32' },
		{ state_name: 'Ladakh', state_code: '38' },
		{ state_name: 'Lakshadweep Islands', state_code: '31' },
		{ state_name: 'Madhya Pradesh', state_code: '23' },
		{ state_name: 'Maharashtra', state_code: '27' },
		{ state_name: 'Manipur', state_code: '14' },
		{ state_name: 'Meghalaya', state_code: '17' },
		{ state_name: 'Mizoram', state_code: '15' },
		{ state_name: 'Nagaland', state_code: '13' },
		{ state_name: 'Odisha', state_code: '21' },
		{ state_name: 'Pondicherry', state_code: '34' },
		{ state_name: 'Punjab', state_code: '03' },
		{ state_name: 'Rajasthan', state_code: '08' },
		{ state_name: 'Sikkim', state_code: '11' },
		{ state_name: 'Tamil Nadu', state_code: '33' },
		{ state_name: 'Telangana', state_code: '36' },
		{ state_name: 'Tripura', state_code: '16' },
		{ state_name: 'Uttar Pradesh', state_code: '09' },
		{ state_name: 'Uttarakhand', state_code: '05' },
		{ state_name: 'West Bengal', state_code: '19' }
	];

	gstin = gstin.toUpperCase();

	if (!isValidGSTNumber(gstin)) return '';

	const state = states.find((o) => o.state_code === gstin.slice(0, 2));
	return state?.state_name || '';
}
