export const calculateFaceDetectionPrice = (maxPhotos) => {
	if (maxPhotos <= 4500) {
		return 750;
	}
	return 1000;
};

export const calculateFaceDetectionRenewalPrice = (maxPhotos, isExpired) => {
	const basePrice = calculateFaceDetectionPrice(maxPhotos);
	if (isExpired) {
		return basePrice;
	}
	return basePrice * 0.5;
};
