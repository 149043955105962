import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert, Button, Checkbox, message, Spin, Table, Row } from 'antd';
import Product, { PRODUCT_TYPE } from '../pouchDB/models/Product';
import Order from '../pouchDB/models/Order';
import RazorPayWrapper from '../containers/RazorPayWrapper';
import FullScreenIndicator from './FullScreenIndicator';

var classNames = require('classnames');

class BuySingleProduct extends Component {
	state = {
		products: undefined,
		loading: true,
		paymentInProgress: false,
		checkoutInProgress: false,
		error: undefined,
		orderItem: undefined,
		order: undefined,
		totalCost: 0,
		shouldUseWallet: true
	};

	componentDidMount() {
		const { eventId, productType } = this.props;
		switch (productType) {
			case PRODUCT_TYPE.photos_pack:
				if (eventId) {
					this.columns.splice(2, 1);
				}
				break;
			case PRODUCT_TYPE.facial_support:
				this.columns.splice(1, 1);
				break;
			default:
				break;
		}
		this.fetchProducts();
	}

	fetchProducts = async () => {
		const { userId, fetchProducts } = this.props;
		try {
			let products = await fetchProducts(userId);
			if (products.length > 0) {
				products = products.map((product, idx) => {
					product.index = idx + 1;
					return product;
				});
				this.setState({ loading: false, products: products });
			} else {
				this.setState({ loading: false, error: 'No Packs to buy!' });
			}
		} catch (err) {
			this.setState({ loading: false, error: err.message });
		}
	};

	columns = [
		{
			title: 'Pack',
			dataIndex: 'index',
			align: 'center'
		},
		{
			title: 'Photos',
			dataIndex: 'photos',
			align: 'center'
		},
		{
			title: 'Validity',
			dataIndex: 'validity',
			align: 'center',
			render: (validity) => <p>{validity + ' Months'}</p>
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			align: 'center',
			render: (cost) => (
				<p>
					{(cost / 100).toLocaleString('en-IN', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
						style: 'currency',
						currency: 'INR'
					})}
				</p>
			),
			responsive: ['md']
		},
		{
			title: 'Select',
			key: 'select',
			width: 60,
			align: 'center',
			render: (_, record, index) => (
				<Checkbox
					onChange={() => {
						let orderItem = {
							quantity: 1,
							productId: record.id
						};
						let products = this.state.products;
						let cost = products[products.findIndex((product) => product.id === record.id)].cost;
						this.setState({ orderItem: orderItem, totalCost: cost });
					}}
					checked={this.state.orderItem ? this.state.orderItem.productId === record.id : false}
				/>
			)
		}
	];

	productComp = (products) => {
		return (
			<Table
				className="facial-support-dialog-table"
				columns={this.columns}
				rowKey={(record) => record.id}
				dataSource={products}
				pagination={false}
				footer={() => this.costComp()}
			/>
		);
	};

	costComp = () => {
		const { totalCost, shouldUseWallet } = this.state;
		const { walletBalance, paymentInProgress, whitelabelId } = this.props;
		const showAmountAfterWalletDeduction = walletBalance && shouldUseWallet && totalCost > 0;
		const onlyWalletBalanceSufficient = totalCost - walletBalance <= 0;
		const creditsUsed = shouldUseWallet
			? onlyWalletBalanceSufficient
				? totalCost
				: walletBalance
			: 0;
		const payableAmount = shouldUseWallet
			? onlyWalletBalanceSufficient
				? 0
				: totalCost - walletBalance
			: totalCost;
		const gstAmount = Math.round(payableAmount * 0.18);

		return (
			<div>
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						padding: '10px 0px 0px',
						marginBottom: '10px'
					}}>
					{walletBalance >= 0 ? (
						<div
							style={{
								flex: 1,
								paddingRight: '7px',
								borderRight: '1px solid #ccc'
							}}>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									marginTop: '10px'
								}}>
								<Checkbox
									style={{ marginTop: 0 }}
									checked={shouldUseWallet}
									onChange={(e) => {
										this.setState({ shouldUseWallet: e.target.checked });
									}}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									paddingTop: '10px'
								}}>
								<span style={{ fontSize: '16px', fontWeight: 400 }}>Use Credits</span>
							</div>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<span style={{ fontSize: '16px', fontWeight: 400 }}>
									{(walletBalance / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</span>
							</div>
						</div>
					) : null}
					<div style={{ flex: 1, paddingLeft: '10px' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: '5px'
							}}>
							<p style={{ margin: 0, marginRight: '4px', fontSize: '14px', fontWeight: 500 }}>
								Subtotal:
							</p>
							<p style={{ margin: 0, marginRight: '16px', fontSize: '14px', fontWeight: 400 }}>
								{(totalCost / 100).toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
									style: 'currency',
									currency: 'INR'
								})}
							</p>
						</div>
						{creditsUsed > 0 && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginBottom: '5px'
								}}>
								<p style={{ margin: 0, marginRight: '4px', fontSize: '14px', fontWeight: 500 }}>
									creditsUsed :
								</p>
								<p style={{ margin: 0, marginRight: '16px', fontSize: '14px', fontWeight: 400 }}>
									-{''}
									{(creditsUsed / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</p>
							</div>
						)}
						{gstAmount > 0 && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									marginBottom: '5px'
								}}>
								<p style={{ margin: 0, marginRight: '4px', fontSize: '14px', fontWeight: 500 }}>
									GST (18%):
								</p>
								<p style={{ margin: 0, marginRight: '16px', fontSize: '14px', fontWeight: 400 }}>
									+ {''}
									{(gstAmount / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</p>
							</div>
						)}
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								marginBottom: '10px'
							}}>
							<h3 style={{ margin: 0, marginRight: '4px', fontSize: '16px', fontWeight: 500 }}>
								Total:
							</h3>

							<Row type="flex" justify="end" align="middle">
								{!showAmountAfterWalletDeduction ? (
									<h2 style={{ marginRight: '16px' }}>
										{((totalCost + gstAmount) / 100).toLocaleString('en-IN', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
											style: 'currency',
											currency: 'INR'
										})}
									</h2>
								) : null}

								{showAmountAfterWalletDeduction ? (
									<p
										style={{
											marginRight: '8px',
											textDecoration: showAmountAfterWalletDeduction ? 'line-through' : 'none'
										}}>
										{((totalCost + gstAmount) / 100).toLocaleString('en-IN', {
											minimumFractionDigits: 0,
											maximumFractionDigits: 1
										})}
									</p>
								) : null}

								{showAmountAfterWalletDeduction ? (
									<h2 style={{ marginRight: '16px' }}>
										{onlyWalletBalanceSufficient
											? (0).toLocaleString('en-IN', {
													minimumFractionDigits: 0,
													maximumFractionDigits: 0,
													style: 'currency',
													currency: 'INR'
											  })
											: ((totalCost - walletBalance + gstAmount) / 100).toLocaleString('en-IN', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
													style: 'currency',
													currency: 'INR'
											  })}
									</h2>
								) : null}
							</Row>
						</div>
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Button
						type="primary"
						disabled={totalCost === 0}
						onClick={(e) => {
							e.preventDefault();
							if (paymentInProgress) {
								return;
							}
							this.placeOrder();
						}}>
						{onlyWalletBalanceSufficient ? 'Add Packs' : 'Buy Now'}
					</Button>
				</div>
			</div>
		);
	};

	placeOrder = async () => {
		const { userId, placeOrder, eventId } = this.props;
		const { orderItem } = this.state;

		try {
			this.setState({ paymentInProgress: true });
			let order = await placeOrder(userId, [orderItem], this.state.shouldUseWallet, eventId);
			if (order.razorPayOrderId) {
				this.setState({ checkoutInProgress: true, order: order });
			} else {
				await this.confirmOrder(order.id, undefined, undefined);
			}
		} catch (err) {
			message.error(err.message);
			this.setState({ paymentInProgress: false, orderItem: undefined, totalCost: 0 });
		}
	};

	checkout(order) {
		const { email, mobile } = this.props;
		const { totalCost } = this.state;

		let props = {
			email: email,
			mobile: mobile,
			amount: totalCost,
			orderId: order.razorPayOrderId,
			paymentAuthorized: (response) => {
				this.confirmOrder(order.id, response.razorpay_payment_id, response.razorpay_signature);
			}
		};
		return (
			<RazorPayWrapper
				asyncScriptOnLoad={() =>
					this.setState({
						checkoutInProgress: false,
						order: undefined,
						paymentInProgress: false,
						orderItem: undefined,
						totalCost: 0
					})
				}
				{...props}
			/>
		);
	}

	confirmOrder = async (orderId, paymentId, signature) => {
		const { userId, confirmOrder, purchaseNeedsUpdate, eventId } = this.props;
		try {
			this.setState({ paymentInProgress: true });
			await confirmOrder(userId, orderId, paymentId, signature, eventId);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false,
				orderItem: undefined,
				totalCost: 0
			});
			purchaseNeedsUpdate();
		} catch (err) {
			message.error(err.message, 15);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false,
				orderItem: undefined,
				totalCost: 0
			});
		}
	};

	render() {
		const { className } = this.props;
		const { products, error, paymentInProgress, checkoutInProgress, order } = this.state;
		return (
			<div className={classNames(className, 'buy-pack')} style={{ minWidth: 0 }}>
				<Spin spinning={this.state.loading}>
					{error ? <Alert message={error} type="error" /> : null}
					{products ? this.productComp(products) : null}
					{paymentInProgress ? <FullScreenIndicator /> : null}
					{checkoutInProgress && order ? this.checkout(order) : null}
				</Spin>
			</div>
		);
	}
}

BuySingleProduct.propTypes = {
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	whitelabelId: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	fetchProducts: PropTypes.func.isRequired,
	placeOrder: PropTypes.func.isRequired,
	confirmOrder: PropTypes.func.isRequired,
	purchaseNeedsUpdate: PropTypes.func.isRequired,
	eventId: PropTypes.string,
	productType: PropTypes.number.isRequired,
	walletBalance: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId,
		whitelabelId: state.auth.whitelabelId,
		email: state.auth.email,
		phone: state.auth.phone
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchProducts: async (userId) => {
		return await Product.fetchProducts(userId, ownProps.productType);
	},
	placeOrder: async (userId, orderItems, useWallet, eventId) => {
		return await Order.placeOrder(userId, orderItems, useWallet, eventId);
	},
	confirmOrder: async (userId, orderId, razorPayPaymentId, signature, eventId) => {
		return await Order.confirmOrder(userId, orderId, razorPayPaymentId, signature, eventId);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BuySingleProduct);
