import { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio, Row, Col } from 'antd';
import CircleTitle from './CircleTitle';

const RadioGroup = Radio.Group;

class WatermarkPositionComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	render() {
		const { position, positionChanged } = this.props;

		if (this.state.isMobile) {
			return (
				<div style={{ backgroundColor: '#F5F5F5', padding: '10px', margin: '10px 0px' }}>
					<RadioGroup
						value={position}
						onChange={(e) => {
							positionChanged(e.target.value);
						}}>
						<Row style={{ marginBottom: '5px' }}>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={0}>
									<span
										style={{
											fontSize: '18px',
											color: '#111111DE',
											textAlign: 'center',
											fontWeight: 300
										}}>
										Top Left
									</span>
								</Radio>
							</Col>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={1}>
									<span
										style={{
											fontSize: '18px',
											color: '#111111DE',
											textAlign: 'center',
											fontWeight: 300
										}}>
										Top Right
									</span>
								</Radio>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={2}>
									<span
										style={{
											fontSize: '18px',
											color: '#111111DE',
											textAlign: 'center',
											fontWeight: 300
										}}>
										Bottom Left
									</span>
								</Radio>
							</Col>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={3}>
									<span
										style={{
											fontSize: '18px',
											color: '#111111DE',
											textAlign: 'center',
											fontWeight: 300
										}}>
										Bottom Right
									</span>
								</Radio>
							</Col>
						</Row>
					</RadioGroup>
				</div>
			);
		}

		return (
			<div style={this.props.style}>
				<Row style={{ marginBottom: '10px' }}>
					<Col span={24}>
						<CircleTitle title="CHOOSE POSITION" />
					</Col>
				</Row>
				<Row>
					<RadioGroup
						value={position}
						onChange={(e) => {
							positionChanged(e.target.value);
						}}>
						<Row style={{ marginBottom: '5px' }}>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={0}>
									Top Left
								</Radio>
							</Col>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={1}>
									Top Right
								</Radio>
							</Col>
						</Row>
						<Row>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={2}>
									Bottom Left
								</Radio>
							</Col>
							<Col span={12}>
								<Radio className="setwatermark-radio" value={3}>
									Bottom Right
								</Radio>
							</Col>
						</Row>
					</RadioGroup>
				</Row>
			</div>
		);
	}
}

WatermarkPositionComp.propTypes = {
	position: PropTypes.number.isRequired,
	positionChanged: PropTypes.func.isRequired
};

export default WatermarkPositionComp;
