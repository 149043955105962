import BillingDetails from '../pouchDB/models/BillingDetails';
import {
	ACTION_BILLING_DETAILS_CREATED,
	ACTION_BILLING_DETAILS_FETCHED,
	ACTION_BILLING_DETAILS_UPDATED
} from './action_types';

export const billingDetailsFetcthed = (billingDetails) => ({
	type: ACTION_BILLING_DETAILS_FETCHED,
	billingDetails: billingDetails
});

export const billingDetailsCreated = (billingDetails) => ({
	type: ACTION_BILLING_DETAILS_CREATED,
	billingDetails: billingDetails
});

export const billingDetailsUpdated = (billingDetails) => ({
	type: ACTION_BILLING_DETAILS_UPDATED,
	billingDetails: billingDetails
});

export const createBillingDetailsMAction = (billingDetails) => {
	return (dispatch, getState) => {
		const { userId } = getState().auth;
		return BillingDetails.createBillingDetails(userId, billingDetails)
			.then((result) => {
				dispatch(billingDetailsCreated(result));
				return Promise.resolve(result);
			})
			.catch((error) => {
				console.error('Error creating billing details:', error);
				return Promise.reject(error);
			});
	};
};

export const updateBillingDetailsMAction = (billingDetails) => {
	return (dispatch, getState) => {
		const { userId } = getState().auth;
		return BillingDetails.editBillingDetails(userId, billingDetails)
			.then((result) => {
				dispatch(billingDetailsUpdated(result));
				return Promise.resolve(result);
			})
			.catch((error) => {
				console.error('Error updating billing details:', error);
				return Promise.reject(error);
			});
	};
};

export const fetchBillingDetailsMAction = () => {
	return (dispatch, getState) => {
		const { userId } = getState().auth;
		return BillingDetails.getBillingDetails(userId)
			.then((billingDetails) => {
				return Promise.resolve(billingDetails);
			})
			.catch((error) => {
				console.error('Error fetching billing details:', error);
				return Promise.reject(error);
			});
	};
};
