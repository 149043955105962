import { SettingOutlined } from '@ant-design/icons';
import { Button, message, Space, Spin, Tabs } from 'antd';
import { replace } from 'connected-react-router';
import csvDownload from 'json-to-csv-export';
import { ArrowLeft } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { createAlbumMAction, eventClicked, updateMyEventsMAction } from '../actions/pouchDBActions';
import { createVideoLinkMAction } from '../actions/videoLinkActions';
import AdminRequest from '../components/AdminRequest';
import EventSettings from '../components/EventSettings';
import ExpiryNoticeBanner from '../components/ExpiryNoticeBanner';
import FullScreenIndicator from '../components/FullScreenIndicator';
import NoAlbumsComp from '../components/NoAlbumsComp';
import FaceDetectionModal from '../components/PurchaseFaceDetection';
import ShareEvent from '../components/ShareEvent';
import UpgradeEvent from '../components/UpgradeEvent';
import Event from '../pouchDB/models/Event';
import Albums from './Albums';
import Photos from './Photos';
import SortBySetting from './SortBySetting';
import VideoLinks from './VideoLinks';

import '../styles/EventHome.sass';

const { TabPane } = Tabs;

const EventHomeComp = (props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [state, setState] = useState({
		eventDocId: props.match.params.eventId,
		fetchEventError: undefined,
		showUpgrade: false,
		facialUpgrade: false,
		showSettings: false,
		isMobile: window.innerWidth <= 576,
		activeTab: '1',
		adminRequest: false,
		showShare: false
	});

	const fetchEvent = useCallback(async () => {
		setLoading(true);
		setError(null);
		try {
			const event = await Event.fetchEvent(state.eventDocId);
			setState((prevState) => ({ ...prevState, event, fetchEventError: undefined }));
			props.showEvent(event);
		} catch (err) {
			console.error(err);
			setState((prevState) => ({ ...prevState, event: undefined, fetchEventError: true }));
			setError('Failed to fetch event data');
		} finally {
			setLoading(false);
		}
	}, [state.eventDocId, props.showEvent]);

	useEffect(() => {
		if (!props.event && !state.fetchEventError) {
			fetchEvent();
		}
	}, [props.event, state.fetchEventError, fetchEvent]);

	useEffect(() => {
		const handleResize = () => {
			setState((prevState) => ({
				...prevState,
				isMobile: window.innerWidth <= 576
			}));
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleFaceDetectionComplete = async () => {
		try {
			const updatedEvent = await Event.fetchEvent(props.event.id);
			props.updateEvent(updatedEvent);
		} catch (error) {
			console.error('Failed to refresh event:', error);
			message.error('Failed to refresh event data');
		}
	};

	const calculateDaysLeft = (expiryDate) => {
		const today = new Date();
		const expiry = new Date(expiryDate);
		const timeDiff = expiry.getTime() - today.getTime();
		return Math.ceil(timeDiff / (1000 * 3600 * 24));
	};

	const handleRenewed = () => {
		props.refreshEvents();
	};

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!props.event) {
		if (state.fetchEventError) {
			props.goBackClicked();
			return null;
		}

		return <FullScreenIndicator />;
	}

	const {
		userId,
		event,
		album,
		visibleAlbums,
		createAlbum,
		createVideoLink,
		goBackClicked,
		eventPhotoCount
	} = props;

	const daysLeft = calculateDaysLeft(event.expiresAt);
	const showExpiryBanner = daysLeft <= 30;

	const header = (
		<div className="pageTitleBar">
			<Button
				onClick={(e) => goBackClicked()}
				icon={<ArrowLeft />}
				style={{ marginRight: 20, padding: 10 }}
			/>
			<div className="pageTitle" style={{ marginBottom: 10 }}>
				<h1 style={{ display: 'inline-block' }}>{event.name}</h1>
				<p style={{ display: 'inline', margin: '20px', height: '60px' }}>
					Photos: {eventPhotoCount || 0}/{event.maxPhotos - (event.guestMaxPhotos || 0)}
				</p>
				<h3 style={{ display: 'inline-block' }} className="pageTitleEventCode">
					{'ID: '}
					{event.eventId}
				</h3>
			</div>
			<Button
				type="primary"
				size="small"
				style={{ marginRight: '10px' }}
				onClick={() => setState((prevState) => ({ ...prevState, adminRequest: true }))}>
				Admin Request
			</Button>
			<Button
				type="primary"
				size="small"
				style={{ marginRight: '10px' }}
				onClick={() => setState((prevState) => ({ ...prevState, showShare: true }))}>
				Share
			</Button>
			<FaceDetectionModal event={event} onComplete={handleFaceDetectionComplete} />
			{visibleAlbums && visibleAlbums.length > 0 && (
				<Button
					type="primary"
					size="small"
					style={{ marginRight: '10px' }}
					onClick={() => setState((prevState) => ({ ...prevState, showUpgrade: true }))}>
					Increase Photo Limit
				</Button>
			)}
			{event.isRegistrationRequired && (
				<Button
					type="primary"
					size="small"
					style={{ marginRight: '10px' }}
					onClick={async () => {
						try {
							const data = await Event.getRegistrations(userId, event.id);
							if (data.length === 0) {
								message.info('No Guests have registered for this Event yet');
								return;
							}
							const dataToConvert = {
								data: data,
								filename: `${event.name}-${event.eventId}-Guest-Data`,
								delimiter: ',',
								headers: ['Email', 'Name', 'Phone']
							};
							csvDownload(dataToConvert);
						} catch (e) {
							message.error(e.message);
						}
					}}>
					Download Guests Data
				</Button>
			)}
			<Button
				type="primary"
				size="small"
				style={{ display: 'inline-block', marginRight: '10px', alignSelf: 'center' }}
				onClick={() => setState((prevState) => ({ ...prevState, showSettings: true }))}>
				Settings
			</Button>
		</div>
	);

	const headerMobile = (
		<div style={{ width: '100%', marginTop: showExpiryBanner ? '25px' : '0' }}>
			<div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '20px' }}>
				<Button
					onClick={(e) => goBackClicked()}
					icon={<ArrowLeft />}
					style={{ marginRight: 10, padding: 10 }}
				/>
				<div>
					<h3 style={{ marginTop: -10, fontSize: '20px', color: '#18181B', fontWeight: 700 }}>
						{event.name}
					</h3>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<div
							style={{ marginRight: '10px', color: '#71717A', fontSize: '16px', fontWeight: 400 }}>
							<span>
								Photos: {eventPhotoCount || 0}/{event.maxPhotos - event.guestMaxPhotos}
							</span>
						</div>
						<h6 style={{ margin: 0, color: '#71717A', fontSize: '16px', fontWeight: 400 }}>
							{'   ID : '}
							{event.eventId}
						</h6>
					</div>
				</div>
				<div style={{ flex: 1 }} />
				<SettingOutlined
					style={{ fontSize: '1.2rem' }}
					role="button"
					onClick={() => setState((prevState) => ({ ...prevState, showSettings: true }))}
				/>
			</div>
			<Space style={{ padding: '0px 20px' }} wrap>
				<Button
					size="small"
					onClick={() => setState((prevState) => ({ ...prevState, adminRequest: true }))}>
					Admin Request
				</Button>
				<Button
					size="small"
					onClick={() => setState((prevState) => ({ ...prevState, showShare: true }))}>
					<span style={{ color: '#000000DE', fontSize: '14px', fontWeight: 400 }}>Share</span>
				</Button>
				<FaceDetectionModal event={event} onComplete={handleFaceDetectionComplete} />
				{visibleAlbums && visibleAlbums.length > 0 && (
					<Button
						size="small"
						onClick={() => setState((prevState) => ({ ...prevState, showUpgrade: true }))}>
						<span style={{ color: '#000000DE', fontSize: '14px', fontWeight: 400 }}>
							Increase Photo Limit
						</span>
					</Button>
				)}
				{event.isRegistrationRequired && (
					<Button
						size="small"
						onClick={async () => {
							try {
								const data = await Event.getRegistrations(userId, event.id);
								if (data.length === 0) {
									message.info('No Guests have registered for this Event yet');
									return;
								}
								const dataToConvert = {
									data: data,
									filename: `${event.name}-${event.eventId}-Guest-Data`,
									delimiter: ',',
									headers: ['Email', 'Name', 'Phone']
								};
								csvDownload(dataToConvert);
							} catch (e) {
								message.error(e.message);
							}
						}}>
						<span style={{ color: '#000000DE', fontSize: '14px', fontWeight: 400 }}>
							Download Guests Data
						</span>
					</Button>
				)}
			</Space>
			<hr style={{ margin: '20px 0px', color: '#D4D4D8' }} />
		</div>
	);

	return (
		<>
			{showExpiryBanner && (
				<ExpiryNoticeBanner event={event} daysLeft={daysLeft} onRenewed={handleRenewed} />
			)}
			<div className="event-home">
				{loading && (
					<div style={{ position: 'fixed', top: 20, right: 20, zIndex: 1000 }}>
						<Spin tip="Loading..." />
					</div>
				)}
				{state.isMobile ? headerMobile : header}

				{state.isMobile ? (
					<Tabs
						style={{ width: '100%', marginBottom: '20px' }}
						onTabClick={(key) => setState((prevState) => ({ ...prevState, activeTab: key }))}>
						<TabPane
							tab={
								<span
									style={{
										fontSize: '14px',
										color: state.activeTab === '1' ? '#FF404C' : '#52525B',
										fontWeight: 500,
										textTransform: 'uppercase'
									}}>
									Albums
								</span>
							}
							key="1">
							<Albums createAlbum={(name) => createAlbum(userId, event, name)} />
							{album && <Photos className="event-home-photos" />}
						</TabPane>
						<TabPane
							tab={
								<span
									style={{
										fontSize: '14px',
										color: state.activeTab === '2' ? '#FF404C' : '#52525B',
										fontWeight: 500,
										textTransform: 'uppercase'
									}}>
									Videos
								</span>
							}
							key="2">
							<VideoLinks
								createVideoLink={(name, link) => createVideoLink(userId, event, name, link)}
							/>
						</TabPane>
					</Tabs>
				) : (
					<>
						<div className="event-home-albums">
							<Albums createAlbum={(name) => createAlbum(userId, event, name)} />
							<VideoLinks
								createVideoLink={(name, link) => createVideoLink(userId, event, name, link)}
							/>
							<SortBySetting />
						</div>
						{visibleAlbums && visibleAlbums.length === 0 ? (
							<NoAlbumsComp
								className="no-data-placeholder"
								createAlbum={(name) => createAlbum(userId, event, name)}
							/>
						) : (
							<Photos className="event-home-photos" />
						)}
					</>
				)}
				{state.showShare && (
					<div>
						<ShareEvent
							onComplete={() => setState((prevState) => ({ ...prevState, showShare: false }))}
						/>
						<div
							className="flexCenterFullScreenContainer"
							style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
						/>
					</div>
				)}
				{state.adminRequest && (
					<div>
						<AdminRequest
							onComplete={() => setState((prevState) => ({ ...prevState, adminRequest: false }))}
						/>
						<div
							className="flexCenterFullScreenContainer"
							style={{ backgroundColor: 'rgba(255,255,255,0.7)' }}
						/>
					</div>
				)}
				{state.showUpgrade && (
					<div>
						<UpgradeEvent
							eventId={event.id}
							facialUpgrade={state.facialUpgrade}
							onComplete={() =>
								setState((prevState) => ({
									...prevState,
									showUpgrade: false,
									facialUpgrade: false
								}))
							}
						/>
						<FullScreenIndicator />
					</div>
				)}
				{state.showSettings && (
					<div>
						<EventSettings
							onComplete={() => setState((prevState) => ({ ...prevState, showSettings: false }))}
							ShareEvent={ShareEvent}
						/>
					</div>
				)}
			</div>
		</>
	);
};

EventHomeComp.propTypes = {
	createAlbum: PropTypes.func.isRequired,
	match: PropTypes.shape({
		params: PropTypes.shape({
			eventId: PropTypes.string.isRequired
		})
	}),
	event: PropTypes.object,
	album: PropTypes.object,
	visibleAlbums: PropTypes.array,
	userId: PropTypes.string.isRequired,
	goBackClicked: PropTypes.func.isRequired,
	eventPhotoCount: PropTypes.number,
	showEvent: PropTypes.func.isRequired,
	updateEvent: PropTypes.func.isRequired,
	createVideoLink: PropTypes.func.isRequired,
	refreshEvents: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	event: state.pouchDB.visibleEvent,
	eventPhotoCount:
		state.pouchDB.visibleEvent && state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id]
			? state.pouchDB.photoCounts[state.pouchDB.visibleEvent.id].count
			: undefined,
	album: state.pouchDB.clickedAlbum,
	visibleAlbums: state.pouchDB.visibleAlbums
});

const mapDispatchToProps = (dispatch) => ({
	goBackClicked: () => {
		dispatch(replace('/events'));
	},
	createAlbum: (userId, event, name) => {
		dispatch(createAlbumMAction(userId, event, name));
	},
	showEvent: (event) => {
		console.log(event);
		dispatch(eventClicked(event));
	},
	createVideoLink: (userId, event, name, link) => {
		dispatch(createVideoLinkMAction(userId, event, name, link));
	},
	updateEvent: (event) => dispatch(eventClicked(event)),
	refreshEvents: () => dispatch(updateMyEventsMAction(1, true))
});

const EventHome = connect(mapStateToProps, mapDispatchToProps)(EventHomeComp);

export default EventHome;
