import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PostAd from './PostAd'
import { fetchPostsMAction, deletePostMAction } from '../actions/PostActions'
import { WarningOutlined } from '@ant-design/icons';
import { Button, Table, Divider, Empty, Popconfirm } from 'antd';

class PostListComp extends Component {
	state = {
		showNewPostForm: false,
		editingPost: undefined,
		editingIndex: undefined
	}

	deletePopOver = (
		<div>
			<p>Are you sure?</p>
			<p>This will permanently delete this post and remove from all of your User's devices.</p>
		</div>
	)

	columns = [{
		title: 'Post Text',
		dataIndex: 'text',
		width: '85%',
	}, {
		title: 'Action',
		key: 'action',
		render: (_, record, index) => (
			<span>
                <div className='anchorDiv' onClick={
					() =>
						this.edit(index, record)
				}>Edit</div>
                <Divider type="vertical" />

                <Popconfirm title={this.deletePopOver}
							icon={<WarningOutlined />}
							onConfirm={(e) => {
								e.stopPropagation();
								this.props.deletePost(index, record.id)
							}}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Delete Anyway!"
							cancelText="Cancel">

                    <div className='anchorDiv'>Delete</div>
                </Popconfirm>
            </span>
		),
	}];

	edit(index, post) {
		this.setState({
			showNewPostForm: true,
			editingPost: post,
			editingIndex: index
		})
	}

	componentDidMount() {
		this.props.fetchPosts()
	}

	getPostsSection(posts) {
		if (posts !== undefined && posts.length === 0) {
			return (
				<Empty description='No Posts' style={{ marginTop: '250px' }}>
					<Button type='primary' onClick={() =>
						this.setState({ showNewPostForm: true })
					}>Create Post</Button>
				</Empty>
			)
		}

		return (
			<Table
				columns={this.columns}
				rowKey={record => record.id}
				dataSource={this.props.posts}
				loading={posts === undefined}
				pagination={false}
			/>
		)
	}

	render() {
		const { posts } = this.props
		return (
			<div>
				<div className='pageTitleBar'>
					<h1 className='pageTitle'>
						My Posts
					</h1>
					<Button type='primary' className='pageRightDetail' onClick={() =>
						this.setState({ showNewPostForm: true })
					}>Create Post</Button>
				</div>
				{
					this.state.showNewPostForm ?
						<PostAd editingPost={this.state.editingPost} editingIndex={this.state.editingIndex} onClose={() => this.setState({
							showNewPostForm: false,
							editingPost: undefined,
							editingIndex: undefined
						})} />
						: null
				}
				{
					this.getPostsSection(posts)
				}
			</div>
		)
	}
}

PostListComp.propTypes = {
	posts: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			notificationText: PropTypes.string.isRequired
		}).isRequired),
	fetchPosts: PropTypes.func.isRequired,
	deletePost: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		posts: state.post.visiblePosts
	}
}

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchPosts: () => {
		dispatch(fetchPostsMAction())
	},
	deletePost: (index, postDocId) => {
		dispatch(deletePostMAction(index, postDocId))
	}
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PostListComp)
