const validityPriceMap = {
	10000: 3000,
	6000: 2000,
	4500: 1500,
	3000: 1000,
	2000: 750,
	1000: 500,
	500: 250,
	250: 150,
	100: 75
};

export const calculateValidityPrice = (maxPhotos) => {
	let cost = 0;
	let remainingPhotos = maxPhotos;

	for (const photos of [10000, 6000, 4500, 3000, 2000, 1000, 500, 250, 100]) {
		if (remainingPhotos >= photos) {
			const packCount = Math.floor(remainingPhotos / photos);
			cost += (validityPriceMap[photos] * packCount) / 2;
			remainingPhotos = remainingPhotos % photos;
		}
	}

	return cost;
};
