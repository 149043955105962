import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message, Modal, Tabs } from 'antd';
import PropTypes from 'prop-types';
import Event from '../pouchDB/models/Event';
import FullScreenIndicator from './FullScreenIndicator';
import ShareQR from './ShareQR';
import ShareMessage from './ShareMessage';

const { TabPane } = Tabs;

const ShareEvent = ({ event, onComplete, auth }) => {
	const [loading, setLoading] = useState(false);
	event;
	const [shareDetail, setShareDetail] = useState(undefined);
	const [copied, setCopied] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const shareDetails = await Event.fetchShareDetail(event.id);
				if (shareDetails) {
					setShareDetail(shareDetails);
					setLoading(false);
				} else {
					setLoading(false);
					message.error('Failed to get sharing details. Please try again.');
					onComplete();
				}
			} catch (err) {
				setLoading(false);
				message.error(err.message);
				onComplete();
			}
		};
		fetchData();
	}, [event.id, onComplete]);

	const copyToClipBoard = (item, text) => {
		const textarea = document.createElement('textarea');
		document.body.appendChild(textarea);
		textarea.value = text;
		textarea.select();
		textarea.setSelectionRange(0, 99999);
		document.execCommand('copy');
		document.body.removeChild(textarea);
		setCopied(item);
		setTimeout(() => {
			setCopied(0);
		}, 3000);
	};

	const shareModal = (shareDetail) => {
		return (
			<Modal open closable={true} footer={null} onCancel={() => onComplete()}>
				<Tabs>
					<TabPane
						tab={
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								<span style={{ fontSize: '16px', color: '#6F4898', fontWeight: '500' }}>
									Share Message
								</span>
							</div>
						}
						key="1">
						<ShareMessage shareDetail={shareDetail} auth={auth} />
					</TabPane>
					<TabPane
						tab={
							<span style={{ fontSize: '16px', color: '#6F4898', fontWeight: 500 }}>Qr Codes</span>
						}
						key="2">
						<ShareQR shareDetail={shareDetail} auth={auth} eventName={event.name} />
					</TabPane>
					<TabPane
						tab={<span style={{ fontSize: '16px', color: '#6F4898', fontWeight: 500 }}>PIN</span>}
						key="3">
						<strong style={{ fontSize: '16px', fontWeight: 500, color: '#4A5568' }}>
							PIN to Download and View All Photos from This Event
						</strong>
						<div
							className="share-copy-wrapper"
							style={{ marginTop: '4px', marginBottom: '4px' }}
							onClick={() => copyToClipBoard(3, shareDetail.downloadPin)}>
							<span style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 400 }}>
								{shareDetail.downloadPin}
							</span>
							{copied === 3 ? (
								<div className="share-copy copied" style={{ fontSize: '14px', fontWeight: 600 }}>
									Copied!
								</div>
							) : (
								<div className="share-copy" style={{ fontSize: '14px', fontWeight: 600 }}>
									COPY
								</div>
							)}
						</div>

						<p style={{ color: '#666', fontSize: '16px', fontWeight: 400 }}>
							This PIN is used for :
						</p>
						<p style={{ color: '#666', fontSize: '15px', fontWeight: 400 }}>
							1. Downloading all photos (Weblink only, max 5 times)
						</p>
						<p style={{ color: '#666', fontSize: '15px', fontWeight: 400 }}>
							2. Viewing all photos when privacy is enabled
						</p>
					</TabPane>
				</Tabs>
			</Modal>
		);
	};

	return (
		<div>
			{shareDetail ? shareModal(shareDetail) : null}
			{loading ? <FullScreenIndicator /> : null}
		</div>
	);
};

ShareEvent.propTypes = {
	event: PropTypes.object.isRequired,
	onComplete: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
	event: state.pouchDB.visibleEvent,
	auth: state.auth
});

export default connect(mapStateToProps)(ShareEvent);
