import { message } from 'antd';
import { bindAll } from 'lodash';
import PropTypes from 'prop-types';
import { Component } from 'react';
import RazorPayWrapper from '../containers/RazorPayWrapper';
import { PRODUCT_TYPE } from '../pouchDB/models/Product';
import '../styles/CreateEvent.sass';
import '../styles/Input.sass';
import BuyProductsComp from './BuyProductsComp';
import CircleTitle from './CircleTitle';
import ExpiringEventsBanner from './ExpiringEventsBanner';
import ExpiringFaceEventsBanner from './ExpiringFaceEventsBanner';
import FullScreenIndicator from './FullScreenIndicator';
import PackComp from './PackComp';
import PhoneNumberPopup from './PhoneNumberPopup';
import WalletComp from './WalletComp';

export const EVENT_NAME_LENGTH = 35;

class DashboardComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			myPurchases: [],
			showIndicator: false,
			wallet: undefined,
			checkoutInProgress: false,
			order: undefined,
			isMobile: window.screen.width <= 576,
			showPhonePopup: false
		};
		bindAll(this, ['paidPacksComps', 'myPacksComps', 'handleWindowResize']);
	}

	componentDidMount() {
		this.fetchPurchases(false).then();
		this.getBalance().then();
		window.addEventListener('resize', this.handleWindowResize);
		this.checkPhoneNumber();
	}

	checkPhoneNumber = () => {
		const { phone } = this.props;
		if (!phone) {
			this.setState({ showPhonePopup: true });
		}
	};

	handlePhoneSubmit = async (phoneNumber) => {
		const { userId, updatePhoneNumber } = this.props;
		try {
			await updatePhoneNumber(userId, phoneNumber);
			message.success('Phone number updated successfully', 1);
			this.setState({ showPhonePopup: false });
		} catch (error) {
			message.error('Failed to update phone number. Please try again.');
		}
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	fetchPurchases = async (consistent) => {
		const { userId, whitelabelId, fetchPurchasedPacks } = this.props;
		try {
			let purchases = await fetchPurchasedPacks(userId, whitelabelId, consistent);
			this.setState({ myPurchases: purchases });
		} catch (err) {
			message.error(err.message);
		}
	};

	getBalance = async () => {
		const { userId, fetchBalance } = this.props;
		try {
			let wallet = await fetchBalance(userId);
			this.setState({ wallet: wallet });
		} catch (err) {
			message.error(err.message);
		}
	};

	recharge = async (amount) => {
		const { userId, fetchWalletProduct, placeRechargeOrder } = this.props;
		try {
			const products = await fetchWalletProduct(userId);
			if (products.length !== 1) {
				message.error('Wallet product not found. please contact administrator.');
			}
			const order = await placeRechargeOrder(
				userId,
				[
					{
						quantity: 1,
						productId: products[0].id
					}
				],
				amount
			);
			this.setState({ checkoutInProgress: true, order: order });
		} catch (err) {
			message.error(err.message);
		}
		return Promise.resolve();
	};

	redeem = async (code) => {
		const { userId, redeem } = this.props;
		try {
			let wallet = await redeem(userId, code);
			this.setState({ wallet: wallet });
			message.success('Code redeemed!');
		} catch (err) {
			message.error(err.message);
		}
	};

	walletComp() {
		return (
			<div
				style={this.state.isMobile ? { marginTop: '30px', width: '100%' } : { marginTop: '30px' }}>
				<WalletComp
					balance={this.state.wallet ? this.state.wallet.balance : undefined}
					recharge={this.recharge}
					redeem={this.redeem}
				/>
			</div>
		);
	}

	myPacksComps() {
		const { myPurchases } = this.state;
		if (!myPurchases || myPurchases.length === 0) {
			return (
				<div className="my-packs">
					<h3 className="pack-title">You have no packs.</h3>
				</div>
			);
		}

		if (this.state.isMobile) {
			return (
				<div
					style={{
						textAlign: 'left',
						width: '100%'
					}}>
					<div>
						<h3
							style={{
								fontSize: '20px',
								color: '#27272A',
								fontWeight: '700'
							}}>
							My Event Packs
						</h3>
						{this.state.myPurchases.map((purchase) => (
							<PackComp
								key={purchase.id}
								name={purchase.title}
								notableDetail={
									purchase.unusedQuantity === 9999
										? 'UNLIMITED'
										: purchase.unusedQuantity + ' Unused'
								}
								// detail1={'Event valid for ' + purchase.validity + ' months'}
								detail1={'Event valid for ' + 12 + ' months'}
								// detail2={
								// 	purchase.isOpen
								// 		? 'All guests can upload photos'
								// 		: 'Only selected guests can upload photos'
								// }
								// detail3={purchase.photos + ' photos with configurable watermark'}
								detail4=""
								serverId={purchase}
							/>
						))}
					</div>
				</div>
			);
		}

		return (
			<div className="my-packs">
				<CircleTitle className="pack-title" title="MY EVENT PACKS" />

				{this.state.myPurchases.map((purchase) => (
					<PackComp
						key={purchase.id}
						name={purchase.title}
						notableDetail={
							purchase.unusedQuantity === 9999 ? 'UNLIMITED' : purchase.unusedQuantity + ' Unused'
						}
						// detail1={'Event valid for ' + purchase.validity + ' months'}
						detail1={'Event valid for ' + 12 + ' months'}
						// detail2={
						// 	purchase.isOpen
						// 		? 'All guests can upload photos'
						// 		: 'Only selected guests can upload photos'
						// }
						// detail3={purchase.photos + ' photos with configurable watermark'}
						// detail4=""
						serverId={purchase}
					/>
				))}
			</div>
		);
	}

	paidPacksComps() {
		if (this.state.isMobile) {
			return (
				<div
					style={{
						textAlign: 'left',
						width: '100%',
						margin: '10px 0px'
					}}>
					<div>
						<h3
							style={{
								fontSize: '20px',
								color: '#27272A',
								fontWeight: 700
							}}>
							Add Event Packs
						</h3>
					</div>
					<div>
						<BuyProductsComp
							productType={PRODUCT_TYPE.photos_pack}
							purchaseNeedsUpdate={async () => {
								try {
									this.setState({ showIndicator: true });
									await this.fetchPurchases(true);
									await this.getBalance();
									this.setState({ showIndicator: false });
									message.success('Payment success! Your packs are now loaded.');
								} catch (err) {
									this.setState({ showIndicator: false });
									message.error(err.message);
								}
							}}
							walletBalance={this.state.wallet ? this.state.wallet.balance : undefined}
						/>
					</div>
				</div>
			);
		}

		return (
			<div className="buy-packs">
				<CircleTitle className="pack-title" title="ADD EVENT PACKS" />
				<BuyProductsComp
					productType={PRODUCT_TYPE.photos_pack}
					purchaseNeedsUpdate={async () => {
						try {
							this.setState({ showIndicator: true });
							await this.fetchPurchases(true);
							await this.getBalance();
							this.setState({ showIndicator: false });
							message.success('Payment success! Your packs are now loaded.');
						} catch (err) {
							this.setState({ showIndicator: false });
							message.error(err.message);
						}
					}}
					walletBalance={this.state.wallet ? this.state.wallet.balance : undefined}
				/>
			</div>
		);
	}

	checkout(order) {
		const { email, mobile } = this.props;

		let props = {
			email: email,
			mobile: mobile,
			amount: order.amount,
			orderId: order.razorPayOrderId,
			paymentAuthorized: (response) => {
				this.confirmOrder(order.id, response.razorpay_payment_id, response.razorpay_signature);
			}
		};
		return (
			<RazorPayWrapper
				asyncScriptOnLoad={() => this.setState({ checkoutInProgress: false, order: undefined })}
				{...props}
			/>
		);
	}

	confirmOrder = async (orderId, paymentId, signature) => {
		const { userId, confirmOrder } = this.props;
		try {
			this.setState({ showIndicator: true });
			await confirmOrder(userId, orderId, paymentId, signature);
			await this.getBalance();
			this.setState({ showIndicator: false, checkoutInProgress: false, order: undefined });
		} catch (err) {
			message.error(err.message, 15);
			this.setState({ checkoutInProgress: false, order: undefined, showIndicator: false });
		}
	};

	render() {
		const { whitelabelId } = this.props;
		return (
			<div>
				<div style={{ marginTop: '10px' }}>
					<ExpiringEventsBanner onRenewed={() => {}} />
				</div>
				<div style={{ marginTop: '10px' }}>
					<ExpiringFaceEventsBanner onRenewed={() => {}} />
				</div>

				<div className="packs">
					{whitelabelId ? this.walletComp() : null}
					{this.myPacksComps()}
					{this.paidPacksComps()}
					{/* <div style={{ margin: '10px 0px' }}>
						<span style={{ fontSize: '16px', fontWeight: 400 }}>
							All Events valid for 1 Years from creation
						</span>
					</div> */}
					<div style={{ fontSize: '16px', fontWeight: 400 }}>
						If you are not from India then please{' '}
						<div
							className="anchorDiv"
							onClick={(e) => {
								e.preventDefault();
								window.open('http://kamero.in#contact', '_blank');
							}}>
							contact us
						</div>{' '}
						for buying packs.
					</div>
				</div>
				{this.state.showIndicator ? <FullScreenIndicator /> : null}
				{this.state.checkoutInProgress && this.state.order ? this.checkout(this.state.order) : null}
				<PhoneNumberPopup
					visible={this.state.showPhonePopup}
					onSubmit={this.handlePhoneSubmit}
					onCancel={() => this.setState({ showPhonePopup: false })}
				/>
			</div>
		);
	}
}

DashboardComp.propTypes = {
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	whitelabelId: PropTypes.string.isRequired,
	fetchPurchasedPacks: PropTypes.func.isRequired,
	createEvent: PropTypes.func.isRequired,
	eventCreated: PropTypes.func.isRequired,
	fetchBalance: PropTypes.func.isRequired,
	fetchWalletProduct: PropTypes.func.isRequired,
	placeRechargeOrder: PropTypes.func.isRequired,
	confirmOrder: PropTypes.func.isRequired,
	redeem: PropTypes.func.isRequired,
	phone: PropTypes.string,
	updatePhoneNumber: PropTypes.func.isRequired
};

export default DashboardComp;
