import { Component } from 'react';
import PropTypes from 'prop-types';
import { Tag, Button, Input, Row, Space, Col, Card } from 'antd';
const { Search } = Input;
import { DownOutlined, UpOutlined } from '@ant-design/icons';
class WalletComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			recharging: false,
			rechargeAmount: undefined,
			isSearchDisabled: true,
			redeeming: false,
			couponCode: undefined,
			isRedeemDisabled: true,
			accordionOpen: false,
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}
	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};
	calculateGST = (amount) => {
		// Convert the input to a number
		const numericAmount = typeof amount === 'number' ? amount : parseFloat(amount);

		if (isNaN(numericAmount) || numericAmount <= 0) {
			return {
				amount: 0,
				gst: 0,
				total: 0
			};
		}

		const fixedAmount = parseFloat(numericAmount.toFixed(2));
		const gst = (fixedAmount * 0.18).toFixed(2);
		const total = (parseFloat(fixedAmount) + parseFloat(gst)).toFixed(2);

		return {
			amount: fixedAmount,
			gst: parseFloat(gst),
			total: parseFloat(total)
		};
	};

	render() {
		const { balance, recharge, redeem } = this.props;

		const { gst, total } = this.state.rechargeAmount
			? this.calculateGST(this.state.rechargeAmount)
			: { gst: 0, total: 0 };

		if (this.state.isMobile) {
			return (
				<Card
					style={{
						alignContent: 'center',
						alignItems: 'center',
						textAlign: 'center',
						marginBottom: '15px'
					}}>
					<h3>Available Credits</h3>
					<h2 style={{ fontSize: '24px', marginBottom: '16px' }}>
						{balance !== undefined
							? (balance / 100).toLocaleString('en-IN', {
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
									style: 'currency',
									currency: 'INR'
							  })
							: '--'}
					</h2>
					<Search
						type="number"
						step="100"
						placeholder="Amount"
						enterButton={
							<Button
								type="primary"
								disabled={this.state.isSearchDisabled}
								style={{ paddingLeft: '16px', paddingRight: '16px' }}>
								Buy Credits
							</Button>
						}
						loading={this.state.recharging}
						value={this.state.rechargeAmount}
						onChange={(e) => {
							this.setState({
								rechargeAmount: e.target.value,
								isSearchDisabled: e.target.value ? e.target.value <= 0 : true
							});
						}}
						onSearch={(value) => {
							if (this.state.recharging) {
								return;
							}
							if (value > 0) {
								this.setState({ recharging: true });
								recharge(value).then(() =>
									this.setState({
										rechargeAmount: undefined,
										recharging: false,
										isSearchDisabled: true
									})
								);
							}
						}}
						style={{ width: '250px', marginBottom: '16px' }}
					/>
					{this.state.rechargeAmount && (
						<div>
							<p>
								GST (18%): ₹
								{gst.toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								})}
							</p>
							<p>
								Total Payable: ₹
								{total.toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2
								})}
							</p>
						</div>
					)}
					<Row justify="center" style={{ alignItems: 'center' }}>
						<p>Got coupon code? Redeem below</p>
					</Row>
					<Search
						type="string"
						placeholder="Coupon Code"
						enterButton={
							<Button
								type="primary"
								disabled={this.state.isRedeemDisabled}
								style={{ paddingLeft: '16px', paddingRight: '16px' }}>
								Redeem
							</Button>
						}
						loading={this.state.redeeming}
						value={this.state.couponCode}
						onChange={(e) => {
							this.setState({
								couponCode: e.target.value,
								isRedeemDisabled: e.target.value ? e.target.value.length <= 0 : true
							});
						}}
						onSearch={(value) => {
							if (this.state.redeeming) {
								return;
							}
							if (value.length > 0) {
								this.setState({ redeeming: true });
								redeem(value).then(() =>
									this.setState({ couponCode: undefined, redeeming: false, isRedeemDisabled: true })
								);
							}
						}}
						style={{ width: '250px', marginBottom: '16px' }}
					/>
				</Card>
			);
		}

		return (
			<Card style={{ alignContent: 'center', alignItems: 'center', textAlign: 'center' }}>
				<h3>Available Credits</h3>
				<h2 style={{ fontSize: '24px', marginBottom: '16px' }}>
					{balance !== undefined
						? (balance / 100).toLocaleString('en-IN', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
								style: 'currency',
								currency: 'INR'
						  })
						: '--'}
				</h2>
				<Search
					type="number"
					step="100"
					placeholder="Amount"
					enterButton={
						<Button
							type="primary"
							disabled={this.state.isSearchDisabled}
							style={{ paddingLeft: '16px', paddingRight: '16px' }}>
							Buy Credits
						</Button>
					}
					loading={this.state.recharging}
					value={this.state.rechargeAmount}
					onChange={(e) => {
						this.setState({
							rechargeAmount: e.target.value,
							isSearchDisabled: e.target.value ? e.target.value <= 0 : true
						});
					}}
					onSearch={(value) => {
						if (this.state.recharging) {
							return;
						}
						if (value > 0) {
							this.setState({ recharging: true });
							recharge(value).then(() =>
								this.setState({
									rechargeAmount: undefined,
									recharging: false,
									isSearchDisabled: true
								})
							);
						}
					}}
					style={{ width: '250px', marginBottom: '16px' }}
				/>
				{this.state.rechargeAmount && (
					<div>
						<p>
							GST (18%): ₹
							{gst.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
						</p>
						<p>
							Total Payable: ₹
							{total.toLocaleString('en-IN', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}
						</p>
					</div>
				)}
				<Row justify="center" style={{ alignItems: 'center' }}>
					<p>Got coupon code? Redeem below</p>
				</Row>
				<Search
					type="string"
					placeholder="Coupon Code"
					enterButton={
						<Button
							type="primary"
							disabled={this.state.isRedeemDisabled}
							style={{ paddingLeft: '16px', paddingRight: '16px' }}>
							Redeem
						</Button>
					}
					loading={this.state.redeeming}
					value={this.state.couponCode}
					onChange={(e) => {
						this.setState({
							couponCode: e.target.value,
							isRedeemDisabled: e.target.value ? e.target.value.length <= 0 : true
						});
					}}
					onSearch={(value) => {
						if (this.state.redeeming) {
							return;
						}
						if (value.length > 0) {
							this.setState({ redeeming: true });
							redeem(value).then(() =>
								this.setState({ couponCode: undefined, redeeming: false, isRedeemDisabled: true })
							);
						}
					}}
					style={{ width: '250px', marginBottom: '16px' }}
				/>
			</Card>
		);
	}
}

WalletComp.propTypes = {
	balance: PropTypes.number,
	recharge: PropTypes.func.isRequired,
	redeem: PropTypes.func.isRequired
};

export default WalletComp;
