import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Input, Modal, Typography } from 'antd';
import { Trash2 } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

const { Title, Text } = Typography;

class DeleteEventModal extends Component {
	state = {
		showModal: false,
		deletionInProgress: false,
		verificationInput: '',
		verificationError: null
	};

	openModal = () => {
		this.setState({
			showModal: true,
			verificationInput: '',
			verificationError: null
		});
	};

	closeModal = () => {
		this.setState({
			showModal: false,
			verificationInput: '',
			verificationError: null
		});
	};

	handleInputChange = (e) => {
		this.setState({
			verificationInput: e.target.value,
			verificationError: null
		});
	};

	validateEventName = () => {
		const { event } = this.props;
		const { verificationInput } = this.state;

		if (!verificationInput.trim()) {
			this.setState({
				verificationError: 'Please enter the event name to confirm deletion.'
			});
			return false;
		}

		if (verificationInput.trim().toLowerCase() !== event.name.trim().toLowerCase()) {
			this.setState({
				verificationError: "The event name you entered doesn't match. Please try again."
			});
			return false;
		}
		return true;
	};

	handleDelete = async () => {
		if (!this.validateEventName()) {
			return;
		}

		const { userId, event, deleteEvent, onDeleted } = this.props;

		this.setState({ deletionInProgress: true });

		try {
			await deleteEvent(event.id, userId);
			this.setState({
				showModal: false,
				deletionInProgress: false,
				verificationInput: '',
				verificationError: null
			});
			if (typeof onDeleted === 'function') {
				onDeleted();
			}
		} catch (error) {
			console.error('Error deleting event:', error);
			this.setState({
				deletionInProgress: false,
				verificationError: 'An error occurred while deleting the event. Please try again.'
			});
		}
	};

	renderTrigger = () => {
		const { type } = this.props;

		if (type === 'icon') {
			return (
				<Trash2
					style={{ height: '16px', width: '16px', cursor: 'pointer', marginLeft: '8px' }}
					onClick={this.openModal}
				/>
			);
		} else {
			return (
				<Button onClick={this.openModal} type="text">
					Delete Event
				</Button>
			);
		}
	};

	render() {
		const { event } = this.props;
		const { showModal, deletionInProgress, verificationInput, verificationError } = this.state;

		return (
			<>
				{this.renderTrigger()}
				<Modal
					title={
						<Title level={4} style={{ color: '#ff4d4f' }}>
							<ExclamationCircleOutlined style={{ marginRight: '8px' }} />
							Confirm Event Deletion
						</Title>
					}
					open={showModal}
					onCancel={this.closeModal}
					footer={[
						<Button key="back" onClick={this.closeModal}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							danger
							loading={deletionInProgress}
							onClick={this.handleDelete}>
							Delete Permanently
						</Button>
					]}>
					<Text strong>Are you sure you want to delete the event "{event.name}"?</Text>
					<Text>
						<p>
							This action will permanently delete all event data and photos. This cannot be undone.
						</p>
					</Text>
					<Text strong>
						<p>To confirm, please enter the event name below:</p>
					</Text>
					<Input
						placeholder="Enter event name"
						value={verificationInput}
						onChange={this.handleInputChange}
						status={verificationError ? 'error' : ''}
						style={{ marginBottom: '10px' }}
					/>
					{verificationError && (
						<Alert
							message={verificationError}
							type="error"
							showIcon
							style={{ marginBottom: '10px' }}
						/>
					)}
				</Modal>
			</>
		);
	}
}

DeleteEventModal.propTypes = {
	event: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	onDeleted: PropTypes.func,
	type: PropTypes.oneOf(['button', 'icon']).isRequired
};

export default DeleteEventModal;
