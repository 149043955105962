import {
  ACTION_UPLOAD_STARTED,
  ACTION_UPLOAD_FINISHED,
  ACTION_UPLOAD_PROGRESS_UPDATED,
  ACTION_UPLOAD_ERROR } from '../actions/action_types'

const upload = (state = {
  uploading : false,
  error: false,
  errorDetail : undefined,
  successMessage: undefined,
  pending: 0,
  total: 0
}, action) => {

  switch (action.type) {
    case ACTION_UPLOAD_STARTED:
      return {
        ...state,
        'uploading' : true,
        'error' : false,
        errorDetail : undefined,
        'total' : action.total,
        'pending' : action.total,
        'successMessage' : undefined
      }
    case ACTION_UPLOAD_FINISHED:
      return {
        ...state,
        'uploading' : false,
        'total' : 0,
        successMessage: action.successMessage
      }
    case ACTION_UPLOAD_PROGRESS_UPDATED:
      return {
        ...state,
        'pending' : action.pending,
        'successMessage' : undefined
      }
    case ACTION_UPLOAD_ERROR:
      return {
        ...state,
        'uploading' : false,
        'pending': 0,
        'total' : 0,
        'error' : true,
        'errorDetail' : action.error,
        'successMessage' : undefined
      }
    default:
      return state;
  }
}

export default upload
