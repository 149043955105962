import { Button, Form, message, Modal } from 'antd';
import React, { useEffect } from 'react';
import PhoneInput from './PhoneInput';

const PhoneNumberPopup = ({ visible, onSubmit }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		if (visible) {
			form.setFieldsValue({
				phone: { countryCode: '+91' }
			});
		}
	}, [visible, form]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				const { phone } = values;
				if (phone && phone.countryCode && phone.phoneNumber && phone.phoneNumber.length === 10) {
					onSubmit(`${phone.countryCode}${phone.phoneNumber}`);
				} else {
					message.error('Please enter a valid 10-digit mobile number with country code');
				}
			})
			.catch((info) => {
				console.log('Validate Failed:', info);
			});
	};

	return (
		<Modal
			visible={visible}
			title="Phone Number Update Required"
			closable={false}
			maskClosable={false}
			footer={[
				<Button key="submit" type="primary" onClick={handleSubmit}>
					Submit
				</Button>
			]}>
			<p>To better serve you and provide important updates, please enter your phone number:</p>
			<Form form={form} initialValues={{ phone: { countryCode: '+91' } }}>
				<Form.Item
					name="phone"
					rules={[
						{
							validator: (_, value) => {
								if (!value || !value.countryCode) {
									return Promise.reject('Please select a country code');
								}
								if (!value.phoneNumber || value.phoneNumber.length !== 10) {
									return Promise.reject('Please enter a valid 10-digit mobile number');
								}
								return Promise.resolve();
							}
						}
					]}>
					<PhoneInput />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default PhoneNumberPopup;
