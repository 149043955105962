export const isEventExpired = (expiresAt) => {
	// Parse the expiresAt string to a Date object
	const expiryDate = new Date(expiresAt);

	// Get the current date and time
	const now = new Date();

	// Compare the two dates
	const isExpired = expiryDate < now;

	// Log the comparison details
	console.log(`Event expiry check:`, {
		expiresAt,
		currentTime: now.toISOString(),
		isExpired
	});

	return isExpired;
};
