import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Badge } from 'antd';
var classNames = require('classnames');

class GuestUploads extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	render() {
		const {
			name,
			notableDetail,
			detail1,
			detail2,
			detail3,
			detail4,
			buttonText,
			serverId,
			buttonTapped,
			buttonClass
		} = this.props;

		var btnClass = classNames('pack-button', buttonClass);

		return (
			<>
				<div
					className="pack-items"
					style={
						this.state.isMobile
							? {
									width: '100%',
									margin: '10px 0px',
									padding: 0,
									flexDirection: 'column',
									alignItems: 'center',
									justifyContent: 'center'
							  }
							: { alignItems: 'center' }
					}>
					<h3>{name}</h3>

					{this.state.isMobile ? (
						<Badge count={notableDetail} style={{ backgroundColor: '#999' }} />
					) : (
						<Badge
							className="pack-badge"
							count={notableDetail}
							style={{ backgroundColor: '#999' }}
						/>
					)}

					{detail1 && <p>{detail1}</p>}
					{detail2 && <p>{detail2}</p>}
					{detail3 && <p>{detail3}</p>}
					{detail4 && <p>{detail4}</p>}

					{buttonText ? (
						<Button
							type="primary"
							className={btnClass}
							onClick={(e) => {
								buttonTapped(serverId);
							}}>
							{buttonText}
						</Button>
					) : null}
				</div>
				<div style={{ width: '100%' }}>
					<div style={{ height: '1px', width: '100%', backgroundColor: '#EEE' }} />
				</div>
			</>
		);
	}
}

GuestUploads.propTypes = {
	name: PropTypes.string.isRequired,
	notableDetail: PropTypes.string.isRequired,
	detail1: PropTypes.string.isRequired,
	detail2: PropTypes.string.isRequired,
	detail3: PropTypes.string.isRequired,
	detail4: PropTypes.string.isRequired,
	buttonText: PropTypes.string,
	serverId: PropTypes.object.isRequired,
	buttonTapped: PropTypes.func
};

export default GuestUploads;
