import { CheckCircleTwoTone } from '@ant-design/icons';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import Photo from '../pouchDB/models/Photo';
import '../styles/Gallery.sass';

// class PhotoComp extends Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			loading: false,
// 			imageURL: null
// 		};
// 		bindAll(this, ['loadImage', 'visibilityChanged']);
// 	}

// 	componentDidMount() {
// 		const { isThumbnail, eventThumbnail } = this.props;

// 		if (!isThumbnail && !eventThumbnail) {
// 			//For fullscreen load image directly
// 			this.visibilityChanged(true);
// 		}
// 	}

// 	visibilityChanged(isVisible) {
// 		if (isVisible && !this.state.loading && !this.state.imageURL) {
// 			const { photo, logoutNow, isThumbnail } = this.props;
// 			this.imageURL = undefined;
// 			this.setState({ loading: true });
// 			this.loadImage(Photo.getS3Key(photo), isThumbnail, logoutNow);
// 		}
// 	}

// 	UNSAFE_componentWillReceiveProps(newProps) {
// 		if (this.props.photo.s3FileName === newProps.photo.s3FileName) {
// 			return;
// 		}
// 		console.log('PhotoComp: Photo changed on same mounted Photo.');
// 		this.setState({ loading: true });
// 		this.loadImage(Photo.getS3Key(newProps.photo), newProps.isThumbnail, newProps.logoutNow);
// 	}

// 	loadImage(s3Key, isThumbnail, logoutNow) {
// 		Photo.getPhoto(s3Key, isThumbnail)
// 			.then((image) => {
// 				this.setState({ loading: false, imageURL: image });
// 			})
// 			.catch((errResponse) => {
// 				this.setState({ loading: false, imageURL: null });

// 				console.log(errResponse);
// 				if (errResponse && (errResponse.status === 401 || errResponse.status === 403)) {
// 					if (logoutNow) {
// 						logoutNow();
// 					}
// 				}
// 			});
// 	}

// 	render() {
// 		const { className, onClick, isThumbnail, isSelected, eventThumbnail } = this.props;
// 		let imageURL = this.state.imageURL;

// 		if (eventThumbnail) {
// 			return (
// 				<div
// 					className={'event-thumbnail ' + className}
// 					style={{ backgroundImage: 'url(' + imageURL + ')' }}
// 					onClick={onClick}
// 				/>
// 			);
// 		}

// 		if (isThumbnail) {
// 			return (
// 				<VisibilitySensor onChange={this.visibilityChanged} partialVisibility={true}>
// 					<div
// 						className={'thumbnail ' + className}
// 						style={{ backgroundImage: 'url(' + imageURL + ')' }}
// 						onClick={onClick}>
// 						{isSelected ? (
// 							<div style={{ float: 'right', margin: '8px' }}>
// 								<CheckCircleTwoTone twoToneColor="#6F4898" style={{ fontSize: '18px' }} />
// 							</div>
// 						) : null}
// 					</div>
// 				</VisibilitySensor>
// 			);
// 		}

// 		return (
// 			<Spin spinning={this.state.loading} style={{ maxHeight: '100%' }}>
// 				<div className="fullscreen-img carousel-img-wrap">
// 					<img className="carousel-img" src={imageURL} onClick={onClick} alt="" />
// 				</div>
// 			</Spin>
// 		);
// 	}
// }

// PhotoComp.propTypes = {
// 	onClick: PropTypes.func,
// 	photo: PropTypes.shape({
// 		event: PropTypes.string.isRequired,
// 		s3FileName: PropTypes.string.isRequired
// 	}).isRequired,
// 	isThumbnail: PropTypes.bool,
// 	eventThumbnail: PropTypes.bool,
// 	logoutNow: PropTypes.func
// };

// export default PhotoComp;

class PhotoComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			imageURL: null,
			error: null
		};
	}

	componentDidMount() {
		console.log('PhotoComp: Mounted', { photoId: this.props.photo.id });
		if (!this.props.isThumbnail && !this.props.eventThumbnail) {
			this.loadImage();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.photo.s3FileName !== prevProps.photo.s3FileName) {
			console.log('PhotoComp: Photo changed, reloading image', {
				prevPhotoId: prevProps.photo.id,
				newPhotoId: this.props.photo.id
			});
			this.loadImage();
		}
	}

	loadImage = () => {
		const { photo, isThumbnail, logoutNow } = this.props;
		console.log('PhotoComp: Loading image', { photoId: photo.id, isThumbnail });

		this.setState({ loading: true, error: null });

		Photo.getPhoto(Photo.getS3Key(photo), isThumbnail)
			.then((image) => {
				console.log('PhotoComp: Image loaded successfully', { photoId: photo.id });
				this.setState({ loading: false, imageURL: image });
			})
			.catch((error) => {
				console.error('PhotoComp: Error loading image', { photoId: photo.id, error });
				this.setState({ loading: false, error: error.message });
				if (error.status === 401 || error.status === 403) {
					logoutNow();
				}
			});
	};

	handleVisibilityChange = (isVisible) => {
		if (isVisible && !this.state.imageURL && !this.state.loading) {
			this.loadImage();
		}
	};

	render() {
		const { className, onClick, isThumbnail, isSelected, eventThumbnail } = this.props;
		const { loading, imageURL, error } = this.state;

		console.log('PhotoComp: Rendering', {
			photoId: this.props.photo.id,
			loading,
			hasImageURL: !!imageURL,
			error
		});

		const imageStyle = imageURL ? { backgroundImage: `url(${imageURL})` } : {};

		if (eventThumbnail) {
			return (
				<div className={`event-thumbnail ${className}`} style={imageStyle} onClick={onClick} />
			);
		}

		if (isThumbnail) {
			return (
				<VisibilitySensor onChange={this.handleVisibilityChange} partialVisibility={true}>
					<div className={`thumbnail ${className}`} style={imageStyle} onClick={onClick}>
						{isSelected && (
							<div style={{ float: 'right', margin: '8px' }}>
								<CheckCircleTwoTone twoToneColor="#6F4898" style={{ fontSize: '18px' }} />
							</div>
						)}
						{loading && <Spin />}
						{error && <div className="error-message">{error}</div>}
					</div>
				</VisibilitySensor>
			);
		}

		return (
			<Spin spinning={loading} style={{ maxHeight: '100%' }}>
				<div className="fullscreen-img carousel-img-wrap">
					{imageURL && <img className="carousel-img" src={imageURL} onClick={onClick} alt="" />}
					{error && <div className="error-message">{error}</div>}
				</div>
			</Spin>
		);
	}
}

PhotoComp.propTypes = {
	onClick: PropTypes.func,
	photo: PropTypes.shape({
		id: PropTypes.string.isRequired,
		event: PropTypes.string.isRequired,
		s3FileName: PropTypes.string.isRequired
	}).isRequired,
	isThumbnail: PropTypes.bool,
	eventThumbnail: PropTypes.bool,
	logoutNow: PropTypes.func
};

export default PhotoComp;
