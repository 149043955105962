import Event from '../pouchDB/models/Event'
import {photosPageSelected, updateMyEventsMAction, visibleEventUpdated} from './pouchDBActions'


export const updateSortByMAction = (newSortBy) => {
    return (dispatch, getState) => {

        let event = getState().pouchDB.visibleEvent
        let clickedAlbum = getState().pouchDB.clickedAlbum
        let photosCurrentPage = getState().pouchDB.currentPage
        let userId = getState().auth.userId
        let eventPage = getState().pouchDB.eventPage

        Event.updateEventSortBy(userId, event.id, newSortBy)
            .then(() => {

                //Update MyEvents
                dispatch(updateMyEventsMAction(eventPage, true))

                //Update Visible Event
                let updatedEvent = Object.assign({}, event)
                updatedEvent.sortBy = newSortBy
                dispatch(visibleEventUpdated(updatedEvent))
                dispatch(photosPageSelected(updatedEvent, clickedAlbum, photosCurrentPage))
            })
    }
}