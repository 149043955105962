import { swaggerClient } from '../../swagger';

class BillingDetails {
	static createBillingDetails = (userId, billingDetails) => {
		return swaggerClient()
			.apis['billing_details'].create_billing_details({
				userId,
				billingDetails
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static getBillingDetails = (userId) => {
		return swaggerClient()
			.apis['billing_details'].get_billing_details({ userId })
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static editBillingDetails = (userId, billingDetails) => {
		return swaggerClient()
			.apis['billing_details'].edit_billing_details({
				userId,
				billingDetails
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};
}

export default BillingDetails;
