import { swaggerClient } from '../../swagger';
import Photo from './Photo';

class Album {
	static fetchAlbumHome = (event, albumDocId) => {
		return swaggerClient()
			.apis.album.album_home({
				eventDocId: event.id,
				albumDocId: albumDocId || '',
				sortBy: event.sortBy,
				skip: '0',
				limit: Photo.photosPerPage().toString()
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static fetchGuestUploadAlbum = (event, consistent = true, pageNumber = 0) => {
		const photosPerPage = Photo.photosPerPage() || 0;
		return swaggerClient()
			.apis.photo.guest_upload_album({
				eventDocId: event.id,
				sortBy: event.sortBy,
				skip: (pageNumber * photosPerPage).toString(),
				limit: photosPerPage.toString() || 0,
				consistent: consistent
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static fetchAlbums = (eventDocId) => {
		return swaggerClient()
			.apis.album.album_list({
				eventDocId: eventDocId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static createAlbum = (userId, eventDocId, albumName) => {
		return swaggerClient()
			.apis.album.create_album({
				userId: userId,
				eventDocId: eventDocId,
				albumName: albumName
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static updateAlbumName(userId, albumDocId, newName) {
		return swaggerClient()
			.apis.album.edit_album_name({
				userId: userId,
				albumDocId: albumDocId || '',
				newName: newName
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static deleteAlbum(userId, albumDocId, eventDocId) {
		return swaggerClient().apis.album.delete_album({
			userId: userId,
			albumDocId: albumDocId || '',
			eventDocId: eventDocId
		});
	}

	static setCoverPhoto(userId, photoDocId) {
		return swaggerClient()
			.apis.album.set_album_cover({
				userId: userId,
				photoDocId: photoDocId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static fetchAlbumCounts = (eventDocId, includeEventCount, albumDocIds) => {
		return swaggerClient()
			.apis.photo.photo_count_by_album({
				eventDocId: eventDocId,
				includeEventCount: includeEventCount,
				albumDocIds: albumDocIds || ''
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};
}

export default Album;
