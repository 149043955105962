import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindAll } from 'lodash';
import { PictureOutlined } from '@ant-design/icons';
import { Radio, Button, Row, Col, Input, message } from 'antd';
import CircleTitle from './CircleTitle';
import Resize from '../helpers/Resize';

const RadioGroup = Radio.Group;
var debounce = require('lodash.debounce');

class WatermarkStyleComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			subTitle: '',
			image: undefined,
			textSelected: true,
			imageSelected: false,
			isMobile: window.screen.width <= 576
		};
		bindAll(this, ['handleWatermarkFile', 'getBase64', 'handleWindowResize']);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	UNSAFE_componentWillMount() {
		window.removeEventListener('resize', this.handleWindowResize);

		const { styleChanged } = this.props;
		this.debouncedUpdate = debounce(styleChanged, 500);

		const { image, watermark } = this.props;
		if (image) {
			this.setState({ image: image, imageSelected: true });
			return;
		}

		if (watermark.title) {
			this.setState({ title: watermark.title, subTitle: watermark.subTitle, textSelected: true });
			return;
		}
	}

	handleWindowResize() {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	}

	getBase64(file) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	}

	async handleWatermarkFile(e) {
		e.preventDefault();

		if (e.target.files.length === 0) {
			return;
		}

		try {
			const resize = new Resize(false, 0.8);
			const result = await resize.fileToResizedImagePromise(e.target.files[0], 480);
			const base64Image = result.imageCanvas.toDataURL('image/png', 0.85);
			this.setState(
				{ textSelected: false, imageSelected: true, title: '', subTitle: '', image: base64Image },
				() => {
					this.debouncedUpdate(this.state);
				}
			);
		} catch (e) {
			message.error('Failed to read image');
		}
		e.target.value = null;
	}

	render() {
		if (this.state.isMobile) {
			return (
				<div style={{ backgroundColor: '#F5F5F5', padding: '10px' }}>
					<Radio.Group
						onChange={(e) => {
							if (e.target.value === 'image') {
								this.setState(
									{ textSelected: false, imageSelected: true, title: '', subTitle: '' },
									() => {
										this.debouncedUpdate(this.state);
									}
								);
							} else {
								this.setState(
									{ textSelected: true, imageSelected: false, image: undefined },
									() => {
										this.debouncedUpdate(this.state);
									}
								);
							}
						}}
						value={
							this.state.imageSelected ? 'image' : this.state.textSelected ? 'text' : undefined
						}>
						<Radio value="text">Text Watermark</Radio>
						<Radio value="image">Image or Logo</Radio>
					</Radio.Group>
					{this.state.textSelected ? (
						<div style={{ marginTop: '16px' }}>
							<Row style={{ margin: '10px 0px' }}>
								<Input
									type="text"
									placeholder="Title"
									value={this.state.title}
									onChange={(e) => {
										this.setState(
											{
												textSelected: true,
												imageSelected: false,
												title: e.target.value,
												image: undefined
											},
											() => {
												this.debouncedUpdate(this.state);
											}
										);
									}}
								/>
							</Row>
							<Row style={{ margin: '10px 0px' }}>
								<Input
									type="text"
									placeholder="SubTitle (Optional)"
									value={this.state.subTitle}
									onChange={(e) => {
										this.setState(
											{
												textSelected: true,
												imageSelected: false,
												subTitle: e.target.value,
												image: undefined
											},
											() => {
												this.debouncedUpdate(this.state);
											}
										);
									}}
								/>
							</Row>
						</div>
					) : (
						<Row type="flex" justify="center" align="middle" style={{ marginTop: '16px' }}>
							<Col>
								{this.state.image ? (
									<img className="watermark-image-selected" src={this.state.image} alt="" />
								) : (
									<PictureOutlined className="watermark-image-placeholder-mobile" />
								)}
							</Col>
							<Col>
								<input
									type="file"
									id="watermarkUploadInputTypeFile"
									onChange={this.handleWatermarkFile}
									accept="image/*"
									style={{ display: 'none' }}
								/>
								<Button
									onClick={(e) => {
										e.preventDefault();
										e.preventDefault();
										document.getElementById('watermarkUploadInputTypeFile').click();
										document.activeElement.blur();
									}}>
									Add Image
								</Button>
							</Col>
						</Row>
					)}
				</div>
			);
		}

		return (
			<div style={this.props.style}>
				<Row style={{ marginBottom: '10px' }}>
					<Col span={24}>
						<CircleTitle title="WATERMARK STYLE" />
					</Col>
				</Row>
				<Row>
					<RadioGroup
						style={{ width: '100%' }}
						value={
							this.state.imageSelected ? 'image' : this.state.textSelected ? 'text' : undefined
						}
						onChange={(e) => {
							if (e.target.value === 'image') {
								this.setState(
									{ textSelected: false, imageSelected: true, title: '', subTitle: '' },
									() => {
										this.debouncedUpdate(this.state);
									}
								);
							} else {
								this.setState(
									{ textSelected: true, imageSelected: false, image: undefined },
									() => {
										this.debouncedUpdate(this.state);
									}
								);
							}
						}}>
						<Row style={{ marginBottom: '5px' }}>
							<Radio className="setwatermark-radio" value={'text'}>
								Text Watermark
							</Radio>
						</Row>
						<Row style={{ marginBottom: '3px' }}>
							<Input
								type="text"
								placeholder="Title"
								value={this.state.title}
								onChange={(e) => {
									this.setState(
										{
											textSelected: true,
											imageSelected: false,
											title: e.target.value,
											image: undefined
										},
										() => {
											this.debouncedUpdate(this.state);
										}
									);
								}}
							/>
						</Row>
						<Row style={{ marginBottom: '20px' }}>
							<Input
								type="text"
								placeholder="SubTitle (Optional)"
								value={this.state.subTitle}
								onChange={(e) => {
									this.setState(
										{
											textSelected: true,
											imageSelected: false,
											subTitle: e.target.value,
											image: undefined
										},
										() => {
											this.debouncedUpdate(this.state);
										}
									);
								}}
							/>
						</Row>
						<Row>
							<Radio className="setwatermark-radio" value={'image'}>
								Custom Image Watermark
							</Radio>
						</Row>
						<Row style={{ height: '100px' }} type="flex" justify="space-around" align="middle">
							<Col>
								{this.state.image ? (
									<img className="watermark-image-selected" src={this.state.image} alt=""></img>
								) : (
									<PictureOutlined className="watermark-image-placeholder" />
								)}
							</Col>
							<Col>
								<input
									type="file"
									id="watermarkUploadInputTypeFile"
									onChange={this.handleWatermarkFile}
									accept="image/*"
									style={{ display: 'none' }}
								/>

								<Button
									onClick={(e) => {
										e.preventDefault();
										e.preventDefault();
										document.getElementById('watermarkUploadInputTypeFile').click();
										document.activeElement.blur();
									}}>
									Add Image
								</Button>
							</Col>
						</Row>
					</RadioGroup>
				</Row>
			</div>
		);
	}
}

WatermarkStyleComp.propTypes = {
	styleChanged: PropTypes.func.isRequired //args image, title, subTitle
};

export default WatermarkStyleComp;
