import { message, Modal } from 'antd';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';
import { refreshVisibleEvent } from '../actions/pouchDBActions';
import Event from '../pouchDB/models/Event';
import { PRODUCT_TYPE } from '../pouchDB/models/Product';
import Wallet from '../pouchDB/models/Wallet';
import BuySingleProduct from './BuySingleProduct';

class UpgradeEvent extends Component {
	constructor(props) {
		super(props);
		this.state = { wallet: undefined };
	}

	componentDidMount() {
		this.getBalance().then();
	}

	getBalance = async () => {
		const { userId, fetchBalance } = this.props;
		try {
			let wallet = await fetchBalance(userId);
			this.setState({ wallet: wallet });
		} catch (err) {
			message.error(err.message);
		}
	};

	render() {
		const { onComplete, eventId, updateEvent, facialUpgrade } = this.props;
		var productType;
		var title;
		if (facialUpgrade) {
			productType = PRODUCT_TYPE.facial_support;
			title = 'Choose Facial Support for this Event';
		} else {
			productType = PRODUCT_TYPE.photos_pack;
			title = 'Choose Number of Photos to Add to this Event';
		}
		return (
			<Modal title={title} open footer={null} onCancel={() => onComplete(false)}>
				<BuySingleProduct
					eventId={eventId}
					productType={productType}
					purchaseNeedsUpdate={async () => {
						await updateEvent(eventId);
						onComplete();
					}}
					walletBalance={this.state.wallet ? this.state.wallet.balance : undefined}
				/>
			</Modal>
		);
	}
}

UpgradeEvent.propTypes = {
	userId: PropTypes.string.isRequired,
	eventId: PropTypes.string.isRequired,
	onComplete: PropTypes.func.isRequired,
	updateEvent: PropTypes.func.isRequired,
	facialUpgrade: PropTypes.bool.isRequired,
	fetchBalance: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		userId: state.auth.userId
	};
}

const mapDispatchToProps = (dispatch) => ({
	updateEvent: async (eventId) => {
		try {
			let event = await Event.fetchEvent(eventId);
			await dispatch(refreshVisibleEvent(event));
			message.success('Your order is now processed!');
		} catch (err) {
			message.error(err.message);
		}
	},
	fetchBalance: async (userId) => {
		return await Wallet.fetchBalance(userId);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeEvent);
