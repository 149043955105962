import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Input } from 'antd';
import { bindAll } from 'lodash';

export const VIDEO_LINK_NAME_LENGTH = 20;

class AddVideoLinkComp extends Component {
	constructor(props) {
		super(props);
		this.state = { name: '', link: '', isMobile: window.screen.width <= 576 };
		bindAll(this, ['handleNameChange', 'handleLinkChange', 'handleWindowResize']);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	handleNameChange = (e) => {
		this.setState({ name: e.target.value });
	};

	handleLinkChange = (e) => {
		this.setState({ link: e.target.value });
	};

	render() {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div style={{ marginRight: '10px' }}>
					<Input
						style={{ fontSize: '16px' }}
						type="text"
						value={this.state.name}
						placeholder="Video Link Name"
						onChange={this.handleNameChange}
						maxLength={VIDEO_LINK_NAME_LENGTH}
					/>
					<Input
						style={{ fontSize: '16px', marginTop: '10px' }}
						type="text"
						value={this.state.link}
						placeholder="Video Link"
						onChange={this.handleLinkChange}
					/>
				</div>
				<Button
					style={{ height: 'auto', alignSelf: 'stretch' }}
					disabled={this.state.name.length <= 0 || this.state.link.length <= 0}
					onClick={(e) => {
						e.preventDefault();
						if (this.state.name.length <= 0 || this.state.link.length <= 0) {
							return;
						}
						this.props.addVideoLink(this.state.name, this.state.link);
						this.setState({ name: '', link: '' });
					}}>
					Add
				</Button>
			</div>

			// <Row justify="space-between" align="middle" type="flex">
			// 	<Col span={this.state.isMobile ? 20 : 17}>
			// 		<Row style={{ marginBottom: '2px' }}>
			// 			<Input
			// 				style={{ fontSize: '16px' }}
			// 				type="text"
			// 				value={this.state.name}
			// 				placeholder="Video Link Name"
			// 				onChange={this.handleNameChange}
			// 				maxLength={VIDEO_LINK_NAME_LENGTH}
			// 			/>
			// 		</Row>
			// 		<Row>
			// 			<Input
			// 				style={{ fontSize: '16px' }}
			// 				type="text"
			// 				value={this.state.link}
			// 				placeholder="Video Link"
			// 				onChange={this.handleLinkChange}
			// 			/>
			// 		</Row>
			// 	</Col>
			// 	<Col>
			// 		<Button
			// 			style={{ height: '66px' }}
			// 			disabled={this.state.name.length <= 0 || this.state.link.length <= 0}
			// 			onClick={(e) => {
			// 				e.preventDefault();
			// 				if (this.state.name.length <= 0 || this.state.link.length <= 0) {
			// 					return;
			// 				}
			// 				this.props.addVideoLink(this.state.name, this.state.link);
			// 				this.setState({ name: '', link: '' });
			// 			}}>
			// 			Add
			// 		</Button>
			// 	</Col>
			// </Row>
		);
	}
}

AddVideoLinkComp.propTypes = {
	addVideoLink: PropTypes.func.isRequired
};

export default AddVideoLinkComp;
