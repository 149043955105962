import { Button, Checkbox, Col, Modal, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import RazorPayWrapper from '../containers/RazorPayWrapper';
import { calculateFaceDetectionPrice } from '../helpers/calculateFaceDetectionPrice';
import Order from '../pouchDB/models/Order';
import Wallet from '../pouchDB/models/Wallet';

const FACE_VALIDITY_MONTHS = 12;
const TILL_EXPIRY_RENEW_DISCOUNT = 0.5; // 50% discount

class RenewFaceModal extends Component {
	state = {
		showModal: false,
		paymentInProgress: false,
		checkoutInProgress: false,
		order: undefined,
		shouldUseWallet: true,
		walletBalance: null
	};

	componentDidMount() {
		this.fetchWalletBalance();
	}

	fetchWalletBalance = async () => {
		const { userId, fetchBalance } = this.props;
		try {
			const wallet = await fetchBalance(userId);
			this.setState({ walletBalance: wallet.balance / 100 });
		} catch (err) {
			console.error(err);
		}
	};

	openModal = () => {
		this.setState({ showModal: true });
	};

	closeModal = () => {
		this.setState({ showModal: false });
	};

	placeOrder = async () => {
		const { userId, event } = this.props;
		const { shouldUseWallet } = this.state;

		try {
			this.setState({ paymentInProgress: true });
			let order = await Order.placeFaceOrder(
				userId,
				shouldUseWallet,
				event.id,
				FACE_VALIDITY_MONTHS
			);
			if (order.razorPayOrderId) {
				this.setState({ checkoutInProgress: true, order: order });
			} else {
				await this.confirmOrder(order.id, undefined, undefined);
			}
		} catch (err) {
			console.error(err);
			this.setState({ paymentInProgress: false });
		}
	};

	confirmOrder = async (orderId, paymentId, signature) => {
		const { userId, event, onRenewed } = this.props;
		try {
			await Order.confirmOrder(userId, orderId, paymentId, signature, event.id);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false,
				showModal: false
			});
			onRenewed();
		} catch (err) {
			console.error(err);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false
			});
		}
	};

	checkout(order) {
		const { email, mobile } = this.props;

		let props = {
			email: email,
			mobile: mobile,
			amount: order.amount,
			orderId: order.razorPayOrderId,
			paymentAuthorized: (response) => {
				this.confirmOrder(order.id, response.razorpay_payment_id, response.razorpay_signature);
			}
		};
		return <RazorPayWrapper {...props} />;
	}

	render() {
		const { event, showAsLink = false } = this.props;
		const {
			showModal,
			paymentInProgress,
			checkoutInProgress,
			order,
			shouldUseWallet,
			walletBalance
		} = this.state;

		if (walletBalance === null) {
			return <div>Loading...</div>;
		}

		const maxPhotos = event.maxPhotos;
		const baseCost = calculateFaceDetectionPrice(maxPhotos);
		const isRenewal = event.facesEnabledAt !== null;
		const currentDate = new Date();
		const expiryDate = event.facesExpiresAt ? new Date(event.facesExpiresAt) : null;

		let totalCost = baseCost;
		if (isRenewal && expiryDate && expiryDate > currentDate) {
			totalCost = baseCost * TILL_EXPIRY_RENEW_DISCOUNT;
		}

		let payableAmount = totalCost;
		let creditsUsed = 0;
		if (shouldUseWallet && walletBalance > 0) {
			if (totalCost - walletBalance > 0) {
				payableAmount = totalCost - walletBalance;
				creditsUsed = walletBalance;
			} else {
				payableAmount = 0;
				creditsUsed = totalCost;
			}
		}

		const gstAmount = payableAmount > 0 ? payableAmount * 0.18 : 0;
		const grandTotal = payableAmount + gstAmount;

		const newExpiryDate = new Date(Math.max(currentDate, expiryDate || currentDate));
		newExpiryDate.setMonth(newExpiryDate.getMonth() + FACE_VALIDITY_MONTHS);

		const triggerElement = showAsLink ? (
			<a onClick={this.openModal}>Renew</a>
		) : (
			<Button type="primary" onClick={this.openModal}>
				Renew
			</Button>
		);

		return (
			<>
				{triggerElement}

				<Modal
					title={`Extend Face Recognition for Event: ${event.name}`}
					open={showModal}
					onCancel={this.closeModal}
					footer={[
						<Button key="back" onClick={this.closeModal}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={paymentInProgress}
							onClick={this.placeOrder}>
							{payableAmount === 0 ? 'Renew' : `Proceed`}
						</Button>
					]}>
					<Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									backgroundColor: '#f0f2f5',
									padding: '16px',
									borderRadius: '8px'
								}}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<Checkbox
										style={{ marginRight: '8px' }}
										checked={shouldUseWallet}
										onChange={(e) => {
											this.setState({ shouldUseWallet: e.target.checked });
										}}
									/>
									<span style={{ fontSize: '16px', fontWeight: 500 }}>Use Credits</span>
								</div>
								<span style={{ fontSize: '16px', fontWeight: 500 }}>
									{walletBalance.toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</span>
							</div>
						</Col>

						<Col span={24}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									backgroundColor: '#f0f2f5',
									padding: '16px',
									borderRadius: '8px'
								}}>
								<Row gutter={[16, 16]}>
									<Col span={12}>Event Photos:</Col>
									<Col span={12}>{event.maxPhotos}</Col>

									{isRenewal && expiryDate && (
										<>
											<Col span={12}>Current Expiry Date:</Col>
											<Col span={12}>{expiryDate.toLocaleDateString()}</Col>
										</>
									)}

									<Col span={12}>Validity:</Col>
									<Col span={12}>{FACE_VALIDITY_MONTHS} Months</Col>

									<Col span={12}>New Expiry Date:</Col>
									<Col span={12}>{newExpiryDate.toLocaleDateString()}</Col>

									<Col span={12}>Renewal Cost:</Col>
									<Col span={12}>
										{totalCost.toLocaleString('en-IN', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
											style: 'currency',
											currency: 'INR'
										})}
										{isRenewal && expiryDate && expiryDate > currentDate && (
											<span style={{ marginLeft: '5px', color: 'green' }}>(50% off)</span>
										)}
									</Col>

									{walletBalance > 0 && shouldUseWallet && (
										<>
											<Col span={12}>Credits Used:</Col>
											<Col span={12}>
												{creditsUsed.toLocaleString('en-IN', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
													style: 'currency',
													currency: 'INR'
												})}
											</Col>
										</>
									)}

									{gstAmount > 0 && (
										<>
											<Col span={12}>GST (18%):</Col>
											<Col span={12}>
												{gstAmount.toLocaleString('en-IN', {
													minimumFractionDigits: 2,
													maximumFractionDigits: 2,
													style: 'currency',
													currency: 'INR'
												})}
											</Col>
										</>
									)}

									<Col span={12}>
										<span style={{ fontSize: '16px', fontWeight: 500 }}>Total:</span>
									</Col>
									<Col span={12}>
										<span style={{ fontSize: '16px', fontWeight: 500 }}>
											{grandTotal.toLocaleString('en-IN', {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
												style: 'currency',
												currency: 'INR'
											})}
										</span>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
				</Modal>
				{checkoutInProgress && order && this.checkout(order)}
			</>
		);
	}
}

RenewFaceModal.propTypes = {
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	event: PropTypes.object.isRequired,
	onRenewed: PropTypes.func.isRequired,
	fetchBalance: PropTypes.func.isRequired,
	showAsLink: PropTypes.bool
};

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	email: state.auth.email,
	mobile: state.auth.mobile
});

const mapDispatchToProps = (dispatch) => ({
	fetchBalance: async (userId) => {
		return await Wallet.fetchBalance(userId);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(RenewFaceModal);
