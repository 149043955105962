import { ClockCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Button, Space, Typography } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import RenewEventModal from './RenewEventModal';
import RenewalLinkModal from './RenewalLinkModal';

const { Text } = Typography;

const ExpiryNoticeBanner = ({ event, daysLeft, onRenewed }) => {
	const isExpired = daysLeft <= 0;
	const message = isExpired
		? 'This event has expired.'
		: `Event Expires in ${daysLeft} day${daysLeft === 1 ? '' : 's'}`;

	return (
		<>
			<Alert
				type="error"
				showIcon
				icon={<WarningOutlined style={{ fontSize: '22px' }} />}
				message={
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							flexWrap: 'wrap',
							alignContent: 'center'
						}}>
						<Space align="center" size="middle">
							<Text strong style={{ fontSize: '16px' }}>
								{isExpired ? 'Event Expired' : 'Attention Required'}
							</Text>
						</Space>
						<Space align="center" size="middle">
							<Text style={{ display: 'flex', alignItems: 'center' }}>
								<ClockCircleOutlined style={{ marginRight: '8px' }} />
								{message}
							</Text>
						</Space>
						<Space wrap>
							<RenewEventModal event={event} onRenewed={onRenewed}>
								<Button type="primary" ghost>
									Renew Event
								</Button>
							</RenewEventModal>
							<RenewalLinkModal event={event}>
								<Button ghost>Share Renewal Link</Button>
							</RenewalLinkModal>
						</Space>
					</div>
				}
			/>
		</>
	);
};

ExpiryNoticeBanner.propTypes = {
	event: PropTypes.object.isRequired,
	daysLeft: PropTypes.number.isRequired,
	onRenewed: PropTypes.func.isRequired
};

export default ExpiryNoticeBanner;
