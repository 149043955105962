import { swaggerClient } from '../../swagger';
import { getThumbnailURL } from '../../helpers/VideoLinkThumbnail';

class Post {
	static fetchPosts = (userId) => {
		return swaggerClient()
			.apis.post.post_list({
				userId: userId,
				skip: '0',
				limit: '500'
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static createPost = (userId, text, notificationText, link, brand, postType, selectedEvent) => {
		return getThumbnailURL(link)
			.then((videoLinkThumbnailResult) => {
				return swaggerClient().apis.post.create_post({
					userId: userId,
					post: {
						text: text,
						notificationText: notificationText,
						link: link,
						brand: brand,
						postType: postType,
						selectedEvent: selectedEvent,
						thumbnailURL: videoLinkThumbnailResult.url,
						videoSource: videoLinkThumbnailResult.source
					}
				});
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static updatePost(
		postDocId,
		userId,
		text,
		notificationText,
		link,
		brand,
		postType,
		selectedEvent
	) {
		return getThumbnailURL(link)
			.then((videoLinkThumbnailResult) => {
				return swaggerClient().apis.post.edit_post({
					userId: userId,
					postDocId: postDocId,
					post: {
						text: text,
						notificationText: notificationText,
						link: link,
						brand: brand,
						postType: postType,
						selectedEvent: selectedEvent,
						thumbnailURL: videoLinkThumbnailResult.url,
						videoSource: videoLinkThumbnailResult.source
					}
				});
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	}

	static deletePost(userId, postDocId) {
		return swaggerClient().apis.post.delete_post({
			userId: userId,
			postDocId: postDocId
		});
	}
}

export default Post;
