import React, { Component } from 'react';
import '../styles/Login.sass';
import { ReactComponent as LoginKameroSVG } from '../svg/kamero_only_icon.svg';
import { Card } from 'antd';

class BrowserNotSupported extends Component {
	render() {
		return (
			<div className="flexCenterPageContainer login-bg">
				<div className="flexCenterPageChild">
					<LoginKameroSVG className="loginKameroIcon" />
					<p className="kamero-brand-text">Kamero</p>
					<h2 className="login-sharing-text">Ai Powered Photo Sharing For Events</h2>
					<Card className="login-card" title={<h3>Browser not supported!</h3>} bordered={false}>
						<p>
							This page is supported only by Google Chrome, Mozilla Firefox and Safari browsers for
							desktop.
						</p>
					</Card>
				</div>
			</div>
		);
	}
}

export default BrowserNotSupported;
