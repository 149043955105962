import { connect } from 'react-redux';
import EventListComp from '../components/EventListComp';
import {
	eventClicked,
	editEventMAction,
	updateMyEventsMAction,
	setEventIdMAction,
	deleteEventMAction,
	searchEventMAction,
	clearSearchedEvents,
	editEventExpiryMAction
} from '../actions/pouchDBActions';
import { push } from 'connected-react-router';

const mapStateToProps = (state) => ({
	userId: state.auth.userId,
	searchedEvents: state.pouchDB.searchedEvents,
	events: state.pouchDB.myEvents,
	replicationState: state.replication.state,
	currentPage: state.pouchDB.eventPage,
	totalEvents: state.pouchDB.totalEvents,
	whitelabelId: state.auth.whitelabelId
});

const mapDispatchToProps = (dispatch) => ({
	onEventClick: (event) => {
		if (event.state !== 'created') {
			return;
		}
		dispatch(eventClicked(event));
	},
	createNewEvent: () => {
		dispatch(push('/events/create'));
	},
	eventNameEdited: (index, userId, event, newEventName) => {
		dispatch(editEventMAction(index, userId, event, newEventName));
	},
	clearSearchedEvents: () => {
		dispatch(clearSearchedEvents());
	},
	searchEvents: (userId, searchText) => {
		if (searchText && searchText.length > 0) {
			return dispatch(searchEventMAction(userId, searchText));
		}
		return Promise.resolve();
	},
	setEventId: (index, userId, event, newEventId) => {
		dispatch(setEventIdMAction(index, userId, event, newEventId));
	},
	deleteEvent: (eventId, userId) => {
		dispatch(deleteEventMAction(eventId, userId));
	},

	editEventExpiry: async (index, userId, event, newExpiry) => {
		return dispatch(await editEventExpiryMAction(index, userId, event, newExpiry));
	},
	refreshEvents: (page) => {
		return dispatch(updateMyEventsMAction(page, true));
	},
	goToPage: (pageNumber) => {
		return dispatch(updateMyEventsMAction(pageNumber, false));
	}
});

const MyEvents = connect(mapStateToProps, mapDispatchToProps)(EventListComp);

export default MyEvents;
