import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Badge, Card } from 'antd';
var classNames = require('classnames');

class PackComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	render() {
		const {
			name,
			notableDetail,
			detail1,
			detail2,
			detail3,
			detail4,
			buttonText,
			serverId,
			buttonTapped,
			buttonClass
		} = this.props;
		var btnClass = classNames('pack-button', buttonClass);

		if (this.state.isMobile) {
			return (
				<div
					style={{
						border: '1px solid #4C4C4C',
						borderRadius: '10px',
						padding: '0px 0px',
						display: 'flex',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'space-between',
						marginBottom: '10px'
					}}>
					<h3 style={{ marginLeft: '10px' }}>{name}</h3>
					<Badge className="pack-badge" count={notableDetail} style={{ backgroundColor: '#999' }} />
				</div>
			);
		}

		return (
			<>
				<Card
					bordered={false}
					style={{
						alignItems: 'center',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						alignContent: 'center',
						textAlign: 'center'
					}}>
					<h3>{name}</h3>
					<Badge
						className="pack-badge"
						count={notableDetail}
						style={{ backgroundColor: '#999', alignContent: 'center', textAlign: 'center' }}
					/>
					<p>{detail1}</p>
				</Card>
				{/* <div className="pack">
					<h3>{name}</h3>
					<Badge className="pack-badge" count={notableDetail} style={{ backgroundColor: '#999' }} />
					<p>{detail1}</p>
					<p>{detail2}</p>
					<p>{detail3}</p>
					<p>{detail4}</p>

					{buttonText ? (
						<Button
							type="primary"
							className={btnClass}
							onClick={(e) => {
								buttonTapped(serverId);
							}}>
							{buttonText}
						</Button>
					) : null}
				</div>
				<p style={{ marginBottom: '12px' }}></p> */}
			</>
		);
	}
}

PackComp.propTypes = {
	name: PropTypes.string.isRequired,
	notableDetail: PropTypes.string.isRequired,
	detail1: PropTypes.string.isRequired,
	detail2: PropTypes.string.isRequired,
	detail3: PropTypes.string.isRequired,
	detail4: PropTypes.string.isRequired,
	buttonText: PropTypes.string,
	serverId: PropTypes.object.isRequired,
	buttonTapped: PropTypes.func
};

export default PackComp;
