import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import NameEditDeleteItemComp from './NameEditDeleteItemComp';
import AddVideoLinkComp from './AddVideoLinkComp';
import { VIDEO_LINK_NAME_LENGTH } from './AddVideoLinkComp';
import { Menu, Modal, Typography, Button, message, Progress } from 'antd';
const { Title } = Typography;
import CircleTitle from './CircleTitle';
import { UploadOutlined } from '@ant-design/icons';
const MenuItemGroup = Menu.ItemGroup;
import '../styles/profile.sass';
import cameraImage from '../svg/camera.svg';
import { Youtube } from 'lucide-react';
const ThumbnailModal = ({
	open,
	setOpen,
	ThumbnailUploadClick,
	handleThumbnailChange,
	ThumbnailPhotInputRef,
	clickedLink,
	setClickedLink,
	videoLinkThumbnailEdited,
	visibleEvent,
	openNotificationWithIcon,
	setOpenPreviewModal,
	uploadProgress
}) => (
	<Modal
		width={580}
		open={open}
		onCancel={() => {
			setOpen(false);
			setClickedLink({});
		}}
		footer="">
		<div>
			<Title level={4}>
				<h5>Upload Thumbnail image for your video</h5>
			</Title>
			<div className="uploadRow">
				<UploadOutlined
					className="uploadLogo"
					style={{ cursor: 'pointer' }}
					onClick={ThumbnailUploadClick}
				/>
				<input
					type="file"
					ref={ThumbnailPhotInputRef}
					style={{ display: 'none' }}
					onChange={handleThumbnailChange}
				/>
				<h4 style={{ color: '#52525B', fontWeight: 600, marginBottom: '2px' }}>Upload Photo</h4>
			</div>
			{uploadProgress > 0 && <Progress percent={uploadProgress} strokeColor="#6F4898" />}

			<div className="coverImagePreview" style={{ backgroundColor: '#D8D8D8' }}>
				{clickedLink.thumbnailURL && (
					<>
						<img src={clickedLink.thumbnailURL ? clickedLink.thumbnailURL : ''} />

						<img
							src={cameraImage}
							style={{
								width: '28px',
								height: '28px',
								position: 'absolute',
								right: '-10px',
								bottom: '-10px',
								cursor: 'pointer'
							}}
							onClick={() => setOpenPreviewModal(true)}
						/>
					</>
				)}
			</div>
			<Button
				type="primary"
				style={{ marginTop: '10px' }}
				disabled={clickedLink.thumbnailURL ? false : true}
				onClick={() => {
					videoLinkThumbnailEdited(
						clickedLink.index,
						visibleEvent,
						clickedLink.name,
						clickedLink,
						clickedLink.thumbnailURL,
						openNotificationWithIcon
					);
					setOpen(false);
				}}>
				Save
			</Button>
		</div>
	</Modal>
);

const PreviewModal = ({ open, setOpen, clickedLink }) => (
	<Modal
		width={580}
		open={open}
		onCancel={() => {
			setOpen(false);
		}}
		footer="">
		<div>
			<Title level={4}>
				<h5>Thumbnail Image</h5>
			</Title>

			<div style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}>
				<img
					src={clickedLink.thumbnailURL ? clickedLink.thumbnailURL : ''}
					style={{ width: '500px', height: '300px', objectFit: 'fill' }}
				/>
			</div>
		</div>
	</Modal>
);

const VideoListComp = ({
	visibleEvent,
	className,
	allReduxState,
	videoLinks,
	onVideoClick,
	createVideoLink,
	videoLinkNameEdited,
	deleteVideoLink,
	videoLinkThumbnailEdited,
	uploadThumbnailImage,
	deletingVideoLinks
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [openPreviewModal, setOpenPreviewModal] = useState(false);
	const [clickedLink, setClickedLink] = useState({});
	const [uploadProgress, setUploadProgress] = useState(0);
	const [isMobile, setIsMobile] = useState(window.screen.width <= 576);
	const uploadProgressRef = useRef(uploadProgress);
	const ThumbnailPhotInputRef = useRef(null);
	//console.log(uploadProgress);

	const ThumbnailUploadClick = () => {
		ThumbnailPhotInputRef.current.click();
	};

	const handleThumbnailChange = (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		//const formData = new FormData();
		formData.append('file', file);
		if (file && file.type.startsWith('image/')) {
			// To check image is landscape or portrait. Only allow landscape images
			const img = new Image();
			img.onload = function () {
				const width = img.naturalWidth;
				const height = img.naturalHeight;
				if (width > height) {
					// Call your upload API here, passing the image file
					uploadThumbnailImage(file, clickedLink, openNotificationWithIcon);
					if (uploadProgress < 90) {
						const interval = setInterval(() => {
							if (uploadProgressRef.current >= 90) {
								console.log('clear interval');
								clearInterval(interval);
							} else {
								uploadProgressRef.current += 5;
								setUploadProgress(uploadProgressRef.current);
							}
						}, 300);
					}
				} else {
					openNotificationWithIcon('error', 'Please upload a Horizontal (Landscape) image');
				}
			};
			img.src = URL.createObjectURL(file);
		} else {
			openNotificationWithIcon('error', 'Invalid file format. Please select an image file.');
			console.log('Invalid file format. Please select an image file.');
		}
	};

	const openNotificationWithIcon = (type, text, funcName) => {
		if (type == 'success') {
			message.success(text);
			if (funcName == 'upload') {
				uploadProgressRef.current = 100;
				setUploadProgress(uploadProgressRef.current);
				setTimeout(() => {
					uploadProgressRef.current = 0;
					setUploadProgress(uploadProgressRef.current);
				}, 500);
			}
		} else if (type == 'error') {
			message.error(text);
			if (funcName == 'upload') {
				uploadProgressRef.current = 99;
				setUploadProgress(uploadProgressRef.current);
				setTimeout(() => {
					uploadProgressRef.current = 0;
					setUploadProgress(uploadProgressRef.current);
				}, 500);
			}
		}
	};

	const handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !isMobile) setIsMobile(true);
		else if (window.innerWidth > 576 && isMobile) setIsMobile(false);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	return (
		<>
			<ThumbnailModal
				open={openModal}
				setOpen={setOpenModal}
				ThumbnailUploadClick={ThumbnailUploadClick}
				handleThumbnailChange={handleThumbnailChange}
				ThumbnailPhotInputRef={ThumbnailPhotInputRef}
				clickedLink={clickedLink}
				setClickedLink={setClickedLink}
				videoLinkThumbnailEdited={videoLinkThumbnailEdited}
				visibleEvent={visibleEvent}
				uploadThumbnailImage={uploadThumbnailImage}
				openNotificationWithIcon={openNotificationWithIcon}
				setOpenPreviewModal={setOpenPreviewModal}
				uploadProgress={uploadProgress}
			/>
			<PreviewModal
				open={openPreviewModal}
				setOpen={setOpenPreviewModal}
				clickedLink={clickedLink}
			/>
			<Menu className="left-menu" selectedKeys={[]}>
				{console.log(allReduxState)}
				<MenuItemGroup
					title={
						!isMobile && (
							<div className="event-home-menu-label">
								<Youtube color="#6F4898" />
								<h4 style={{ marginLeft: 10 }}>VIDEOS</h4>
							</div>
						)
					}>
					<AddVideoLinkComp
						addVideoLink={(name, link) => {
							createVideoLink(name, link);
						}}
					/>
					{videoLinks !== undefined
						? videoLinks.map((videoLink, i) => (
								<Menu.Item key={videoLink.id} style={isMobile ? { margin: '10px 0px' } : {}}>
									<NameEditDeleteItemComp
										key={videoLink.id}
										name={videoLink.name}
										type={'VideoLink'}
										isActive={true}
										itemNameMaxLength={VIDEO_LINK_NAME_LENGTH}
										editItemPopupTitle="Edit VideoLink Name"
										editItemPopupPlaceholder="New VideoLink Name"
										deleteItemPopupTitle="Are you sure?"
										deleteItemPopupDetail="This will delete the link permanently."
										isBeingDeleted={false}
										onItemClick={() => {
											onVideoClick(videoLink.link);
										}}
										editItem={(newName) => {
											videoLinkNameEdited(i, visibleEvent, newName, videoLink);
										}}
										deleteItem={() => deleteVideoLink(i, visibleEvent, videoLink)}
										onThumbnailClicked={() => {
											setOpenModal(true);
											setClickedLink({ ...videoLink, index: i });
										}}
									/>
								</Menu.Item>
						  ))
						: null}
				</MenuItemGroup>
			</Menu>
		</>
	);
};

VideoListComp.propTypes = {
	videoLinks: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		}).isRequired
	),
	allReduxState: PropTypes.object.isRequired,
	visibleEvent: PropTypes.object.isRequired,
	onVideoClick: PropTypes.func.isRequired,
	createVideoLink: PropTypes.func.isRequired,
	videoLinkNameEdited: PropTypes.func.isRequired,
	videoLinkThumbnailEdited: PropTypes.func.isRequired,
	deleteVideoLink: PropTypes.func.isRequired
};

export default VideoListComp;
