import { swaggerClient } from '../../swagger';
import { getThumbnailURL } from '../../helpers/VideoLinkThumbnail';

class Profile {
	static fetchProfile = (userId) => {
		return swaggerClient()
			.apis.profile.get_event_profile({
				userId: userId
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static createProfile = (
		companyName,
		aboutCompany,
		personalDetails,
		brandColor,
		profileImageLink,
		coverImageLink,
		socialMediaLinks,
		whatsappDetails,
		eventId,
		userId
	) => {
		return swaggerClient()
			.apis.profile.create_event_profile({
				profile: {
					companyName: companyName,
					aboutCompany: aboutCompany,
					personalDetails: personalDetails,
					brandColor: brandColor,
					profileImageLink: profileImageLink,
					coverImageLink: coverImageLink,
					socialMediaLinks: socialMediaLinks,
					portfolioEventId: eventId,
					whatsappDetails: whatsappDetails,
					userId: userId
				}
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static updateProfile = (
		companyName,
		aboutCompany,
		personalDetails,
		brandColor,
		profileImageLink,
		coverImageLink,
		socialMediaLinks,
		whatsappDetails,
		eventId,
		userId,
		docId
	) => {
		return swaggerClient()
			.apis.profile.update_event_profile({
				docId: docId,
				profile: {
					companyName: companyName,
					aboutCompany: aboutCompany,
					personalDetails: personalDetails,
					brandColor: brandColor,
					profileImageLink: profileImageLink,
					coverImageLink: coverImageLink,
					socialMediaLinks: socialMediaLinks,
					portfolioEventId: eventId,
					whatsappDetails: whatsappDetails,
					userId: userId
				}
			})
			.then((result) => {
				return Promise.resolve(JSON.parse(result.data));
			});
	};

	static uploadProfileAndCoverPhoto = (image, userId) => {
		return swaggerClient().apis.profile.upload_profile_photo({
			userId: userId,
			file: image
		});
	};
}

export default Profile;
