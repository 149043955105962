// src/helpers/dateHelpers.js

/**
 * Calculates the number of days between two dates
 * @param {Date} date1 - The first date
 * @param {Date} date2 - The second date
 * @returns {number} The number of days between the two dates
 */
export const daysBetweenDates = (date1, date2) => {
	const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
	const diffDays = Math.round(Math.abs((date1 - date2) / oneDay));
	return diffDays;
};

/**
 * Checks if editing is allowed based on the event creation date
 * @param {string} createdAt - The creation date of the event in ISO 8601 format
 * @returns {boolean} True if editing is allowed, false otherwise
 */
export const isEditingAllowed = (createdAt) => {
	const creationDate = new Date(createdAt);
	const currentDate = new Date();

	// Ensure both dates are valid
	if (isNaN(creationDate.getTime()) || isNaN(currentDate.getTime())) {
		console.error('Invalid date format');
		return false;
	}

	const daysSinceCreation = daysBetweenDates(creationDate, currentDate);
	return daysSinceCreation <= 60;
};

/**
 * Formats a date string to a short date format (DD/MM/YY)
 * @param {string} dateString - The date string in ISO 8601 format
 * @returns {string} The formatted date string
 */
export const formatShortDate = (dateString) => {
	const date = new Date(dateString);
	if (isNaN(date.getTime())) {
		console.error('Invalid date format');
		return 'Invalid Date';
	}
	return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
		.toString()
		.padStart(2, '0')}/${date.getFullYear().toString().substr(2, 2)}`;
};
