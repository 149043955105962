import { useState, useEffect, useRef } from 'react';
import ProfileMobileUi from './ProfileMobile';
import { countryCodes } from '../phoneData';
import { Typography, Row, Col, Button, Select, Input, message, Modal } from 'antd';
const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;
import { GlobalOutlined, CheckOutlined, UploadOutlined } from '@ant-design/icons';
import { SketchPicker } from 'react-color';
import youtubeIcon from '../svg/youtube.svg';
import instagramIcon from '../svg/instagram.svg';
import pinterestIcon from '../svg/pinterest.png';
import VimeoIcon from '../svg/viber.svg';
import twitterIcon from '../svg/twitter.svg';
import facebookIcon from '../svg/facebook.svg';
import whatsappIcon from '../svg/whatsapp.svg';
import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg';
import '../styles/profile.sass';
import { IMAGES_URL } from '../config';

const socialIcons = [
	{ icon: facebookIcon, name: 'facebook' },
	{ icon: instagramIcon, name: 'instagram' },
	{ icon: twitterIcon, name: 'twitter' },

	{ icon: youtubeIcon, name: 'youtube' },
	{ icon: whatsappIcon, name: 'whatsapp' },
	{ icon: VimeoIcon, name: 'vimeo' },
	{ icon: pinterestIcon, name: 'pinterest' }
];

const savedColors = [
	'#6F4898',
	'#FFB700',
	'#DC2626',
	'#EA580C',
	'#F59E0B',
	'#78350F',
	'#65A30D',
	'#15803D',
	'#0D9488',
	'#082F49',
	'#1E3A8A',
	'#4F46E5',
	'#86198F',
	'#831843',
	'#BE185D',
	'#E11D48',
	'#881337',
	'#4C0519',
	'#38BDF8',
	'#064E3B',
	'#7F1D1D',
	'#FBBF24',
	'#10B981',
	'#B91C1C',
	'#FB7185',
	'#A78BFA',
	'#818CF8',
	'#3B82F6',
	'#FDBA74',
	'#FCD34D',
	'#F97316',
	'#EF4444'
];

const profileNavElement = [
	{ text: 'Company Info', pageNo: 1 },
	{ text: 'Contact Info', pageNo: 2 },
	{ text: 'Color Theme', pageNo: 3 },
	{ text: 'Profile Photo', pageNo: 4 },
	{ text: 'Social Media Links', pageNo: 5 },
	{ text: 'Portfolio', pageNo: 6 }
];

// const ImageBaseUrl = 'https://devimages.kamero.in/1/none/high/thumb/?key=';
// const ImageBaseUrl = 'https://images.kamero.in/1/none/high/thumb/?key=';

const ImageBaseUrl = IMAGES_URL + '/1/none/high/thumb/?key=';

const SocialInput = ({
	socialSelected,
	socialLinkValue,
	setSocialLinks,
	whatsappDetails,
	setWhatsappDetails,
	enabledSocialField,
	setEnabledSocialField,
	isMobile,
	error
}) => {
	const InputDisabled = isMobile && enabledSocialField !== socialSelected.name;

	return (
		<>
			{socialSelected.name === 'whatsapp' ? (
				<>
					<span
						className="whatsappInputWraper input-custom-style"
						style={
							InputDisabled
								? {
										marginTop: '22px',
										border: '1px solid #A1A1AA',
										backgroundColor: '#f5f5f5',
										color: 'rgba(0, 0, 0, 0.25)',
										cursor: 'not-allowed'
								  }
								: { marginTop: '22px' }
						}>
						<span className="ant-input-prefix">
							<img src={socialSelected.icon} className="input-icon" />
						</span>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Select
								showSearch
								style={{
									width: 90,
									marginRight: '10px'
								}}
								name={'countryCode'}
								value={whatsappDetails.countryCode}
								placeholder="Search to Select"
								optionFilterProp="children"
								filterOption={(input, option) => (option?.label ?? '').includes(input)}
								filterSort={(optionA, optionB) =>
									(optionA?.label ?? '')
										.toLowerCase()
										.localeCompare((optionB?.label ?? '').toLowerCase())
								}
								options={countryCodes.map((item) => ({
									label: item.value + ' ' + item.label,
									value: item.value + '-' + item.code
								}))}
								dropdownClassName="whatsapp-dropdown"
								dropdownRender={(menu, index) => (
									<div key={index} style={{ minWidth: '200px' }}>
										{menu}
									</div>
								)}
								onChange={(e) => setWhatsappDetails(e, 'countryCode')}
								disabled={InputDisabled}
								onBlur={() => {
									setEnabledSocialField(null);
								}}
							/>
							<Input
								placeholder={`Enter your whatsapp number`}
								name={'whatsappNo'}
								type="text"
								className="whatsappInput"
								value={whatsappDetails.whatsappNo}
								onChange={(e) => setWhatsappDetails(e.target.value, 'whatsappNo')}
								disabled={InputDisabled}
								onBlur={() => {
									setEnabledSocialField(null);
								}}
								suffix={
									!InputDisabled ? null : (
										<h6
											styles={{
												fontSize: '14px',
												color: '#FF404C',
												fontWeight: 800
											}}
											onClick={() => {
												setEnabledSocialField(socialSelected.name);
											}}
											role="button">
											Edit
										</h6>
									)
								}
							/>
						</div>
					</span>
					<p className="errorText" style={{ display: error ? 'block' : 'none' }}>
						Enter a valid {socialSelected.name} number
					</p>
				</>
			) : (
				<>
					<Input
						value={socialLinkValue}
						placeholder={`Place your ${socialSelected.name} link here`}
						prefix={<img src={socialSelected.icon} className="input-icon" />}
						type="text"
						size="large"
						name={socialSelected.name}
						onChange={(e) => setSocialLinks(e)}
						style={{ marginTop: '22px' }}
						className="input-custom-style"
						disabled={InputDisabled}
						onBlur={() => {
							setEnabledSocialField(null);
						}}
						suffix={
							!InputDisabled ? null : (
								<h6
									styles={{
										fontSize: '14px',
										color: '#FF404C',
										fontWeight: 800
									}}
									onClick={() => {
										setEnabledSocialField(socialSelected.name);
									}}
									role="button">
									Edit
								</h6>
							)
						}
					/>
					<p className="errorText" style={{ display: error ? 'block' : 'none' }}>
						Enter your {socialSelected.name} link
					</p>
				</>
			)}
		</>
	);
};

const Profile = (props) => {
	const profilePhotInputRef = useRef(null);
	const coverPhotInputRef = useRef(null);
	const { profile } = props;
	const [errorCompanyName, setCompanyNameError] = useState('');
	const [errorPhone, setPhoneError] = useState('');
	const [errorEmail, setEmailError] = useState('');
	const [errorWeb, setWebError] = useState('');
	const [selectedIcon, setSelectedIcon] = useState([]);
	const [socialInputError, setSocialInputError] = useState([]);
	const [enabledSocialField, setEnabledSocialField] = useState(null);
	const [isMobile, setIsMobile] = useState(window.screen.width <= 576);
	const [mobilePreview, setMobilePreview] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		props.getProfileData();
		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	function handleWindowResize() {
		if (window.innerWidth <= 576 && !isMobile) setIsMobile(true);
		else if (window.innerWidth > 576 && isMobile) setIsMobile(false);
	}

	useEffect(() => {
		const filteredArr = socialIcons.filter(
			(icon, index) => profile.socialMediaLinks[icon.name] != ''
		);
		setSelectedIcon(filteredArr);
	}, [profile.socialMediaLinks]);

	const openNotificationWithIcon = (type, text) => {
		if (type == 'success') message.success(text);
		else if (type == 'error') message.error(text);
	};

	const onCompanyNameChange = (e) => {
		props.updateCompanyName(e.target.value);
	};

	const onAboutCompanyChange = (e) => {
		props.updateAboutCompany(e.target.value);
	};

	const handleUpdatePage = () => {
		props.updateProfile(
			profile.companyName,
			profile.aboutCompany,
			profile.personalDetails,
			profile.brandColor,
			profile.profilePhoto,
			profile.coverPhoto,
			profile.socialMediaLinks,
			profile.whatsappDetails,
			profile.eventId,
			profile.docId,
			() => {}
		);
		props.updateCurrentPage(profile.currentPage + 1);
	};

	const handleUpdatePageByNav = (pageNo) => {
		const validationResult = validateAllProfileData(pageNo);
		if (validationResult.validated) {
			props.updateProfile(
				profile.companyName,
				profile.aboutCompany,
				profile.personalDetails,
				profile.brandColor,
				profile.profilePhoto,
				profile.coverPhoto,
				profile.socialMediaLinks,
				profile.whatsappDetails,
				profile.eventId,
				profile.docId,
				() => {}
			);
			props.updateCurrentPage(pageNo);
		} else {
			openNotificationWithIcon('error', validationResult.errorMsg);
			props.updateCurrentPage(validationResult.errorIn);
		}
	};

	const validateAllProfileData = (pageNo) => {
		const result = { validated: true, errorIn: 0, errorMsg: '' };
		for (let i = 1; i < pageNo; i++) {
			if (i == 1) {
				if (profile.companyName == '' || profile.aboutCompany == '') {
					result.validated = false;
					result.errorIn = 1;
					result.errorMsg = 'Please fill all the fields correctly in Company Info';
					return result;
				}
			}

			if (i == 2) {
				if (!validatePersonalDetails()) {
					result.validated = false;
					result.errorIn = 2;
					result.errorMsg = 'Please fill all the fields correctly in Contact Info';
					return result;
				}
			}

			if (i == 3) {
				if (profile.brandColor == '') {
					result.validated = false;
					result.errorIn = 3;
					result.errorMsg = 'Please select the Brand Color';
					return result;
				}
			}

			if (i == 4) {
				if (profile.coverPhoto == '' || profile.profilePhoto == '') {
					result.validated = false;
					result.errorIn = 4;
					result.errorMsg = 'Please upload Profile and Cover Photos';
					return result;
				}
			}

			if (i == 5) {
				if (!validateSocialLinks()) {
					result.validated = false;
					result.errorIn = 5;
					result.errorMsg = 'Please fill all the fields corretly in Social Media Links';
					return result;
				}
			}

			if (i == 5) {
				if (selectedIcon.length < 4) {
					result.validated = false;
					result.errorIn = 5;
					result.errorMsg = 'Please enter atleat 4 social media links';
					return result;
				}
			}

			if (i == 6) {
				if (profile.eventId == '') {
					result.validated = false;
					result.errorIn = 6;
					result.errorMsg = 'Please fill event id field';
					return result;
				}
			}
		}
		return result;
	};

	const validateCompanyDetails = () => {
		const companyNamePattern = /^(?!^[0-9]*$)[a-zA-Z0-9 ]+$/;

		if (companyNamePattern.test(profile.companyName)) setCompanyNameError('');
		else
			setCompanyNameError(
				'Company Name should only contains alphabets or only alphanumeric characters'
			);

		return companyNamePattern.test(profile.companyName);
	};

	const validatePersonalDetails = () => {
		const details = profile.personalDetails;
		const phoneNo = details.phoneNo;
		const email = details.emailId;
		const url = details.websiteLink;
		const emailPattern = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
		const phonePattern = /^\d{10}$/;

		if (phonePattern.test(phoneNo)) setPhoneError('');
		else setPhoneError('Enter a valid phone no.');

		if (url.startsWith('https://')) setWebError('');
		else setWebError(`Website link should include "https://"`);

		if (emailPattern.test(email)) setEmailError('');
		else setEmailError('Enter a valid email id.');

		return phonePattern.test(phoneNo) && emailPattern.test(email) && url.startsWith('https://')
			? true
			: false;
	};

	const onPersonalDetailsChange = (e) => {
		const tempObj = profile.personalDetails;
		if (e.target.name == 'mobNo') tempObj.phoneNo = e.target.value;
		else if (e.target.name == 'webLink') tempObj.websiteLink = e.target.value;
		else tempObj.emailId = e.target.value;
		props.updatePersonalDetails(tempObj);
	};

	const handleChangeBrandColor = (color) => {
		props.updateBrandColor(color.hex);
	};

	const handleChangeSocialLinks = (e) => {
		const tempObj = profile.socialMediaLinks;
		tempObj[e.target.name] = e.target.value;
		props.updateSocialLinks(tempObj);
	};

	const handleChangeWhatsappInput = (value, type) => {
		const tempObj = profile.whatsappDetails;
		tempObj[type] = value.replaceAll(' ', '');
		const code = tempObj?.countryCode?.split('-')[0].slice(1);
		const phonenumber = tempObj?.whatsappNo;
		props.updateWhatsappDetails(tempObj);
		if (phonenumber != '') {
			const tempObj2 = profile.socialMediaLinks;
			tempObj2['whatsapp'] = `https://wa.me/${code + phonenumber}`;
			props.updateSocialLinks(tempObj2);
		}
	};

	const handleSocialSelected = (iconClicked) => {
		const iconIndex = selectedIcon.findIndex((icon) => icon.name == iconClicked.name);
		if (iconIndex > -1) {
			const filteredArr = selectedIcon.filter((icon, index) => iconIndex != index);
			setSelectedIcon(filteredArr);
			const tempObj = profile.socialMediaLinks;
			tempObj[iconClicked.name] = '';
			props.updateSocialLinks(tempObj);
		} else {
			setSelectedIcon([...selectedIcon, iconClicked]);
		}
	};

	const validateSocialLinks = () => {
		const tempObj = profile.socialMediaLinks;
		const whatsappNo = profile.whatsappDetails.whatsappNo;
		const errorInputNames = [];
		selectedIcon.forEach((icon) => {
			if (tempObj[icon.name] == '') errorInputNames.push(icon);
			if (icon.name == 'whatsapp') {
				const phonePattern = /^\d{10}$/;
				if (!phonePattern.test(whatsappNo)) errorInputNames.push(icon);
			}
		});
		setSocialInputError(errorInputNames);
		return errorInputNames.length < 1;
	};

	const onEventIdChange = (e) => {
		props.updateEventId(e.target.value);
	};

	const createProfile = () => {
		props.createProfile(
			profile.companyName,
			profile.aboutCompany,
			profile.personalDetails,
			profile.brandColor,
			profile.profilePhoto,
			profile.coverPhoto,
			profile.socialMediaLinks,
			profile.whatsappDetails,
			profile.eventId,
			openNotificationWithIcon
		);
	};

	const updateProfile = () => {
		const validationResult = validateAllProfileData(7);
		if (validationResult.validated) {
			props.updateProfile(
				profile.companyName,
				profile.aboutCompany,
				profile.personalDetails,
				profile.brandColor,
				profile.profilePhoto,
				profile.coverPhoto,
				profile.socialMediaLinks,
				profile.whatsappDetails,
				profile.eventId,
				profile.docId,
				openNotificationWithIcon
			);
		} else {
			openNotificationWithIcon('error', validationResult.errorMsg);
			props.updateCurrentPage(validationResult.errorIn);
		}
	};

	const handleProfileUploadClick = () => {
		profilePhotInputRef.current.click();
	};

	const handleProfileChange = (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		if (file && file.type.startsWith('image/')) {
			// Call your upload API here, passing the image file
			props.uploadPhotos(file, 'profile', openNotificationWithIcon);
		} else {
			openNotificationWithIcon('error', 'Invalid file format. Please select an image file.');
			console.log('Invalid file format. Please select an image file.');
		}
	};

	const handleCoverUploadClick = () => {
		coverPhotInputRef.current.click();
	};

	const handleCoverChange = (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append('file', file);
		if (file && file.type.startsWith('image/')) {
			// Call your upload API here, passing the image file
			props.uploadPhotos(file, 'cover', openNotificationWithIcon);
		} else {
			openNotificationWithIcon('error', 'Invalid file format. Please select an image file.');
			console.log('Invalid file format. Please select an image file.');
		}
	};

	const toggleMobilePreview = () => {
		setMobilePreview((prev) => !prev);
	};

	return (
		<>
			<div className="loadingScreen" style={{ display: profile.loading ? 'flex' : 'none' }}>
				<IndicatorSVG />
			</div>
			<Row
				gutter={[20, 20]}
				style={{
					width: '100%',
					maxWidth: '1350px',
					margin: '40px auto auto auto',
					display: profile.loading ? 'none' : 'flex'
				}}>
				<Col
					xs={{ span: 24 }}
					sm={{ span: 24 }}
					md={{ span: 24 }}
					lg={{ span: 24 }}
					xl={{ span: 16 }}>
					<Row>
						<Col span={24}>
							<Title level={5}>
								<h4>Create Profile</h4>
							</Title>
						</Col>
						<Col span={24}>
							<div className={isMobile ? 'profileNavbar-mobile' : 'profileNavbar'}>
								{profileNavElement.map((navElem, index) => (
									<div
										key={index}
										className={
											profile.currentPage == navElem.pageNo ? 'navElemSelected' : 'navElem'
										}
										onClick={() => handleUpdatePageByNav(navElem.pageNo)}>
										{navElem.text}
									</div>
								))}
							</div>
						</Col>
						<Col span={24}>
							{profile.currentPage == 1 && (
								<>
									<div>
										<Title level={5}>
											<h5
												style={{
													fontSize: '16px',
													color: '#1E293B',
													fontWeight: '700'
												}}>
												Company Name
											</h5>
										</Title>
										<Input
											value={profile.companyName}
											placeholder="Write company name"
											style={{ marginBottom: 10 }}
											size="large"
											maxLength={32}
											onChange={onCompanyNameChange}
											// disabled={profile.profileCreated}
											// readOnly={profile.profileCreated}
											className="input-custom-style"
										/>
										<p className="errorText">{errorCompanyName}</p>
										<div
											style={{
												marginTop: '8px',
												fontSize: '16px',
												color: '#27272A',
												fontWeight: '400'
											}}>
											Note: The weblik for your event Galleries will be in this format
										</div>
										<a style={{ color: '#5290f6', textAlign: 'center' }}>
											https://(companyName).kamero.in/(evintid)
										</a>
									</div>
									<div>
										<Title level={5}>
											<h5
												style={{
													fontSize: '16px',
													color: '#1E293B',
													fontWeight: '700'
												}}>
												About your company
											</h5>
										</Title>
										<TextArea
											showCount
											spellCheck="false"
											rows={10}
											value={profile.aboutCompany}
											maxLength={1000}
											style={{
												marginBottom: 10
											}}
											className="companyTextArea"
											onChange={onAboutCompanyChange}
											placeholder="Write about your company"
										/>
									</div>
									<div>
										<Button
											type="primary"
											disabled={
												!profile.profileCreated ||
												profile.aboutCompany == '' ||
												profile.companyName == ''
											}
											onClick={updateProfile}
											style={{
												marginRight: '10px',
												display: profile.profileCreated ? 'inline-block' : 'none'
											}}>
											Save
										</Button>
										{isMobile ? (
											<>
												<Button
													type="primary"
													style={{
														marginRight: '10px',
														display: profile.profileCreated ? 'none' : 'inline-block'
													}}
													disabled={profile.aboutCompany == '' || profile.companyName == ''}
													onClick={() => {
														validateCompanyDetails() && handleUpdatePage();
													}}>
													Next
												</Button>
												<Button
													type="text"
													style={{
														fontSize: '16px',
														color: '#3F3F46',
														fontWeight: 500
													}}
													disabled={profile.aboutCompany == '' || profile.companyName == ''}
													onClick={toggleMobilePreview}>
													Preview
												</Button>
											</>
										) : (
											<Button
												type="primary"
												disabled={profile.aboutCompany == '' || profile.companyName == ''}
												onClick={() => {
													validateCompanyDetails() && handleUpdatePage();
												}}>
												Next
											</Button>
										)}
									</div>
								</>
							)}
							{profile.currentPage == 2 && (
								<div>
									<Title level={5}>
										<h5
											style={{
												fontSize: '16px',
												color: '#1E293B',
												fontWeight: '700'
											}}>
											Mobile Number
										</h5>
									</Title>
									<Input
										value={profile.personalDetails.phoneNo}
										//placeholder="Enter "
										prefix="+91"
										//style={{ marginTop: 10 }}
										size="large"
										type="tel"
										name="mobNo"
										pattern="[0-9]{10}"
										onChange={onPersonalDetailsChange}
										className="input-custom-style"
									/>
									<p className="errorText">{errorPhone}</p>
									<Title level={5} style={{ marginTop: 15 }}>
										<h5
											style={{
												fontSize: '16px',
												color: '#1E293B',
												fontWeight: '700'
											}}>
											Website Link (including "https://")
										</h5>
									</Title>
									<Input
										//value={profile.companyName}
										value={profile.personalDetails.websiteLink}
										placeholder="Website Link"
										prefix={<GlobalOutlined style={{ color: 'cornflowerblue' }} />}
										//style={{ marginBottom: 10 }}
										size="large"
										onChange={onPersonalDetailsChange}
										name="webLink"
										type="text"
										className="input-custom-style"
									/>
									<p className="errorText">{errorWeb}</p>
									<Title level={5} style={{ marginTop: 15 }}>
										<h5 style={{ fontSize: '16px', color: '#1E293B', fontWeight: '700' }}>
											Email Id
										</h5>
									</Title>
									<Input
										//value={profile.companyName}
										value={profile.personalDetails.emailId}
										placeholder="abx@xyz.com"
										//style={{ marginTop: 10 }}
										onChange={onPersonalDetailsChange}
										name="email"
										size="large"
										type="email"
										className="input-custom-style"
									/>
									<p className="errorText">{errorEmail}</p>
									<Button
										type="primary"
										disabled={!profile.profileCreated}
										onClick={() => {
											validatePersonalDetails() && updateProfile();
										}}
										style={{
											marginRight: '10px',
											marginTop: 15,
											display: profile.profileCreated ? 'inline-block' : 'none'
										}}>
										Save
									</Button>
									{isMobile ? (
										<>
											<Button
												type="primary"
												disabled={
													profile.personalDetails.phoneNo == '' ||
													profile.personalDetails.emailId == '' ||
													profile.personalDetails.websiteLink == ''
												}
												onClick={() => {
													validatePersonalDetails() && handleUpdatePage();
												}}
												style={{
													marginTop: 15,
													display: profile.profileCreated ? 'none' : 'inline-block'
												}}>
												Next
											</Button>
											<Button
												type="text"
												style={{
													fontSize: '16px',
													color: '#3F3F46',
													fontWeight: 500
												}}
												disabled={profile.aboutCompany == '' || profile.companyName == ''}
												onClick={toggleMobilePreview}>
												Preview
											</Button>
										</>
									) : (
										<Button
											type="primary"
											disabled={
												profile.personalDetails.phoneNo == '' ||
												profile.personalDetails.emailId == '' ||
												profile.personalDetails.websiteLink == ''
											}
											onClick={() => {
												validatePersonalDetails() && handleUpdatePage();
											}}
											style={{ marginTop: 15 }}>
											Next
										</Button>
									)}
								</div>
							)}
							{profile.currentPage == 3 && (
								<div>
									<Title level={5}>
										<h5 style={{ fontSize: '16px', color: '#1E293B', fontWeight: '700' }}>
											Select your Brand Color
										</h5>
									</Title>
									<Row gutter={[20, 20]} className="colorPickerRow">
										<Col
											xs={{ span: 24 }}
											sm={{ span: 12 }}
											md={{ span: 10 }}
											lg={{ span: 10 }}
											style={{ borderRight: '1.5px solid lightgrey' }}>
											<p style={{ fontWeight: 500, fontSize: '16px', color: '#1E293B' }}>
												Color Picker
											</p>
											<SketchPicker
												className="colorPicker"
												color={profile.brandColor}
												onChangeComplete={handleChangeBrandColor}
											/>
										</Col>
										<Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 14 }} lg={{ span: 14 }}>
											<p
												style={{
													fontWeight: 500,
													fontSize: '16px',
													color: '#1E293B'
												}}>
												Saved Color
											</p>
											<Row gutter={[10, 10]}>
												{savedColors.map((color, index) => (
													<Col key={index}>
														<div
															className="colorBrick"
															style={{ backgroundColor: color }}
															onClick={() => handleChangeBrandColor({ hex: color })}>
															{profile.brandColor == color && <CheckOutlined />}
														</div>
													</Col>
												))}
											</Row>
										</Col>
									</Row>
									<Title level={5}>
										<h5 style={{ fontSize: '16px', color: '#1E293B', fontWeight: '700' }}>
											Selected Color
										</h5>
									</Title>
									<Input
										value={profile.brandColor}
										placeholder="Select a color "
										prefix={
											<div
												style={{
													backgroundColor: profile.brandColor,
													height: '25px',
													width: '45px',
													border: '1px solid #d1d1d3'
												}}></div>
										}
										type="text"
										size="large"
										name="mobNo"
										className="input-custom-style"
										onChange={(e) => handleChangeBrandColor({ hex: e.target.value })}
									/>
									<div>
										<Button
											type="primary"
											disabled={profile.brandColor == ''}
											style={{
												margin: '15px 10px 15px 0',
												display: profile.profileCreated ? 'inline-block' : 'none'
											}}
											onClick={updateProfile}>
											Save
										</Button>
										{isMobile ? (
											<>
												<Button
													type="primary"
													style={{
														margin: '15px 0 15px 0',
														display: profile.profileCreated ? 'none' : 'inline-block'
													}}
													disabled={profile.brandColor == ''}
													onClick={handleUpdatePage}>
													Next
												</Button>
												<Button
													type="text"
													style={{
														fontSize: '16px',
														color: '#3F3F46',
														fontWeight: 500
													}}
													disabled={profile.aboutCompany == '' || profile.companyName == ''}
													onClick={toggleMobilePreview}>
													Preview
												</Button>
											</>
										) : (
											<Button
												type="primary"
												style={{ margin: '15px 0 15px 0' }}
												disabled={profile.brandColor == ''}
												onClick={handleUpdatePage}>
												Next
											</Button>
										)}
									</div>
								</div>
							)}
							{profile.currentPage == 4 && (
								<>
									<div>
										<Title level={5}>
											<h5 style={{ fontSize: '16px', color: '#1E293B', fontWeight: '700' }}>
												Upload Profile Photo
											</h5>
										</Title>
										<div className="uploadRow">
											<UploadOutlined
												className="uploadLogo"
												style={{ cursor: 'pointer' }}
												onClick={handleProfileUploadClick}
											/>
											<input
												type="file"
												ref={profilePhotInputRef}
												style={{ display: 'none' }}
												onChange={handleProfileChange}
											/>
											<h4
												style={{
													fontWeight: 600,
													marginBottom: '2px',
													fontSize: '18px',
													color: '#52525B'
												}}>
												Upload Profile Photo
											</h4>
											<p
												style={{
													fontSize: '16px',
													color: '#71717A',
													verticalAlign: 'top',
													fontWeight: 400
												}}>
												Size 320 X 320 pixels
											</p>
										</div>
										{/* {isMobile && (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>110 KB</span>
												<Progress percent={30} strokeColor="#FF404C" />
											</div>
										)} */}
										<div className="profileImagePreview">
											<img
												src={
													profile.profilePhoto != '' ? `${ImageBaseUrl}${profile.profilePhoto}` : ''
												}
											/>
										</div>
									</div>
									<div style={{ marginTop: '55px' }}>
										<Title level={5}>
											<h5 style={{ fontSize: '16px', color: '#1E293B', fontWeight: '700' }}>
												Upload Cover Photo
											</h5>
										</Title>
										<div className="uploadRow">
											<UploadOutlined
												className="uploadLogo"
												style={{ cursor: 'pointer' }}
												onClick={handleCoverUploadClick}
											/>
											<input
												type="file"
												ref={coverPhotInputRef}
												style={{ display: 'none' }}
												onChange={handleCoverChange}
											/>
											<h4
												style={{
													fontWeight: 600,
													marginBottom: '2px',
													fontSize: '18px',
													color: '#52525B'
												}}>
												Upload Cover Photo
											</h4>
											<p
												style={{
													fontSize: '16px',
													color: '#71717A',
													verticalAlign: 'top',
													fontWeight: 400
												}}>
												Size 1080 X 720 pixels
											</p>
										</div>
										{/* {isMobile && (
											<div style={{ display: 'flex', alignItems: 'center' }}>
												<span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>110 KB</span>
												<Progress percent={30} strokeColor="#FF404C" />
											</div>
										)} */}
										<div
											className="coverImagePreview"
											style={{ width: '100%', overflow: 'hidden', maxWidth: '300px' }}>
											<img
												src={profile.coverPhoto != '' ? `${ImageBaseUrl}${profile.coverPhoto}` : ''}
												style={{ width: '100%', height: '100%', objectFit: 'cover' }}
											/>
										</div>
										<Button
											type="primary"
											disabled={profile.coverPhoto == '' || profile.profilePhoto == ''}
											style={{
												margin: '15px 10px 15px 0',
												display: profile.profileCreated ? 'inline-block' : 'none'
											}}
											onClick={updateProfile}>
											Save
										</Button>
										{isMobile ? (
											<>
												<Button
													type="primary"
													style={{
														margin: '15px 0 15px 0',
														display: profile.profileCreated ? 'none' : 'inline-block'
													}}
													disabled={profile.coverPhoto == '' || profile.profilePhoto == ''}
													onClick={handleUpdatePage}>
													Next
												</Button>
												<Button
													type="text"
													style={{
														fontSize: '16px',
														color: '#3F3F46',
														fontWeight: 500
													}}
													disabled={profile.aboutCompany == '' || profile.companyName == ''}
													onClick={toggleMobilePreview}>
													Preview
												</Button>
											</>
										) : (
											<Button
												type="primary"
												style={{ margin: '15px 0 15px 0' }}
												disabled={profile.coverPhoto == '' || profile.profilePhoto == ''}
												onClick={handleUpdatePage}>
												Next
											</Button>
										)}
									</div>
								</>
							)}
							{profile.currentPage == 5 && (
								<div>
									<Title
										level={5}
										style={{
											marginBottom: '0px',
											fontSize: '20px',
											color: '#1E293B',
											fontWeight: 600
										}}>
										Select and Add your Social media links
									</Title>
									<p
										style={{
											marginTop: '0px',
											marginBottom: '10px',
											fontSize: '14px',
											color: '#71717A',
											fontWeight: 400
										}}>
										Please select at least 4 links to proceed
									</p>
									<div className={isMobile ? 'iconContainer-mobile' : 'iconContainer'}>
										{socialIcons.map((element, index) => (
											<img
												key={index}
												src={element.icon}
												className="icon"
												onClick={() => handleSocialSelected(element)}
											/>
										))}
									</div>
									<div>
										{selectedIcon.map((icon, index) => (
											<SocialInput
												key={index}
												socialSelected={icon}
												isMobile={isMobile}
												enabledSocialField={enabledSocialField}
												setEnabledSocialField={setEnabledSocialField}
												socialLinkValue={profile.socialMediaLinks[icon.name]}
												setSocialLinks={handleChangeSocialLinks}
												whatsappDetails={profile.whatsappDetails}
												setWhatsappDetails={handleChangeWhatsappInput}
												error={
													socialInputError.findIndex((errorIcon) => errorIcon.name == icon.name) >
													-1
														? true
														: false
												}
											/>
										))}
									</div>

									<Button
										type="primary"
										disabled={selectedIcon.length < 4}
										style={{
											margin: '15px 10px 15px 0',
											display: profile.profileCreated ? 'inline-block' : 'none'
										}}
										onClick={() => {
											validateSocialLinks() && updateProfile();
										}}>
										Save
									</Button>
									{isMobile ? (
										<>
											{' '}
											<Button
												type="primary"
												disabled={selectedIcon.length < 4}
												onClick={() => {
													validateSocialLinks() && handleUpdatePage();
												}}
												style={{ marginTop: '15px' }}>
												Next
											</Button>
											<Button
												type="text"
												style={{
													fontSize: '16px',
													color: '#3F3F46',
													fontWeight: 500
												}}
												disabled={profile.aboutCompany == '' || profile.companyName == ''}
												onClick={toggleMobilePreview}>
												Preview
											</Button>
										</>
									) : (
										<Button
											type="primary"
											disabled={selectedIcon.length < 4}
											onClick={() => {
												validateSocialLinks() && handleUpdatePage();
											}}
											style={{ marginTop: '15px' }}>
											Next
										</Button>
									)}
								</div>
							)}
							{profile.currentPage == 6 && (
								<div>
									<Title level={5}>
										<h5
											style={{
												fontSize: '16px',
												color: '#1E293B',
												fontWeight: '700'
											}}>
											Enter Event ID for your Portfolio Event
										</h5>
									</Title>
									<Input
										value={profile.eventId}
										placeholder="Write Event ID"
										style={{ marginBottom: 10 }}
										size="large"
										onChange={onEventIdChange}
										className="input-custom-style"
									/>
									<div style={{ marginTop: '15px' }}>
										<Button
											type="primary"
											disabled={profile.eventId == ''}
											style={{
												margin: '10px',
												marginLeft: 0,
												display: profile.profileCreated ? 'inline-block' : 'none'
											}}
											onClick={updateProfile}>
											Save
										</Button>
										{isMobile && (
											<>
												<Button
													type="text"
													style={{
														margin: '10px',
														marginLeft: 0,
														fontSize: '16px',
														color: '#3F3F46',
														fontWeight: 500
													}}
													disabled={profile.aboutCompany == '' || profile.companyName == ''}
													onClick={toggleMobilePreview}>
													Preview
												</Button>
											</>
										)}
										<Button
											type="primary"
											style={{ margin: '10px', marginLeft: 0 }}
											disabled={profile.eventId == '' || profile.loading || profile.profileCreated}
											onClick={createProfile}>
											Create Profile
										</Button>
									</div>
								</div>
							)}
						</Col>
					</Row>
				</Col>

				{isMobile ? (
					<>
						<Modal open={mobilePreview} onCancel={toggleMobilePreview} footer={null}>
							<ProfileMobileUi
								validatePersonalDetails={validatePersonalDetails}
								selectedIcon={selectedIcon}
							/>
						</Modal>
						<Col />
					</>
				) : (
					<Col
						xs={{ span: 24 }}
						sm={{ span: 24 }}
						md={{ span: 24 }}
						lg={{ span: 24 }}
						xl={{ span: 8 }}
						style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
						<ProfileMobileUi
							validatePersonalDetails={validatePersonalDetails}
							selectedIcon={selectedIcon}
						/>
					</Col>
				)}
			</Row>
		</>
	);
};

export default Profile;
