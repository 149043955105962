import { Button, Input, message, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Event from '../pouchDB/models/Event';

const { Paragraph, Title, Text } = Typography;

const DownloadAllPhotosModal = ({ event, showAsLink }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [loading, setLoading] = useState(false);
	const [shareDetails, setShareDetails] = useState(null);
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkIfMobile = () => setIsMobile(window.innerWidth <= 768);
		checkIfMobile();
		window.addEventListener('resize', checkIfMobile);
		return () => window.removeEventListener('resize', checkIfMobile);
	}, []);

	const validateEmail = useCallback((email) => {
		const re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
		return re.test(String(email).toLowerCase());
	}, []);

	const handleEmailChange = useCallback((e) => {
		setEmail(e.target.value);
		setEmailError('');
	}, []);

	const fetchFullEvent = useCallback(async () => {
		try {
			const details = await Event.fetchShareDetail(event.id);
			setShareDetails(details);
			return details;
		} catch (error) {
			console.error('Error fetching full event details:', error);
			message.error('Failed to fetch event details. Please try again.');
			return null;
		}
	}, [event.id]);

	const handleDownload = useCallback(async () => {
		if (!validateEmail(email)) {
			setEmailError('Please enter a valid email address');
			return;
		}

		setLoading(true);
		let data = shareDetails || (await fetchFullEvent());
		if (!data) {
			setLoading(false);
			return;
		}

		try {
			const result = await Event.downloadAllPhotos(event.id, email, data.downloadPin);
			if (result.message) {
				message.error(result.message);
			} else {
				message.success('Download link will be sent to the provided email within 30 minutes');
				setIsModalVisible(false);
			}
		} catch (err) {
			message.error('Something went wrong');
		} finally {
			setLoading(false);
		}
	}, [email, shareDetails, fetchFullEvent, event.id, validateEmail]);

	const triggerElement = useMemo(
		() =>
			showAsLink ? (
				<a onClick={() => setIsModalVisible(true)}>Request Download Link</a>
			) : (
				<Button onClick={() => setIsModalVisible(true)}>Request Download Link</Button>
			),
		[showAsLink]
	);

	return (
		<>
			{triggerElement}
			<Modal
				title={<Title level={4}>Request All Photos Download Link: {event.name}</Title>}
				open={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				footer={null}
				width={isMobile ? '100%' : 600}>
				<Paragraph
					strong
					style={{
						fontSize: '16px',
						backgroundColor: '#f0f0f0',
						padding: '10px',
						borderLeft: '4px solid #6F4898',
						fontWeight: 500,
						marginBottom: '20px'
					}}>
					Enter the email address where you want to receive a one-time photos download link. You can
					either request this link for your own email address or directly send it to users or
					clients by entering their email address.
				</Paragraph>
				<Paragraph>
					After requesting the download link, you will receive an email with album-wise links to
					download all photos from the event. These links will be valid for 24 hours, so make sure
					to download the photos within 24 hours using that link.
				</Paragraph>
				<div style={{ marginTop: '20px', marginBottom: '20px' }}>
					<Title level={5}>Email Address</Title>
					<Input
						type="email"
						value={email}
						onChange={handleEmailChange}
						placeholder="Enter email address"
						style={{ marginBottom: '10px' }}
					/>
					{emailError && <Text type="danger">{emailError}</Text>}
				</div>
				<div style={{ display: 'flex', justifyContent: 'center', gap: '10px', flexWrap: 'wrap' }}>
					<Button
						type="primary"
						onClick={handleDownload}
						loading={loading}
						size={isMobile ? 'middle' : 'large'}
						style={{ minWidth: isMobile ? '40px' : 'auto' }}>
						Request Download Link
					</Button>
				</div>
			</Modal>
		</>
	);
};

DownloadAllPhotosModal.propTypes = {
	event: PropTypes.object.isRequired,
	showAsLink: PropTypes.bool
};

export default DownloadAllPhotosModal;
