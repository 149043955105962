import {
  ACTION_UPLOAD_STARTED,
  ACTION_UPLOAD_FINISHED,
  ACTION_UPLOAD_PROGRESS_UPDATED,
  ACTION_UPLOAD_ERROR } from './action_types'

export const uploadStartedAction = (total) => ({
  type: ACTION_UPLOAD_STARTED,
  total: total
})

export const uploadFinished = (successMessage) => ({
  type: ACTION_UPLOAD_FINISHED,
  successMessage: successMessage
})

export const uploadProgressUpdatedAction = (pending) => ({
  type: ACTION_UPLOAD_PROGRESS_UPDATED,
  pending: pending
})

export const uploadErrorOccuredAction = (error) => ({
  type: ACTION_UPLOAD_ERROR,
  error: error
})
