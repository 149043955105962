import {errorHandler, swaggerClient} from "../../swagger"

class Wallet {
   static fetchBalance = async (userId) => {
      try {
         let result = await swaggerClient().apis.store.wallet({
            userId: userId
         })
         return JSON.parse(result.data)
      } catch (err) {
         errorHandler(err)
      }
   }

   static redeem = async (userId, code) => {
      try {
         let result = await swaggerClient().apis.store.redeem({
            userId: userId,
            code: code
         })
         return JSON.parse(result.data)
      } catch (err) {
         errorHandler(err)
      }
   }
}

export default Wallet