import { Alert, Button, Input, Modal, Typography } from 'antd';
import { Pencil } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEditingAllowed } from '../helpers/dateHelpers';

const { Title, Text } = Typography;

const EditEventIdModal = ({ event, userId, onEdit }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [newEventId, setNewEventId] = useState('');
	const [error, setError] = useState(null);

	useEffect(() => {
		if (isModalVisible) {
			setNewEventId(event.eventId);
			setError(null);
		}
	}, [isModalVisible, event.eventId]);

	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleEdit = () => {
		if (newEventId.trim() === '') {
			setError('Event ID cannot be empty.');
			return;
		}

		if (newEventId.trim() === event.eventId.trim()) {
			setError('New event ID must be different from the current ID.');
			return;
		}

		onEdit(userId, event, newEventId);
		setIsModalVisible(false);
	};

	const renderEditButton = () => (
		<Pencil
			style={{
				height: '24px',
				width: '24px',
				cursor: 'pointer',
				paddingLeft: '4px',
				paddingRight: '4px',
				paddingTop: '8px'
			}}
			onClick={showModal}
		/>
	);

	const renderModalContent = () => {
		if (!isEditingAllowed(event.createdAt)) {
			return (
				<Alert
					message="Event ID cannot be edited"
					description={
						<>
							For security reasons, the event ID cannot be edited after 60 days from creation. If
							you need to change it, please contact support at{' '}
							<a href="mailto:support@kamero.in">support@kamero.in</a>.
						</>
					}
					type="info"
					showIcon
				/>
			);
		}

		return (
			<>
				<Alert
					message="Be aware"
					description="Changing the event ID will also change the event QR code and event link. After changing the event ID, you will need to reshare the new QR code or event link with your Users/clients. Users with older links or QR codes will not be able to access event photos."
					type="info"
					showIcon
					style={{ marginBottom: '16px' }}
				/>
				<Text>Enter the new ID for the event:</Text>
				<Input
					value={newEventId}
					onChange={(e) => setNewEventId(e.target.value)}
					placeholder="New Event ID"
					maxLength={50}
					style={{ marginTop: '8px', marginBottom: '16px' }}
				/>
				{error && <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />}
			</>
		);
	};

	const modalFooter = isEditingAllowed(event.createdAt)
		? [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleEdit}>
					Save Changes
				</Button>
		  ]
		: [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="support" type="primary" href="mailto:support@kamero.in">
					Contact Support
				</Button>
		  ];

	return (
		<>
			{renderEditButton()}
			<Modal
				title={<Title level={4}>Edit Event ID</Title>}
				open={isModalVisible}
				onCancel={handleCancel}
				footer={modalFooter}>
				{renderModalContent()}
			</Modal>
		</>
	);
};

EditEventIdModal.propTypes = {
	event: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	onEdit: PropTypes.func.isRequired
};

export default EditEventIdModal;
