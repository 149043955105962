import { connect } from 'react-redux'
import {updateSortByMAction} from '../actions/sortByActions'
import SortByComp from '../components/SortByComp'

const mapStateToProps = (state) => ({
    sortBy : state.pouchDB.visibleEvent.sortBy
})

const mapDispatchToProps = (dispatch) => ({
        updateSortBy : (sortBy) => {
            dispatch(updateSortByMAction(sortBy))
        }
    }
)

const SortBySetting = connect(
    mapStateToProps,
    mapDispatchToProps
)(SortByComp)

export default SortBySetting
