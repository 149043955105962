import {swaggerClient} from "../../swagger"

class Watermark{
  static getWatermark = (userId) => {
    return swaggerClient().apis.watermark.get_watermark({
      userId : userId
    }).then((result) => {
      return Promise.resolve(JSON.parse(result.data))
    })
  }

  static updateWatermark(userId, watermark){
    return swaggerClient().apis.watermark.update_watermark({
      userId : userId,
      watermark: watermark
    })
  }
}

export default Watermark
