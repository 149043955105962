import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { RAZOR_CLIENT_KEY } from '../config';

const BASE_OPTIONS = {
	key: RAZOR_CLIENT_KEY,
	name: 'Kamero',
	theme: {
		color: '#6F4898'
	}
};

const RazorPay = ({ Razorpay, orderId, amount, email, mobile, paymentAuthorized }) => {
	useEffect(() => {
		if (!Razorpay) return;

		const options = { ...BASE_OPTIONS };
		options.order_id = orderId;
		options.amount = amount;
		options.handler = (response) => {
			paymentAuthorized(response);
		};
		options.prefill = {
			email: email
		};
		if (mobile) {
			options.prefill.contact = mobile;
		}

		const pay = new Razorpay(options);
		pay.open();

		// Clean up on component unmount
		return () => {
			pay.close();
		};
	}, [Razorpay, orderId, amount, email, mobile, paymentAuthorized]);

	return null;
};

RazorPay.propTypes = {
	Razorpay: PropTypes.func,
	orderId: PropTypes.string.isRequired,
	amount: PropTypes.number.isRequired,
	email: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	paymentAuthorized: PropTypes.func.isRequired
};

export default RazorPay;
