import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PHOTOS_SORT_BY_CLICKED_AT, PHOTOS_SORT_BY_NAME } from '../pouchDB/models/Photo';
import { Menu, Radio, Popconfirm, Select } from 'antd';
import CircleTitle from './CircleTitle';
import { Images } from 'lucide-react';

const MenuItemGroup = Menu.ItemGroup;

class SortByComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	render() {
		const { sortBy, updateSortBy } = this.props;

		let sortByTimeChecked = sortBy === undefined || sortBy === 'clickedAt';
		let sortByNameChecked = sortBy === 'name';

		if (this.state.isMobile) {
			return (
				<div>
					<Select
						size="small"
						onChange={(value) => {
							updateSortBy(value);
						}}
						style={{
							fontSize: '16px',
							color: '#3F3F46',
							fontWeight: 400
						}}
						placeholder="Sort By"
						options={[
							{ label: 'Photo Click Time', value: PHOTOS_SORT_BY_CLICKED_AT },
							{ label: 'Photo FileName', value: PHOTOS_SORT_BY_NAME }
						]}
					/>
				</div>
			);
		}

		return (
			<Menu className="left-menu" selectedKeys={[]} style={{ marginTop: '10px' }}>
				<MenuItemGroup
					style={{ paddingBottom: '0px' }}
					title={
						<div className="event-home-menu-label">
							<Images color="#6F4898" size={20} />
							<h4 style={{ marginLeft: 10 }}>SORT PHOTOS BY</h4>
						</div>
					}>
					<Menu.Item className="sort-by-menu-item" key={1}>
						<Popconfirm
							title="Sort all photos in the Event by Photo click time - as per EXIF data of the camera"
							onConfirm={(e) => {
								e.stopPropagation();
								updateSortBy(PHOTOS_SORT_BY_CLICKED_AT);
							}}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Confirm"
							cancelText="Cancel">
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}>
								<Radio checked={sortByTimeChecked}>Photo Click Time</Radio>
							</div>
						</Popconfirm>
					</Menu.Item>
					<Menu.Item className="sort-by-menu-item" key={2}>
						<Popconfirm
							title="Sort all photos in the Event by ascending order of filenames"
							onConfirm={(e) => {
								e.stopPropagation();
								updateSortBy(PHOTOS_SORT_BY_NAME);
							}}
							onCancel={(e) => {
								e.stopPropagation();
							}}
							okText="Confirm"
							cancelText="Cancel">
							<div
								onClick={(e) => {
									e.stopPropagation();
								}}>
								<Radio checked={sortByNameChecked}>Photo FileName</Radio>
							</div>
						</Popconfirm>
					</Menu.Item>
				</MenuItemGroup>
			</Menu>
		);
	}
}
SortByComp.propTypes = {
	sortBy: PropTypes.string,
	updateSortBy: PropTypes.func.isRequired
};

export default SortByComp;
