import { Input, Select } from 'antd';
import React, { useEffect } from 'react';
import { countryCodes } from '../phoneData';

const { Option } = Select;

const PhoneInput = ({ value = {}, onChange }) => {
	useEffect(() => {
		if (!value.countryCode) {
			onChange?.({ ...value, countryCode: '+91' });
		}
	}, []);

	const triggerChange = (changedValue) => {
		const newValue = { ...value, ...changedValue };
		onChange?.(newValue);
	};

	const onCountryChange = (selectedValue) => {
		triggerChange({ countryCode: selectedValue });
	};

	const onPhoneChange = (e) => {
		const phoneNumber = e.target.value.replace(/\D/g, '');
		triggerChange({ phoneNumber });
	};

	const filterOption = (input, option) => {
		const searchStr = `${option.value} ${option.label}`.toLowerCase();
		return searchStr.includes(input.toLowerCase());
	};

	return (
		<Input.Group compact>
			<Select
				showSearch
				style={{ width: '30%' }}
				value={value.countryCode || '+91'}
				onChange={onCountryChange}
				filterOption={filterOption}
				optionLabelProp="label">
				{countryCodes.map((country) => (
					<Option
						key={country.value}
						value={country.value}
						label={`${country.value} ${country.label}`}>
						<span style={{ fontWeight: 'bold' }}>{country.value}</span> {country.label}
					</Option>
				))}
			</Select>
			<Input
				style={{ width: '70%' }}
				value={value.phoneNumber || ''}
				onChange={onPhoneChange}
				placeholder="Phone number"
				maxLength={10}
			/>
		</Input.Group>
	);
};

export default PhoneInput;
