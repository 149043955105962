import {
    ACTION_VISIBLE_VIDEOLINKS_UPDATED,
    ACTION_VIDEOLINK_CREATED,
    ACTION_VIDEOLINK_UPDATED,
    ACTION_VIDEOLINK_DELETED,
    ACTION_EVENT_CLICKED
} from '../actions/action_types'
import {
    addAtIndexArray,
    removeAtIndexArray,
    updateAtIndexArray
} from './arrayFunctions'

const videoLink = (state = {
    visibleVideoLinks : undefined
}, action) => {
    switch (action.type) {
        case ACTION_EVENT_CLICKED:
            return {
                ...state,
                visibleVideoLinks: undefined
            }
        case ACTION_VISIBLE_VIDEOLINKS_UPDATED:
            return {
                ...state,
                visibleVideoLinks: action.videoLinks
            }
        case ACTION_VIDEOLINK_CREATED:
            return {
                ...state,
                visibleVideoLinks : addAtIndexArray(state.visibleVideoLinks, state.visibleVideoLinks.length, action.videoLink)
            }
        case ACTION_VIDEOLINK_UPDATED:
            return {
                ...state,
                visibleVideoLinks : updateAtIndexArray(state.visibleVideoLinks, action.videoLinkIndex, action.videoLink)
            }
        case ACTION_VIDEOLINK_DELETED:
            return {
                ...state,
                visibleVideoLinks : removeAtIndexArray(state.visibleVideoLinks, action.videoLinkIndex)
            }
        default:
            return state;
    }
}

export default videoLink
