import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EVENT_NAME_LENGTH } from './CreateEventComp';
import PhotoComp from './PhotoComp';
import TextFieldComp from './TextFieldComp';

import RenewEventModal from './RenewEventModal';
import RenewalLinkModal from './RenewalLinkModal';

import { Card } from 'antd';
import { IMAGES_URL } from '../config';

import { ReactComponent as IndicatorSVG } from '../svg/Indicator.svg';
import { ReactComponent as UploadSVG } from '../svg/Upload.svg';
import DeleteEventModal from './DeleteEventModal';
import DownloadAllPhotosModal from './DownloadAllPhotosModal';

import EditEventIdModal from './EditEventIdModal';
import EditEventNameModal from './EditEventNameModal';

class EventComp extends Component {
	eventHeaderNoPhotos() {}

	getEventHeader(state, photo) {
		if (photo) {
			return <PhotoComp className="event-card-header-photo" photo={photo} eventThumbnail />;
		}

		if (state === 'created') {
			return (
				<div className="event-card-header-nophoto">
					<div className="event-card-header-nophoto-child">
						<UploadSVG />
					</div>
					<div className="event-card-header-nophoto-child event-card-header-text">
						Upload Photos
					</div>
				</div>
			);
		}

		if (state === 'rejected') {
			return (
				<div className="event-card-header-nophoto">
					<div className="event-card-header-nophoto-child">
						<h5 className="event-card-error">Event rejected.</h5>
						<h5 className="event-card-error">
							Please{' '}
							<div
								onClick={(e) => {
									e.preventDefault();
									window.open('https://kamero.in/#contact', '_blank');
								}}>
								contact us
							</div>{' '}
							to create manually.
						</h5>
					</div>
				</div>
			);
		}

		return (
			<div className="event-card-header-nophoto">
				<div className="event-card-header-nophoto-child">
					<IndicatorSVG className="create-event-indicator" />
				</div>
				<div className="event-card-header-nophoto-child event-card-header-text">
					Creating event...
				</div>
			</div>
		);
	}

	getEditPopOver(name) {
		return (
			<div>
				<p>Edit Event Name</p>
				<TextFieldComp
					shouldAutoFocus
					maxLength={EVENT_NAME_LENGTH}
					placeholder="New Event Name"
					initialValue={name}
					textChanged={(eventName) => {
						this.newEventName = eventName;
					}}
				/>
			</div>
		);
	}

	getEditHashIdPopOver(eventId) {
		return (
			<div>
				<p>Set Event Id</p>
				<TextFieldComp
					shouldAutoFocus
					maxLength={EVENT_NAME_LENGTH}
					placeholder="New Event ID"
					initialValue={eventId}
					textChanged={(eventId) => {
						this.newEventID = eventId;
					}}
				/>
			</div>
		);
	}

	handleRenewed = () => {
		this.props.refreshEvents();
	};

	handleEventDeleted = () => {
		this.props.refreshEvents();
	};

	render() {
		const {
			// id,
			eventId,
			name,
			maxPhotos,
			guestMaxPhotos,
			// createdAt,
			expiresAt,
			state,
			coverPhotoS3Key
		} = this.props.event;

		const { onClick, photoCount, userId, deleteEvent, eventNameEdited, setEventId, isExpired } =
			this.props;

		console.log(eventId, expiresAt, 'is expired', isExpired);

		// const createdAtDate = new Date(createdAt);
		const expiresAtDate = new Date(expiresAt);

		const expiresAtShort =
			expiresAtDate.getDate() +
			'/' +
			(expiresAtDate.getMonth() + 1) +
			'/' +
			expiresAtDate.getFullYear().toString().substr(2, 2);

		let photoDetail;
		if (photoCount !== undefined) {
			photoDetail = photoCount + '/' + (maxPhotos - (guestMaxPhotos || 0)) + ' Photos';
		} else {
			photoDetail = '-- /' + maxPhotos + ' Photos Event';
		}

		const defaultCoverImageUrl =
			'https://kamero-public.s3.ap-south-1.amazonaws.com/logos/no-event-cover.jpeg';

		let coverImageUrl = defaultCoverImageUrl;
		if (coverPhotoS3Key) {
			coverImageUrl =
				IMAGES_URL + '/1/none/high/poster/' + '?key=' + encodeURIComponent(coverPhotoS3Key);
		}

		return (
			<div className={`event-card-wrapper ${isExpired ? 'expired' : ''}`}>
				<Card
					cover={
						<div style={{ position: 'relative' }}>
							<img
								alt="Event Cover Photo"
								src={coverImageUrl}
								style={{
									minHeight: '260px',
									maxHeight: '260px',
									width: '100%',
									objectFit: 'cover'
								}}
							/>
							{!isExpired && <ExpiryOverlay expiryDate={expiresAtShort} />}
						</div>
					}
					actions={[
						<>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
								<div className="event-card-subdetail-right"></div>
								<p
									className="event-card-subdetail-right"
									style={{
										fontSize: '16px',
										color: '#27272A',
										fontWeight: '300'
									}}>
									ID : {eventId}
								</p>
								<div onClick={(e) => e.stopPropagation()}>
									<EditEventIdModal event={this.props.event} userId={userId} onEdit={setEventId} />
								</div>
							</div>
						</>,

						<p
							className="event-card-subdetail-left"
							style={{
								fontSize: '16px',
								color: '#27272A',
								fontWeight: '300'
							}}>
							{photoDetail}
						</p>
					]}
					hoverable
					className={`event-card ${isExpired ? 'expired' : ''}`}
					onClick={(e) => {
						if (!isExpired) {
							onClick();
						}
					}}>
					{state === 'created' || state === 'rejected' ? (
						<div
							className="event-card-detail"
							style={{ marginTop: '-20px', marginBottom: '-22px' }}>
							<h3 className="event-card-detail-title-child event-card-subdetail">
								<span className="event-name">{name}</span>
								<div
									className="event-actions"
									onClick={(e) => {
										e.stopPropagation();
									}}>
									<EditEventNameModal
										event={this.props.event}
										userId={userId}
										onEdit={eventNameEdited}
									/>
									<DeleteEventModal
										event={this.props.event}
										userId={userId}
										deleteEvent={deleteEvent}
										onDeleted={this.handleEventDeleted}
										type="icon"
									/>
								</div>
							</h3>
						</div>
					) : (
						<div className="event-card-detail">
							<h3 className="event-card-detail-title-child">{'Creating ' + name + ' ...'}</h3>
						</div>
					)}
				</Card>
				{isExpired && (
					<div className="expired-overlay">
						<ExpiredOverlay expiryDate={expiresAtShort} />
						<RenewEventModal event={this.props.event} onRenewed={this.handleRenewed} />
						<DeleteEventModal
							event={this.props.event}
							userId={userId}
							deleteEvent={deleteEvent}
							onDeleted={this.handleEventDeleted}
						/>
						<RenewalLinkModal event={this.props.event} />
						<DownloadAllPhotosModal event={this.props.event} />
					</div>
				)}
			</div>
		);
	}
}

EventComp.propTypes = {
	onClick: PropTypes.func.isRequired,
	event: PropTypes.object.isRequired,
	photoCount: PropTypes.number,
	latestPhoto: PropTypes.object,
	eventNameEdited: PropTypes.func.isRequired,
	setEventId: PropTypes.func.isRequired,
	deleteEvent: PropTypes.func.isRequired,
	editEventExpiry: PropTypes.func.isRequired,
	userId: PropTypes.string,
	refreshEvents: PropTypes.func.isRequired,
	isExpired: PropTypes.bool.isRequired
};

const mapStateToProps = (state, ownProps) => ({
	userId: state.auth.userId,
	photoCount: state.pouchDB.photoCounts[ownProps.event.id]
		? state.pouchDB.photoCounts[ownProps.event.id].count
		: undefined,
	latestPhoto: state.pouchDB.photoCounts[ownProps.event.id]
		? state.pouchDB.photoCounts[ownProps.event.id].photo
		: undefined
});

export default connect(mapStateToProps)(EventComp);

const ExpiryOverlay = ({ expiryDate }) => (
	<div className="expiry-overlay">
		<span>Valid upto: {expiryDate}</span>
	</div>
);

const ExpiredOverlay = ({ expiryDate }) => (
	<div className="expiry-overlay expired">
		<span>Expired on: {expiryDate}</span>
	</div>
);
