import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Table, InputNumber, Spin, Alert, Row, Button, message, Checkbox, Col } from 'antd';
import Product from '../pouchDB/models/Product';
import Order from '../pouchDB/models/Order';
import RazorPayWrapper from '../containers/RazorPayWrapper';
import FullScreenIndicator from './FullScreenIndicator';
import { PRODUCT_TYPE } from '../pouchDB/models/Product';
import { fetchBillingDetailsMAction } from '../actions/BillingDetailsActions';
import AddBillingDetailsForm from './AddBillingDetails';

var classNames = require('classnames');

class BuyProductsComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			products: undefined,
			loading: true,
			paymentInProgress: false,
			checkoutInProgress: false,
			error: undefined,
			orderItems: [],
			order: undefined,
			totalCost: 0,
			orderTotal: 0,
			payableAmount: 0,
			shouldUseWallet: true,
			billingDetails: null,
			showAddBillingForm: false,
			isMobile: window.screen.width <= 576
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	componentDidMount() {
		const { eventId, productType } = this.props;
		switch (productType) {
			case PRODUCT_TYPE.photos_pack:
				if (eventId) {
					this.columns.splice(2, 1);
				}
				break;
			case PRODUCT_TYPE.facial_support:
				this.columns.splice(1, 1);
				break;
			default:
				break;
		}
		this.fetchProducts();
		this.fetchBillingDetails();
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	fetchProducts = async () => {
		const { userId, fetchProducts } = this.props;
		try {
			let products = await fetchProducts(userId);
			if (products.length > 0) {
				products = products.map((product, idx) => {
					product.index = idx + 1;
					return product;
				});
				this.setState({ loading: false, products: products });
			} else {
				this.setState({ loading: false, error: 'No Packs to buy!' });
			}
		} catch (err) {
			this.setState({ loading: false, error: err.message });
		}
	};

	fetchBillingDetails = async () => {
		const { fetchBillingDetails } = this.props;
		try {
			const billingDetails = await fetchBillingDetails();
			this.setState({ billingDetails, loading: false });
		} catch (error) {
			console.error('Error fetching billing details:', error);
			// message.warning(
			// 	'You have not added billing details yet. Please add billing details for proper invoice.'
			// );
			this.setState({ loading: false });
		}
	};

	columns = [
		{
			title: 'Pack',
			dataIndex: 'index',
			align: 'center',
			responsive: ['md']
		},
		{
			title: 'Photos',
			dataIndex: 'photos',
			align: 'center'
		},
		{
			title: 'Validity',
			dataIndex: 'validity',
			align: 'center',
			render: (validity) => <p>{validity + ' Months'}</p>,
			responsive: ['md']
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			align: 'center',
			render: (cost) => (
				<p>
					{(cost / 100).toLocaleString('en-IN', {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
						style: 'currency',
						currency: 'INR'
					})}
				</p>
			)
		},
		{
			title: 'Quantity',
			key: 'quantity',
			width: 60,
			align: 'center',
			render: (_, record, index) => (
				<InputNumber
					type="number"
					size="large"
					min={0}
					max={this.props.productType === PRODUCT_TYPE.facial_support ? 1 : 50}
					defaultValue={0}
					value={this.currentQuantity(record)}
					onChange={(value) => {
						let orderItems = this.state.orderItems;
						let index = orderItems.findIndex((item) => item.productId === record.id);
						let newItem = {
							quantity: value,
							productId: record.id
						};
						if (index >= 0) {
							if (value === 0) {
								orderItems.splice(index, 1);
							} else {
								orderItems[index] = newItem;
							}
						} else {
							orderItems.push(newItem);
						}
						this.setState({ orderItems: orderItems, totalCost: this.totalCost() });
					}}
				/>
			)
		}
	];

	currentQuantity = (record) => {
		const { orderItems } = this.state;
		let index = orderItems.findIndex((item) => item.productId === record.id);

		if (index < 0) {
			return 0;
		}
		return orderItems[index].quantity;
	};

	productComp = (products) => {
		return (
			<Table
				className="purchase-table"
				columns={this.columns}
				rowKey={(record) => record.id}
				dataSource={products}
				pagination={false}
				// footer={() => this.costComp()}
				footer={() => this.summeryComp()}
			/>
		);
	};

	totalCost = () => {
		const { walletBalance } = this.props;

		const { orderItems, shouldUseWallet, products } = this.state;

		var subTotal = orderItems.reduce(
			(total, item) =>
				total +
				item.quantity *
					products[products.findIndex((product) => product.id === item.productId)].cost,
			0
		);

		console.log('orderItems', orderItems);
		console.log('walletbalance vvvvv', walletBalance);

		var taxableAmount = subTotal;
		var creditsUsed = 0;
		var onlyWalletBalanceSufficient = false;

		if (walletBalance && shouldUseWallet) {
			if (subTotal <= walletBalance) {
				onlyWalletBalanceSufficient = true;
				taxableAmount = 0;
				creditsUsed = subTotal;
			} else {
				taxableAmount = subTotal - walletBalance;
				creditsUsed = walletBalance;
			}
		}

		var gstAmount = taxableAmount * 0.18;
		var orderTotal = subTotal + gstAmount;

		var payableAmount = orderTotal - creditsUsed;

		this.setState({
			orderItems,
			subTotal,
			taxableAmount,
			gstAmount,
			creditsUsed,
			onlyWalletBalanceSufficient,
			payableAmount,
			orderTotal
		});

		console.log('subTotal x ', subTotal);
		console.log('taxableAmount x ', taxableAmount);
		console.log('gstAmount x ', gstAmount);
		console.log('totalCost x', orderTotal);
		console.log('creditsUsed x ', creditsUsed);
		console.log('onlyWalletBalanceSufficient x', onlyWalletBalanceSufficient);
		console.log('payableAmount x', payableAmount);
	};

	summeryComp = () => {
		const {
			orderItems,
			products,
			billingDetails,
			payableAmount,
			creditsUsed,
			gstAmount,
			subTotal
		} = this.state;

		const gstRate = 18;

		// Check if there are any products in the order
		if (orderItems.length === 0) {
			return <div>{this.costComp()}</div>;
		}

		const tableData = orderItems.map((item, index) => {
			const product = products[products.findIndex((p) => p.id === item.productId)];
			return {
				index: index + 1,
				name: product.photos,
				cost: product.cost,
				quantity: item.quantity,
				subTotal: (item.quantity * product.cost) / 100,
				gstAmount: (item.quantity * product.cost * gstRate) / 10000,
				total: (item.quantity * product.cost * 1.18) / 100
			};
		});

		const totalRow = (
			<Row type="flex" justify="center" align="middle" style={{ marginTop: '10px' }}>
				<Col xs={24} sm={24} md={24} style={{ textAlign: 'right' }}>
					<div>
						<p style={{ fontSize: '16px', marginBottom: '5px' }}>
							Subtotal :{' '}
							<span style={{ fontSize: '16px' }}>
								{(subTotal / 100).toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
									style: 'currency',
									currency: 'INR'
								})}
							</span>
						</p>
						{creditsUsed ? (
							<p style={{ fontSize: '16px', marginBottom: '5px' }}>
								Credits Used :{' '}
								<span style={{ fontSize: '16px' }}>
									-{' '}
									{(creditsUsed / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</span>
							</p>
						) : null}
						{gstAmount ? (
							<p style={{ fontSize: '16px', marginBottom: '5px' }}>
								GST (18%) :{' '}
								<span style={{ fontSize: '16px' }}>
									+{' '}
									{(gstAmount / 100).toLocaleString('en-IN', {
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
										style: 'currency',
										currency: 'INR'
									})}
								</span>
							</p>
						) : null}
						<p style={{ fontSize: '16px' }}>
							Total :{' '}
							<span style={{ fontSize: '16px' }}>
								{(payableAmount / 100).toLocaleString('en-IN', {
									minimumFractionDigits: 2,
									maximumFractionDigits: 2,
									style: 'currency',
									currency: 'INR'
								})}
							</span>
						</p>
					</div>
				</Col>
			</Row>
		);

		const columns = [
			{
				title: 'No.',
				dataIndex: 'index',
				width: creditsUsed ? null : 30,
				align: 'center',
				responsive: ['md']
			},
			{ title: 'Photos', dataIndex: 'name', width: creditsUsed ? null : 50, align: 'center' },
			{
				title: 'Price',
				dataIndex: 'cost',
				align: 'center',
				responsive: ['md'],
				render: (cost) => (
					<p>
						{cost !== '-'
							? (cost / 100).toLocaleString('en-IN', {
									minimumFractionDigits: 0,
									maximumFractionDigits: 0,
									style: 'currency',
									currency: 'INR'
							  })
							: '-'}
					</p>
				)
			},
			{
				title: 'Qty',
				dataIndex: 'quantity',
				width: creditsUsed ? null : 50,
				align: 'center',
				align: 'center'
			},
			{
				title: 'Subtotal',
				align: 'center',
				responsive: ['md'],
				render: (_, record) => (
					<p>
						{record.subTotal.toLocaleString('en-IN', {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
							style: 'currency',
							currency: 'INR'
						})}
					</p>
				)
			}
		];

		if (!creditsUsed) {
			columns.push(
				{
					title: 'GST (18%)',
					align: 'center',
					render: (_, record) => (
						<p>
							{record.gstAmount.toLocaleString('en-IN', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
								style: 'currency',
								currency: 'INR'
							})}
						</p>
					)
				},
				{
					title: 'Total',
					align: 'center',
					render: (_, record) => (
						<p>
							{record.total.toLocaleString('en-IN', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
								style: 'currency',
								currency: 'INR'
							})}
						</p>
					)
				}
			);
		}

		return (
			<>
				<Row type="flex" justify="space-between" align="middle">
					<Col span={24}>
						<div className="invoice-container">
							<h4>Purchase Summery</h4>
							<Table
								columns={columns}
								dataSource={tableData}
								pagination={false}
								components={{
									body: {
										row: (props) => (
											<tr
												{...props}
												style={{
													backgroundColor:
														props.index === tableData.length - 1 ? '#f0f0f0' : 'inherit'
												}}
											/>
										)
									}
								}}
							/>
						</div>
						{totalRow}

						<div>
							{billingDetails ? null : (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										marginTop: '10px',
										marginBottom: '10px'
									}}>
									<div style={{ display: 'inline-flex', alignItems: 'center' }}>
										<p style={{ marginRight: '10px' }}>
											Add Billing Details first if you want GST Invoice :
										</p>
										<Button
											type="dashed"
											className="pageRightDetail"
											onClick={() => this.setState({ showAddBillingForm: true })}>
											Add Billing Details
										</Button>
									</div>
								</div>
							)}
						</div>

						{this.state.showAddBillingForm ? (
							<AddBillingDetailsForm
								editingBillingDetails={billingDetails ? billingDetails : null}
								onClose={() =>
									this.setState({ showAddBillingForm: false, editingBusiness: undefined })
								}
							/>
						) : null}
					</Col>
				</Row>
				<div>{this.costComp()}</div>
			</>
		);
	};

	costComp = () => {
		const { paymentInProgress, walletBalance } = this.props;
		const { payableAmount, shouldUseWallet, orderTotal, onlyWalletBalanceSufficient, creditsUsed } =
			this.state;

		return (
			<Row type="flex" justify={'space-between'} align="middle">
				{walletBalance >= 0 ? (
					<Checkbox
						checked={shouldUseWallet}
						onChange={(e) => {
							this.setState({ shouldUseWallet: e.target.checked }, () => this.totalCost());
						}}>
						<span style={{ fontSize: '18px', fontWeight: 400 }}>Use Credits</span>
						<span style={{ fontSize: '16px', fontWeight: 500 }}>
							(
							{(walletBalance / 100).toLocaleString('en-IN', {
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
								style: 'currency',
								currency: 'INR'
							})}
							)
						</span>
					</Checkbox>
				) : null}
				<Row type="flex" justify="end" align="middle">
					<h3 style={{ marginRight: '4px' }}>Total:</h3>
					{creditsUsed ? (
						<h2
							style={{
								marginRight: '16px',
								textDecoration: creditsUsed > 0 ? 'line-through' : 'none'
							}}>
							{(orderTotal / 100).toLocaleString('en-IN', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
								style: 'currency',
								currency: 'INR'
							})}
						</h2>
					) : null}

					<h2 style={{ marginRight: '16px' }}>
						{(payableAmount / 100).toLocaleString('en-IN', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
							style: 'currency',
							currency: 'INR'
						})}
					</h2>

					<Button
						type="primary"
						disabled={orderTotal === 0}
						onClick={(e) => {
							e.preventDefault();
							if (paymentInProgress) {
								return;
							}
							this.placeOrder();
						}}>
						{onlyWalletBalanceSufficient ? 'Add Packs' : 'Buy Now'}
					</Button>
				</Row>
			</Row>
		);
	};

	placeOrder = async () => {
		const { userId, placeOrder, eventId } = this.props;
		const { orderItems } = this.state;

		if (orderItems.length === 0) {
			return;
		}

		try {
			this.setState({ paymentInProgress: true });
			let order = await placeOrder(userId, orderItems, this.state.shouldUseWallet, eventId);
			if (order.razorPayOrderId) {
				this.setState({ checkoutInProgress: true, order: order });
			} else {
				await this.confirmOrder(order.id, undefined, undefined);
			}
		} catch (err) {
			message.error(err.message);
			this.setState({ paymentInProgress: false, orderItems: [], totalCost: 0 });
		}
	};

	checkout(order) {
		const { email, mobile } = this.props;
		const { totalCost } = this.state;

		console.log('order', order);
		console.log('totalCost', totalCost);

		let props = {
			email: email,
			mobile: mobile,
			amount: totalCost,
			orderId: order.razorPayOrderId,
			paymentAuthorized: (response) => {
				this.confirmOrder(order.id, response.razorpay_payment_id, response.razorpay_signature);
			}
		};
		return (
			<RazorPayWrapper
				asyncScriptOnLoad={() =>
					this.setState({
						checkoutInProgress: false,
						order: undefined,
						paymentInProgress: false,
						orderItems: [],
						totalCost: 0
					})
				}
				{...props}
			/>
		);
	}

	confirmOrder = async (orderId, paymentId, signature) => {
		const { userId, confirmOrder, purchaseNeedsUpdate, eventId } = this.props;
		try {
			this.setState({ paymentInProgress: true });
			await confirmOrder(userId, orderId, paymentId, signature, eventId);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false,
				orderItems: [],
				totalCost: 0
			});
			purchaseNeedsUpdate();
		} catch (err) {
			message.error(err.message, 15);
			this.setState({
				checkoutInProgress: false,
				order: undefined,
				paymentInProgress: false,
				orderItems: [],
				totalCost: 0
			});
		}
	};

	render() {
		const { className } = this.props;
		const { products, error, paymentInProgress, checkoutInProgress, order } = this.state;
		return (
			<div className={classNames(className, 'buy-pack')} style={{ margin: 0, minWidth: 0 }}>
				<Spin spinning={this.state.loading}>
					{error ? <Alert message={error} type="error" /> : null}
					{products ? this.productComp(products) : null}
					{paymentInProgress ? <FullScreenIndicator /> : null}
					{checkoutInProgress && order ? this.checkout(order) : null}
				</Spin>
			</div>
		);
	}
}

BuyProductsComp.propTypes = {
	userId: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	whitelabelId: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	fetchProducts: PropTypes.func.isRequired,
	placeOrder: PropTypes.func.isRequired,
	confirmOrder: PropTypes.func.isRequired,
	purchaseNeedsUpdate: PropTypes.func.isRequired,
	eventId: PropTypes.string,
	productType: PropTypes.number.isRequired,
	walletBalance: PropTypes.number
};

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId,
		whitelabelId: state.auth.whitelabelId,
		email: state.auth.email,
		phone: state.auth.phone
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	fetchProducts: async (userId) => {
		return await Product.fetchProducts(userId, ownProps.productType);
	},
	fetchBillingDetails: () => {
		return dispatch(fetchBillingDetailsMAction());
	},
	placeOrder: async (userId, orderItems, useWallet, eventId) => {
		return await Order.placeOrder(userId, orderItems, useWallet, eventId);
	},
	confirmOrder: async (userId, orderId, razorPayPaymentId, signature, eventId) => {
		return await Order.confirmOrder(userId, orderId, razorPayPaymentId, signature, eventId);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BuyProductsComp);
