//auth
export const ACTION_LOGIN_START_ON_BUTTON_TAP = 'LOGIN_START_ON_BUTTON_TAP';
export const ACTION_LOGIN_FAILED = 'LOGIN_FAILED';
export const ACTION_CLEAR_LOGIN_ERR = 'CLEAR_LOGIN_ERR';
export const ACTION_LOGGED_OUT = 'LOGGED_OUT';
export const ACTION_LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ACTION_NEEDS_LOGIN = 'ACTION_NEEDS_LOGIN';
export const ACTION_UPDATE_CLICKED_ALBUM = 'ACTION_UPDATE_CLICKED_ALBUM';
export const ACTION_UPDATE_PHONE_NUMBER = 'ACTION_UPDATE_PHONE_NUMBER';

//Replication
export const ACTION_REPLICATION_CONFIGURED = 'REPLICATION_CONFIGURED';
export const ACTION_CONTINUOUS_REPLICATION_STATE_CHANGED = 'CONTINUOUS_REPLICATION_STATE_CHANGED';

//Couchbase DB

export const ACTION_TOTAL_EVENTS_UPDATED = 'TOTAL_EVENTS_UPDATED';
export const ACTION_SEARCH_EVENTS_UPDATED = 'SEARCH_EVENTS_UPDATED';
export const ACTION_MY_EVENTS_UPDATED = 'MY_EVENTS_UPDATED';
export const ACTION_EVENT_CLICKED = 'EVENT_CLICKED';
export const ACTION_EVENT_UPDATED = 'EVENT_UPDATED';
export const ACTION_VISIBLE_EVENT_UPDATED = 'VISIBLE_EVENT_UPDATED';
export const ACTION_EVENT_HOME_UPDATED = 'EVENT_HOME_UPDATED';
export const ACTION_ALBUM_HOME_UPDATED = 'ALBUM_HOME_UPDATED';
export const ACTION_ALBUM_GUEST_UPDATED = 'ACTION_ALBUM_GUEST_UPDATED';
export const ACTION_VISIBLE_ALBUMS_UPDATED = 'VISIBLE_ALBUMS_UPDATED';
export const ACTION_ALBUM_CREATED = 'ALBUM_CREATED';
export const ACTION_ALBUM_UPDATED = 'ALBUM_UPDATED';
export const ACTION_ALBUM_DELETED = 'ALBUM_DELETED';
export const ACTION_ALBUM_CLICKED = 'ALBUM_CLICKED';
export const ACTION_VISIBLE_PHOTOS_PAGE_UPDATED = 'VISIBLE_PHOTOS_PAGE_UPDATED';
export const ACTION_VISIBLE_PHOTOS_UPDATED = 'VISIBLE_PHOTOS_UPDATED';
export const ACTION_PHOTO_COUNT_UPDATED = 'PHOTO_COUNT_UPDATED';
export const ACTION_ALBUM_DELETE_STARTED = 'ACTION_ALBUM_DELETE_STARTED';
export const ACTION_ALBUM_DELETE_FINISHED = 'ACTION_ALBUM_DELETE_FINISHED';
export const ACTION_API_ERROR_OCCURED = 'API_ERROR_OCCURED';
export const ACTION_GUEST_UPLOAD_REFRESHING = 'ACTION_GUEST_UPLOAD_REFRESHING';
export const ACTION_EXPIRED_EVENTS_UPDATED = 'EXPIRED_EVENTS_UPDATED';
export const ACTION_EXPIRED_FACES_EVENTS_UPDATED = 'ACTION_EXPIRED_FACES_EVENTS_UPDATED';

//Upload
export const ACTION_UPLOAD_STARTED = 'UPLOAD_STARTED';
export const ACTION_UPLOAD_FINISHED = 'UPLOAD_FINISHED';
export const ACTION_UPLOAD_PROGRESS_UPDATED = 'UPLOAD_PROGRESS_UPDATED';
export const ACTION_UPLOAD_ERROR = 'UPLOAD_ERROR';

//Create Event
export const ACTION_CREATE_EVENT_WITH_NAME = 'CREATE_EVENT_WITH_NAME';
export const ACTION_CREATE_EVENT_PACK_CHOSEN = 'CREATE_EVENT_PACK_CHOSEN';
export const ACTION_CREATE_EVENT_PAYMENT_AUTHORIZED = 'CREATE_EVENT_PAYMENT_AUTHORIZED';

//Local Create Profile Actions
export const ACTION_UPDATE_ABOUT_COMPANY = 'ACTION_UPDATE_ABOUT_COMPANY';
export const ACTION_UPDATE_CURRENT_PAGE = 'ACTION_UPDATE_CURRENT_PAGE';
export const ACTION_UPDATE_COMPANY_NAME = 'ACTION_UPDATE_COMPANY_NAME';
export const ACTION_UPDATE_PERSONAL_DETAILS = 'ACTION_UPDATE_PERSONAL_DETAILS';
export const ACTION_UPDATE_BRAND_COLOR = 'ACTION_UPDATE_BRAND_COLOR';
export const ACTION_UPDATE_SOCIAL_LINKS = 'ACTION_UPDATE_SOCIAL_LINKS';
export const ACTION_UPDATE_WHATSAPP_DETAILS = 'ACTION_UPDATE_WHATSAPP_DETAILS';
export const ACTION_UPDATE_EVENT_ID = 'ACTION_UPDATE_EVENT_ID';
export const UPDATE_PROFILE_PHOTO_LINK = 'UPDATE_PROFILE_PHOTO_LINK';
export const UPDATE_COVER_PHOTO_LINK = 'UPDATE_COVER_PHOTO_LINK';

//API create profile actions
export const GET_PROFILE_DATA_STARTED = 'ACTION_GET_PROFILE_DATA';
export const ACTION_GET_PROFILE_DATA = 'ACTION_GET_PROFILE_DATA';
export const UPDATE_PROFILE_IF_CREATED = 'UPDATE_PROFILE_IF_CREATED';
export const GET_PROFILE_DATA_FAILED = 'ACTION_GET_PROFILE_DATA';
export const ACTION_PROFILE_CREATION_STARTED = 'ACTION_PROFILE_CREATION_STARTED';
export const ACTION_PROFILE_CREATION_SUCCESS = 'ACTION_PROFILE_CREATION_SUCCESS';
export const ACTION_PROFILE_CREATION_FAILED = 'ACTION_PROFILE_CREATION_FAILED';
export const ACTION_PROFILE_EDIT_STARTED = 'ACTION_PROFILE_EDIT_STARTED';
export const ACTION_PROFILE_EDIT_SUCESS = 'ACTION_PROFILE_EDIT_SUCESS';
export const ACTION_PROFILE_EDIT_FAILED = 'ACTION_PROFILE_EDIT_SUCESS';
export const ACTION_PROFILE_NOT_CREATED = 'ACTION_PROFILE_NOT_CREATED';
export const ACTION_PHOTO_UPLOAD_STARTED = 'ACTION_PHOTO_UPLOAD_STARTED';
export const ACTION_PHOTO_UPLOAD_FAILED = 'ACTION_PHOTO_UPLOAD_FAILED';

//Video Link
export const ACTION_VISIBLE_VIDEOLINKS_UPDATED = 'ACTION_VISIBLE_VIDEOLINKS_UPDATED';
export const ACTION_VIDEOLINK_CREATED = 'ACTION_VIDEOLINK_CREATED';
export const ACTION_VIDEOLINK_UPDATED = 'ACTION_VIDEOLINK_UPDATED';
export const ACTION_VIDEOLINK_DELETED = 'ACTION_VIDEOLINK_DELETED';

//Posts
export const ACTION_VISIBLE_POSTS_UPDATED = 'ACTION_VISIBLE_POSTS_UPDATED';
export const ACTION_POST_CREATED = 'ACTION_POST_CREATED';
export const ACTION_POST_UPDATED = 'ACTION_POST_UPDATED';
export const ACTION_POST_DELETED = 'ACTION_POST_DELETED';

//Billing Details
export const ACTION_BILLING_DETAILS_CREATED = 'ACTION_BILLING_DETAILS_CREATED';
export const ACTION_BILLING_DETAILS_UPDATED = 'ACTION_BILLING_DETAILS_UPDATED';
export const ACTION_BILLING_DETAILS_FETCHED = 'ACTION_BILLING_DETAILS_FETCHED';

//Invoice
export const ACTION_INVOICE_DOWNLOAD_SUCCESS = 'ACTION_INVOICE_DOWNLOAD_SUCCESS';
export const ACTION_INVOICE_DOWNLOAD_FAILURE = 'ACTION_INVOICE_DOWNLOAD_FAILURE';
