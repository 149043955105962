import React from 'react';
import { createRoot } from 'react-dom/client';
import configureStore from './store/configureStore';
import Root from './containers/Root';
import { BrowserRouter as Router } from 'react-router-dom';
import { clearEventHome } from './actions/pouchDBActions';
import { createBrowserHistory } from 'history';
import { ConfigProvider } from 'antd';
import { theme } from './theme';

function noop() {}

console.log('Environment: ' + process.env.REACT_APP_KAMERO_ENV);
if (process.env.REACT_APP_KAMERO_ENV === 'production') {
	console.log = noop;
	console.warn = noop;
	console.error = noop;
	console.timeEnd = noop;
	console.time = noop;
}

const history = createBrowserHistory();
export const store = configureStore(history);

const root = createRoot(document.getElementById('root'));
root.render(
	<ConfigProvider theme={theme}>
		<Router>
			<Root store={store} history={history} />
		</Router>
	</ConfigProvider>
);

let watchingHistory = false;
const watchHistory = () => {
	if (history && watchingHistory === false) {
		watchingHistory = true;
		history.listen((location) => {
			// You can also subscribe to changes in the location!
			if (location.pathname === '/events') {
				console.log('History Event: /events');
				clearEventHome(store.dispatch, store.getState);
			}
		});
	}
};

watchHistory();
