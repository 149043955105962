import { Component } from 'react';
import { connect } from 'react-redux';
import { message, Modal, Tabs, Table, Divider, Button, Space } from 'antd';
import PropTypes from 'prop-types';
import Event from '../pouchDB/models/Event';
import FullScreenIndicator from './FullScreenIndicator';
import '../styles/EventShare.sass';

const { TabPane } = Tabs;

class AdminRequest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			activeTab: '1'
		};
		this.handleWindowResize = this.handleWindowResize.bind(this);
	}

	async componentDidMount() {
		await this.fetchData();
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	fetchData = async () => {
		const { event, fetchAdminRequestList, onComplete } = this.props;
		console.log(event, 'userId');
		try {
			this.setState({ loading: true });
			let fetchAdmin = await fetchAdminRequestList(event.userId, event.id);
			if (fetchAdmin) {
				this.setState({ loading: false, fetchAdmin: fetchAdmin });
			} else {
				this.setState({ loading: false });
				message.error('Failed to get sharing details. Please try again.');
				onComplete();
			}
		} catch (err) {
			this.setState({ loading: false });
			message.error(err.message);
			onComplete();
		}
	};

	adminRequest = async (index, recordId, type) => {
		const { event, onComplete, adminPermission } = this.props;
		try {
			this.setState({ loading: true });
			let fetchPermission = await adminPermission(event.userId, event.id, recordId, type);
			if (fetchPermission) {
				this.setState({ loading: false, fetchPermission: fetchPermission });
				message.success('Status Changed Successfully');
				onComplete();
			} else {
				this.setState({ loading: false });
				onComplete();
			}
		} catch (err) {
			this.setState({ loading: false });
			message.error(err.message);
			onComplete();
		}
	};

	columns = [
		{
			title: 'User Name',
			dataIndex: 'userName',
			align: 'left'
		},
		{
			title: 'Email',
			dataIndex: 'userId',
			align: 'left'
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			render: (_, record, index, state) =>
				record.state === 1 ? (
					<Space>
						<div className="permissionButton">
							<Button
								style={{ backgroundColor: 'green', color: 'white' }}
								onClick={() => this.adminRequest(index, record.id, 'approve')}>
								Approve
							</Button>
						</div>
						<Divider type="vertical" />
						<div className="permissionButton">
							<Button type="primary" onClick={() => this.adminRequest(index, record.id, 'reject')}>
								Reject
							</Button>
						</div>

						{/* <Popconfirm
						title={this.deletePopOver}
						icon={<WarningOutlined />}
						
						okText="Delete Anyway!"
						cancelText="Cancel">
						<div className="anchorDiv">Delete</div>
					</Popconfirm> */}
					</Space>
				) : record.state === 3 ? (
					<span>
						<div style={{ color: 'red' }}>Rejected</div>
					</span>
				) : record.state === 2 ? (
					<span>
						<div style={{ color: 'green' }}>Approved</div>
					</span>
				) : (
					''
				)
		}
	];
	adminListModal = (fetchAdmin) => {
		const { onComplete } = this.props;
		console.log(fetchAdmin, 'fetchAdmin');

		return (
            (<Modal width={800} title="Admin Request" open footer={null} onCancel={() => onComplete()}>
                <div style={{ width: '100%', overflowX: 'auto' }}>
					<Table
						className="facial-support-dialog-table"
						rowKey={(record) => record.id}
						columns={this.columns}
						dataSource={fetchAdmin}
						pagination={false}
						loading={fetchAdmin === undefined}
					/>
				</div>
            </Modal>)
        );
	};

	render() {
		return (
			<div>
				{this.state.fetchAdmin ? this.adminListModal(this.state.fetchAdmin) : null}
				{this.state.loading ? <FullScreenIndicator /> : null}
			</div>
		);
	}
}

AdminRequest.propTypes = {
	fetchAdmin: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			userId: PropTypes.string.isRequired,
			userName: PropTypes.string.isRequired,
			state: PropTypes.string.isRequired
		}).isRequired
	),
	event: PropTypes.object.isRequired,
	onComplete: PropTypes.func.isRequired,
	fetchAdminRequestList: PropTypes.func.isRequired,
	adminPermission: PropTypes.func.isRequired
};

function mapStateToProps(state) {
	return {
		event: state.pouchDB.visibleEvent
	};
}

const mapDispatchToProps = (dispatch) => ({
	fetchAdminRequestList: async (userId, eventDocId) => {
		return await Event.fetchAdminRequestList(userId, eventDocId);
	},
	adminPermission: async (userId, eventDocId, permissionDocId, type) => {
		return await Event.adminPermission(userId, eventDocId, permissionDocId, type);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminRequest);
