import { Alert, Button, Input, Modal, Typography } from 'antd';
import { Pencil } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEditingAllowed } from '../helpers/dateHelpers';

const { Title, Text } = Typography;

const EditEventNameModal = ({ event, userId, onEdit }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [newEventName, setNewEventName] = useState('');
	const [error, setError] = useState(null);

	useEffect(() => {
		if (isModalVisible) {
			setNewEventName(event.name);
			setError(null);
		}
	}, [isModalVisible, event.name]);

	const showModal = () => setIsModalVisible(true);
	const handleCancel = () => setIsModalVisible(false);

	const handleEdit = () => {
		if (newEventName.trim() === '') {
			setError('Event name cannot be empty.');
			return;
		}

		if (newEventName.trim() === event.name.trim()) {
			setError('New event name must be different from the current name.');
			return;
		}

		onEdit(userId, event, newEventName);
		setIsModalVisible(false);
	};

	const renderEditButton = () => (
		<Pencil
			style={{
				height: '16px',
				width: '16px',
				cursor: 'pointer'
			}}
			onClick={showModal}
		/>
	);

	const renderModalContent = () => {
		if (!isEditingAllowed(event.createdAt)) {
			return (
				<Alert
					message="Event name cannot be edited"
					description={
						<>
							For security reasons, the event name cannot be edited after 60 days from creation. If
							you need to change it, please contact support at{' '}
							<a href="mailto:support@kamero.in">support@kamero.in</a>.
						</>
					}
					type="info"
					showIcon
				/>
			);
		}

		return (
			<>
				<Text>Enter the new name for the event:</Text>
				<Input
					value={newEventName}
					onChange={(e) => setNewEventName(e.target.value)}
					placeholder="New Event Name"
					maxLength={50}
					style={{ marginTop: '8px', marginBottom: '16px' }}
				/>
				{error && <Alert message={error} type="error" showIcon style={{ marginBottom: '16px' }} />}
			</>
		);
	};

	const modalFooter = isEditingAllowed(event.createdAt)
		? [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="submit" type="primary" onClick={handleEdit}>
					Save Changes
				</Button>
		  ]
		: [
				<Button key="back" onClick={handleCancel}>
					Cancel
				</Button>,
				<Button key="support" type="primary" href="mailto:support@kamero.in">
					Contact Support
				</Button>
		  ];

	return (
		<>
			{renderEditButton()}
			<Modal
				title={<Title level={4}>Edit Event Name</Title>}
				open={isModalVisible}
				onCancel={handleCancel}
				footer={modalFooter}>
				{renderModalContent()}
			</Modal>
		</>
	);
};

EditEventNameModal.propTypes = {
	event: PropTypes.object.isRequired,
	userId: PropTypes.string.isRequired,
	onEdit: PropTypes.func.isRequired
};

export default EditEventNameModal;
