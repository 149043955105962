import React from 'react';
import QRCodeGenerator from './QRCodeGenerator';
import ls from 'local-storage';
import { Divider } from 'antd';

const ShareQR = ({ shareDetail, auth, eventName }) => {
	return (
		<div>
			<h3>📱 QR Code for Mobile App</h3>
			<QRCodeGenerator data={shareDetail.commonAppLink} auth={auth} eventName={eventName} />
			<Divider />
			<h3>🌐 QR Code for Web Link</h3>
			<QRCodeGenerator data={shareDetail.webLink} auth={auth} eventName={eventName} />
		</div>
	);
};

export default ShareQR;
