import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Progress, Alert, Tooltip, Switch, Spin } from 'antd';
import { bindAll } from 'lodash';
import ls from 'local-storage';
import ImageUploader from '../helpers/ImageUploader';
import { ReactComponent as DragDropSVG } from '../svg/DragDropIcon.svg';
import { CloudUpload } from 'lucide-react';

const SUPPORTED_IMAGES = ['image/jpg', 'image/jpeg'];

class UploadComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMobile: window.screen.width <= 576,
			isProcessing: false
		};
		bindAll(this, ['startUpload', 'handleFile', 'drop', 'allowDrop', 'handleWindowResize']);
		this.uploader = new ImageUploader();
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	UNSAFE_componentWillMount() {
		const { photosUploaded, uploadProgressUpdated, uploadFinished, uploadError } = this.props;
		var that = this;
		this.uploader.setOnProgressListener((status, pending, info) => {
			console.log('setOnProgressListener: ' + status);
			if (status === 'active') {
				uploadProgressUpdated(pending);
				return;
			}

			console.timeEnd('UPLOAD TOTAL TIME');
			photosUploaded(that.props.event, that.props.album);
			if (status === 'complete') {
				uploadFinished(info);
				setTimeout(() => {
					uploadFinished(undefined);
				}, 10000);
				return;
			}

			console.log(info);
			uploadError(info);
		});
	}

	drop = (e) => {
		e.preventDefault();
		this.setState({ isProcessing: true });
		console.log('starting image processing...');
		const { uploadError } = this.props;
		var fileArray = [];
		var cursor = 0;
		let files = e.dataTransfer.files;
		for (; cursor < files.length; ++cursor) {
			let file = files[cursor];
			if (SUPPORTED_IMAGES.indexOf(file.type) === -1) {
				uploadError('Only jpegs images are supported.');
				return;
			}
			fileArray.push(file);
		}
		this.startUpload(fileArray);
	};

	allowDrop = (e) => {
		e.preventDefault();
	};

	handleFile(e) {
		e.preventDefault();
		console.log('starting image processing...');
		this.setState({ isProcessing: true });
		var fileArray = [];
		var cursor = 0;
		for (; cursor < e.target.files.length; ++cursor) {
			fileArray.push(e.target.files[cursor]);
		}
		this.startUpload(fileArray);
		e.target.value = null;
	}

	startUpload = (fileArray) => {
		const { userId, event, album, uploadStarted, uploadError, eventPhotoCount } = this.props;
		if (fileArray.length > 2000) {
			uploadError('Maximum 2000 images can be uploaded at once');
			return;
		}
		uploadStarted(fileArray.length);
		this.uploader.setConfig({
			quality: 0.8,
			autoRotate: true,
			debug: false,
			userId: userId,
			event: event,
			album: album,
			eventPhotoCount: eventPhotoCount
		});
		this.uploader.isCancelled = false;
		this.uploader.uploadFileFromFileArray(fileArray).finally(() => {
			console.log('image processing finished...');
			this.setState({ isProcessing: false });
		});
	};

	isDragNDropSupported = () => {
		var div = document.createElement('div');
		return (
			('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
			'FormData' in window &&
			'FileReader' in window
		);
	};

	render() {
		const { successMessage, uploading, pending, total, error } = this.props;
		let header, footer;

		const isDragNDropSupported = this.isDragNDropSupported();

		let messageElement = null;

		if (uploading) {
			let progress = Math.trunc(((total - pending) / total) * 100);
			let progressLabel = 'Uploading ' + pending + ' photos';
			let progressLabelOnProgressBar = total - pending + '/' + total;

			if (pending === 0) {
				progressLabel = 'Completing Uploads..';
			}

			if (progress < 10) {
				progress = 10;
			}
			header = (
				<div>
					<p>{progressLabel}</p>
				</div>
			);
			footer = (
				<div className="upload-progress">
					<Tooltip className="upload-progress-bar" title={progressLabelOnProgressBar} sty>
						<Progress percent={progress} status="active" />
					</Tooltip>
					<Button
						className="upload-progress-cancel-button"
						onClick={(e) => {
							e.preventDefault();
							this.uploader.cancel();
						}}>
						Cancel
					</Button>
				</div>
			);
		} else {
			if (successMessage) {
				messageElement = (
					<Alert
						type="success"
						message={successMessage}
						style={{ marginTop: '10px' }}
						closable
						showIcon
					/>
				);
			}

			header = (
				<div>
					{isDragNDropSupported && !this.state.isProcessing ? (
						<div>
							<CloudUpload style={{ margin: '10px' }} size={36} />
							<h3 style={{ marginBottom: '15px' }}>
								<strong>Drag and drop your images here!</strong>
							</h3>
							<p></p>
							<h5>or</h5>
						</div>
					) : null}
				</div>
			);

			footer = this.state.isMobile ? (
				<div
					style={{ width: '100%', padding: '10px' }}
					role="button"
					onClick={(e) => {
						document.getElementById('fileUploadInputTypeFile').click();
						document.activeElement.blur();
					}}>
					<Spin spinning={this.state.isProcessing}>
						<input
							type="file"
							id="fileUploadInputTypeFile"
							onChange={this.handleFile}
							multiple="multiple"
							accept="image/.jpeg,image/.jpg"
							style={{ display: 'none' }}
						/>
					</Spin>

					{this.state.isProcessing ? (
						<Spin />
					) : (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								backgroundColor: '#F3F3F3',
								padding: '10px',
								borderRadius: '6px',
								width: '100%'
							}}>
							<DragDropSVG style={{ marginRight: '10px' }} />
							<h3
								style={{
									margin: 0,
									fontSize: '22px',
									color: '#4A4A4A',
									textAlign: 'center',
									fontWeight: 600
								}}>
								Upload Photos
							</h3>
						</div>
					)}
				</div>
			) : (
				<div>
					<input
						type="file"
						id="fileUploadInputTypeFile"
						onChange={this.handleFile}
						multiple="multiple"
						accept="image/jpeg,image/jpg"
						style={{ display: 'none' }}
					/>
					{this.state.isProcessing ? (
						<Spin />
					) : (
						<>
							<Button
								className="btn-gray"
								style={{ margin: '15px', marginBottom: '20px', width: '200px' }}
								onClick={(e) => {
									e.preventDefault();
									document.getElementById('fileUploadInputTypeFile').click();
									document.activeElement.blur();
								}}>
								Browse to Upload
							</Button>
							<p style={{ marginBottom: '5px' }}>Maximum 2000 images can be uploaded at once</p>
						</>
					)}
				</div>
			);
		}

		if (error) {
			const { uploadError } = this.props;
			messageElement = (
				<Alert
					type="error"
					message={`Upload Error! ${error}`}
					style={{ marginTop: '10px' }}
					closable
					showIcon
					afterClose={() => uploadError(undefined)}
				/>
			);
		}

		if (isDragNDropSupported && uploading === false && !this.state.isProcessing) {
			if (!ls.get('addWatermarkDuringUpload')) {
				ls.get('addWatermarkDuringUpload', '1');
			}
			let addWatermark = ls.get('addWatermarkDuringUpload');

			return (
				<div className="uploadContainerWithDragDrop" onDrop={this.drop} onDragOver={this.allowDrop}>
					{messageElement}
					<div className="switchHolder upload-container-watermark-option">
						{this.state.isMobile ? (
							<h4
								style={{
									display: 'inline',
									color: '#111111DE',
									fontWeight: 600,
									fontSize: '20px'
								}}>
								Watermark
							</h4>
						) : (
							<h4 style={{ display: 'inline' }}>Add Watermark?</h4>
						)}
						<Switch
							checked={addWatermark === '0' ? false : true}
							onChange={(e) => {
								ls.set('addWatermarkDuringUpload', addWatermark === '0' ? '1' : '0');
								this.forceUpdate();
							}}
						/>
					</div>
					{!this.state.isMobile && header}
					{footer}
				</div>
			);
		}

		return (
			<div className="uploadContainerWithDragDrop">
				{messageElement}
				{header}
				{footer}
			</div>
		);
	}
}

UploadComp.propTypes = {
	uploading: PropTypes.bool.isRequired,
	pending: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	error: PropTypes.string,
	userId: PropTypes.string.isRequired,
	event: PropTypes.object.isRequired,
	eventPhotoCount: PropTypes.number,
	album: PropTypes.object.isRequired,
	uploadStarted: PropTypes.func.isRequired,
	uploadFinished: PropTypes.func.isRequired,
	uploadProgressUpdated: PropTypes.func.isRequired,
	uploadError: PropTypes.func.isRequired,
	successMessage: PropTypes.string,
	photosUploaded: PropTypes.func.isRequired
};

export default UploadComp;
