import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Carousel, Col, message, Row, Spin, Switch } from 'antd';

import '../styles/Watermark.sass';
import WatermarkPositionComp from './WatermarkPositionComp';
import WatermarkStyleComp from './WatermarkStyleComp';
import FullScreenIndicator from './FullScreenIndicator';
import Watermark from '../pouchDB/models/Watermark';
import { bindAll } from 'lodash';
import { addTextWatermark, addWatermark } from '../helpers/watermark';

import SAMPLE_IMAGE1 from '../images/WatermarkSample1.jpg';
import SAMPLE_IMAGE2 from '../images/WatermarkSample2.jpg';
import SAMPLE_IMAGE3 from '../images/WatermarkSample3.jpg';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CarouselButton = ({ direction = 'left', onClick }) => {
	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 2,
				top: '50%',
				right: direction === 'left' ? '' : '0',
				backgroundColor: '#52525B',
				borderRadius: '100%',
				padding: '5px',
				width: '25px',
				height: '25px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}
			onClick={onClick}>
			{direction === 'left' ? (
				<LeftOutlined style={{ color: 'white' }} />
			) : (
				<RightOutlined style={{ color: 'white' }} />
			)}
		</div>
	);
};

class SetWatermark extends Component {
	constructor(props) {
		super(props);
		this.state = {
			watermarkSaving: false,
			watermark: { position: 3, size: 0 },
			origWatermark: undefined,
			watermarkStyleUpdated: false,
			sampleImage1: SAMPLE_IMAGE1,
			sampleImage2: SAMPLE_IMAGE2,
			sampleImage3: SAMPLE_IMAGE3,
			isMobile: window.screen.width <= 576,
			watermarkType: 'text'
		};
		bindAll(this, [
			'watermarkControls',
			'watermarkChanged',
			'saveWatermark',
			'fetchWatermark',
			'handleWindowResize'
		]);
	}

	async componentDidMount() {
		window.addEventListener('resize', this.handleWindowResize);
		await this.fetchWatermark();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowResize);
	}

	handleWindowResize = async () => {
		if (window.innerWidth <= 576 && !this.state.isMobile) this.setState({ isMobile: true });
		else if (window.innerWidth > 576 && this.state.isMobile) this.setState({ isMobile: false });
	};

	fetchWatermark = async () => {
		const { userId } = this.props;
		try {
			let watermark = await Watermark.getWatermark(userId);
			this.setState({ origWatermark: watermark, watermark: watermark }, () => {
				this.watermarkChanged();
			});
		} catch (err) {
			if (err.status === 404) {
				this.setState({ origWatermark: {} }, () => {
					this.watermarkChanged();
				});
				return;
			}

			message.error(err.response.body.message);
		}
	};

	sampleImages() {
		const { sampleImage1, sampleImage2, sampleImage3 } = this.state;

		if (this.state.isMobile) {
			return (
				<div style={{ position: 'relative' }}>
					<Carousel
						effect="fade"
						dots={false}
						arrows
						adaptiveHeight
						style={{ width: '100%', height: '100%' }}
						prevArrow={<CarouselButton />}
						nextArrow={<CarouselButton direction="right" />}>
						<div style={{ height: '100%', overflow: 'hidden' }}>
							<img src={sampleImage1} style={{ width: '100%', height: '100%' }} />
						</div>
						<div style={{ height: '100%', maxHeight: '500px', overflow: 'hidden' }}>
							<img src={sampleImage2} style={{ width: '100%', height: '100%' }} />
						</div>
						<div style={{ height: '100%', maxHeight: '500px', overflow: 'hidden' }}>
							<img src={sampleImage3} style={{ width: '100%', height: '100%' }} />
						</div>
					</Carousel>
					<h6
						style={{
							fontSize: '16px',
							color: '#4A4A4A',
							textAlign: 'right',
							fontWeight: '500'
						}}>
						PREVIEW
					</h6>
				</div>
			);
		}

		return (
			<Carousel className="watermark-carousel" effect="fade">
				<div className="watermark-carousel-img carousel-img-wrap">
					<img className="carousel-img" src={sampleImage1} alt="" />
				</div>
				<div className="watermark-carousel-img carousel-img-wrap">
					<img className="carousel-img" src={sampleImage2} alt="" />
				</div>
				<div className="watermark-carousel-img carousel-img-wrap">
					<img className="carousel-img" src={sampleImage3} alt="" />
				</div>
			</Carousel>
		);
	}

	watermarkControls() {
		let watermarkBig = false;
		var that = this;

		if (this.state.watermark) {
			watermarkBig = this.state.watermark.size === 1;
		}

		if (this.state.isMobile) {
			return (
				<div>
					<h2
						style={{
							fontSize: '20px',
							color: '#111111DE',
							fontWeight: '500'
						}}>
						Watermark Style
					</h2>
					<WatermarkStyleComp
						image={this.state.watermark.base64Image}
						watermark={this.state.watermark}
						styleChanged={(styleState) =>
							that.setState(
								{
									watermark: {
										...that.state.watermark,
										title:
											styleState.title && styleState.title.length > 0
												? styleState.title
												: undefined,
										subTitle:
											styleState.subTitle && styleState.subTitle.length > 0
												? styleState.subTitle
												: undefined,
										base64Image: styleState.image
									},
									watermarkStyleUpdated: true
								},
								() => {
									that.watermarkChanged();
								}
							)
						}
					/>
					<div style={{ backgroundColor: '#F5F5F5', padding: '10px', margin: '16px 0px' }}>
						<Row type="flex" justify="space-between" align="middle">
							<Col>
								<h4
									style={{
										fontSize: '20px',
										color: '#111111DE',
										textAlign: 'center',
										fontWeight: '500'
									}}>
									Bigger Watermark
								</h4>
							</Col>
							<Col>
								<Switch
									checked={watermarkBig}
									onChange={(newOn) => {
										that.setState(
											{ watermark: { ...this.state.watermark, size: newOn ? 1 : 0 } },
											() => {
												that.watermarkChanged();
											}
										);
									}}
								/>
							</Col>
						</Row>
					</div>
					<h2
						style={{
							fontSize: '20px',
							color: '#111111DE',
							fontWeight: '500'
						}}>
						Watermark Style
					</h2>
					<WatermarkPositionComp
						style={{ marginBottom: '20px' }}
						position={
							this.state.watermark.position !== undefined ? this.state.watermark.position : 3
						}
						positionChanged={(newPosition) => {
							if (newPosition === that.state.watermark.position) {
								return;
							}
							that.setState(
								{ watermark: { ...that.state.watermark, position: newPosition } },
								() => {
									that.watermarkChanged();
								}
							);
						}}
					/>
				</div>
			);
		}

		return (
			<div>
				<WatermarkPositionComp
					style={{ marginBottom: '20px' }}
					position={this.state.watermark.position !== undefined ? this.state.watermark.position : 3}
					positionChanged={(newPosition) => {
						if (newPosition === that.state.watermark.position) {
							return;
						}
						that.setState({ watermark: { ...that.state.watermark, position: newPosition } }, () => {
							that.watermarkChanged();
						});
					}}
				/>
				<WatermarkStyleComp
					image={this.state.watermark.base64Image}
					watermark={this.state.watermark}
					styleChanged={(styleState) =>
						that.setState(
							{
								watermark: {
									...that.state.watermark,
									title:
										styleState.title && styleState.title.length > 0 ? styleState.title : undefined,
									subTitle:
										styleState.subTitle && styleState.subTitle.length > 0
											? styleState.subTitle
											: undefined,
									base64Image: styleState.image
								},
								watermarkStyleUpdated: true
							},
							() => {
								that.watermarkChanged();
							}
						)
					}
				/>
				<Row type="flex" justify="space-between" style={{ marginBottom: '20px' }}>
					<Col>
						<h4 style={{ display: 'inline' }}>Bigger Watermark</h4>
					</Col>
					<Col>
						<Switch
							checked={watermarkBig}
							onChange={(newOn) => {
								that.setState(
									{ watermark: { ...this.state.watermark, size: newOn ? 1 : 0 } },
									() => {
										that.watermarkChanged();
									}
								);
							}}
						/>
					</Col>
				</Row>
				<p>Please check sample images for watermark size. Toggle the image to see more samples.</p>
			</div>
		);
	}

	watermarkChanged() {
		const watermark = this.state.watermark;
		if (watermark.position === undefined) {
			return;
		}

		if (this.state.watermark.base64Image) {
			addWatermark(
				SAMPLE_IMAGE1,
				this.state.watermark.base64Image,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage1: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			addWatermark(
				SAMPLE_IMAGE2,
				this.state.watermark.base64Image,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage2: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			addWatermark(
				SAMPLE_IMAGE3,
				this.state.watermark.base64Image,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage3: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			return;
		}

		console.log(watermark);
		if (watermark.title && watermark.title.length > 0) {
			addTextWatermark(
				SAMPLE_IMAGE1,
				watermark.title,
				watermark.subTitle,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage1: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			addTextWatermark(
				SAMPLE_IMAGE2,
				watermark.title,
				watermark.subTitle,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage2: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			addTextWatermark(
				SAMPLE_IMAGE3,
				watermark.title,
				watermark.subTitle,
				watermark.position,
				watermark.size
			)
				.then((imageWithWatermark) => {
					this.setState({ sampleImage3: imageWithWatermark });
				})
				.catch((err) => console.log(err));

			return;
		}

		this.setState({
			sampleImage1: SAMPLE_IMAGE1,
			sampleImage2: SAMPLE_IMAGE2,
			sampleImage3: SAMPLE_IMAGE3
		});
	}

	isSaveable = () => {
		if (!this.state.watermark) {
			return false;
		}

		return (
			(this.state.watermarkStyleUpdated ||
				this.state.origWatermark.size !== this.state.watermark.size ||
				this.state.origWatermark.position !== this.state.watermark.position) &&
			(this.state.watermark.base64Image !== undefined || this.state.watermark.title !== undefined)
		);
	};

	saveWatermark = async () => {
		if (!this.isSaveable()) {
			return;
		}

		this.setState({ watermarkSaving: true });
		try {
			console.log(this.state.watermark);
			await Watermark.updateWatermark(this.props.userId, this.state.watermark);
			await this.fetchWatermark();
			this.setState({ watermarkSaving: false, watermarkStyleUpdated: false });
		} catch (err) {
			this.setState({ watermarkSaving: false, watermarkStyleUpdated: false });
			console.log(err);
			message.error(err.response.body.message);
		}
	};

	render() {
		if (!this.state.origWatermark) {
			return <FullScreenIndicator />;
		}

		if (this.state.isMobile) {
			return (
				<div style={{ padding: '30px 0px' }}>
					{this.sampleImages()}
					{this.watermarkControls()}
					<p
						style={{
							fontSize: '18px',
							color: '#52525B',
							fontWeight: 300,
							margin: '10px 0px'
						}}>
						Please check the sample image for watermark size. Swipe the image to see more samples.
					</p>
					<Spin spinning={this.state.watermarkSaving}>
						<Button
							type="primary"
							style={{ width: '100%', height: '100%' }}
							disabled={this.isSaveable() === false}
							onClick={this.saveWatermark}>
							Save changes
						</Button>
					</Spin>
				</div>
			);
		}

		return (
			<div>
				<div className="pageTitleBar">
					<h1 className="pageTitle">Set Watermark</h1>
					<Spin spinning={this.state.watermarkSaving}>
						<Button
							type="primary"
							className="pageRightDetail"
							disabled={this.isSaveable() === false}
							onClick={this.saveWatermark}>
							Save changes
						</Button>
					</Spin>
				</div>
				<Row type="flex" justify="center">
					<Col className="col-watermark-controls">{this.watermarkControls()}</Col>
					<Col className="col-watermark-images">{this.sampleImages()}</Col>
				</Row>
			</div>
		);
	}
}

SetWatermark.propTypes = {
	userId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId
	};
};

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SetWatermark);
