import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { continousReplicationChanged, replicationConfigured } from '../actions/replicationActions';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { replace } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';
import '../styles/App.sass';
import Login from './Login';
import AppWithNavigation from './AppWithNavigation';
import FullScreen from './FullScreen';
import { appLaunched } from '../actions/loginActions';
import Cookie from 'js-cookie';
import { ConfigProvider } from 'antd';

const getBrowser = () => {
	var ua = navigator.userAgent,
		tem,
		M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
	if (/trident/i.test(M[1])) {
		tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
		return { name: 'IE', version: tem[1] || '' };
	}
	if (M[1] === 'Chrome') {
		tem = ua.match(/\bOPR\/(\d+)/);
		if (tem != null) {
			return { name: 'Opera', version: tem[1] };
		}
	}
	M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
	tem = ua.match(/version\/(\d+)/i);
	if (tem !== null) {
		M.splice(1, 1, tem[1]);
	}
	return { name: M[0], version: M[1] };
};

const SUPPORTED_BROWSERS = ['Chrome', 'Safari', 'Firefox', 'Edge'];
const ismobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);
const browser = getBrowser();

class App extends Component {
	static propTypes = {
		children: PropTypes.node
	};

	async componentDidMount() {
		await this.props.appLaunched();
	}

	render() {
		if (SUPPORTED_BROWSERS.indexOf(browser.name) === -1) {
			console.log(browser.name);
			return <BrowserNotSupported />;
		}

		return (
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route exact path="/events/:eventId/:albumIndex/:index" component={FullScreen} />
				<Route path="*" component={AppWithNavigation} />
			</Switch>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		replicationConfigured: state.replication.continousReplicationConfigured
	};
};

const mapDispatchToProps = (dispatch, ownProps) => ({
	replicationConfiguredAction: () => {
		dispatch(replicationConfigured());
	},
	continousReplicationChangedAction: (userId, newState, info) => {
		dispatch(continousReplicationChanged(userId, newState, info));
	},
	appLaunched: async () => {
		dispatch(await appLaunched());
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
