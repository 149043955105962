import {
	closestCenter,
	DndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors
} from '@dnd-kit/core';
import {
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, message } from 'antd';
import { SquareLibrary } from 'lucide-react';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NameEditDeleteItemComp from './NameEditDeleteItemComp';
import TextFieldButtonComp from './TextFieldButtonComp';

const ALBUM_NAME_LENGTH = 20;

const SortableItem = ({
	album,
	sortIndex,
	onAlbumClick,
	visibleEvent,
	albumNameEdited,
	deleteAlbum,
	deletingAlbums,
	clickedAlbum
}) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: album.id
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		marginTop: '10px'
	};

	return (
		<div ref={setNodeRef} style={style} {...attributes} {...listeners}>
			<div onClick={() => onAlbumClick(visibleEvent, album)} className="sortable-row-item">
				<NameEditDeleteItemComp
					key={album.id}
					{...album}
					type={'Album'}
					isActive={album?.id === clickedAlbum?.id}
					itemNameMaxLength={ALBUM_NAME_LENGTH}
					editItemPopupTitle="Edit Album Name"
					editItemPopupPlaceholder="New Album Name"
					deleteItemPopupTitle="Are you sure?"
					deleteItemPopupDetail="This will delete all photos inside this album."
					isBeingDeleted={deletingAlbums.indexOf(album.id) !== -1}
					editItem={(newName) => albumNameEdited(sortIndex, visibleEvent, newName, album)}
					deleteItem={() => deleteAlbum(sortIndex, visibleEvent, album)}
				/>
			</div>
		</div>
	);
};

const AlbumListComp = ({
	albums,
	createAlbum,
	onGuestImageClick,
	visibleEvent,
	guestImageCount,
	guestUploadsLoading,
	onAlbumClick,
	albumNameEdited,
	deleteAlbum,
	deletingAlbums,
	clickedAlbum,
	albumSortOrderChanged
}) => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

	useEffect(() => {
		const handleWindowResize = () => {
			setIsMobile(window.innerWidth <= 576);
		};
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	const handleDragEnd = (event) => {
		const { active, over } = event;
		if (active.id !== over.id) {
			const oldIndex = albums.findIndex((album) => album.id === active.id);
			const newIndex = albums.findIndex((album) => album.id === over.id);
			albumSortOrderChanged(oldIndex, newIndex).catch((e) => {
				message.error(e.response.body.message);
			});
		}
	};

	return (
		<>
			<Col type="flex">
				{!isMobile && (
					<div
						className="event-home-menu-label"
						style={{ marginLeft: '15px', marginBottom: '10px' }}>
						<SquareLibrary color="#6F4898" />
						<h4 style={{ marginLeft: '10px' }}>ALBUMS</h4>
					</div>
				)}
				<TextFieldButtonComp
					textFieldUpdated={createAlbum}
					placeholder="Add new album"
					actionButton="Add"
				/>
				{albums && (
					<DndContext
						sensors={sensors}
						collisionDetection={closestCenter}
						onDragEnd={handleDragEnd}>
						<SortableContext
							items={albums.map((album) => album.id)}
							strategy={verticalListSortingStrategy}>
							{albums.map((album, index) => (
								<SortableItem
									key={album.id}
									album={album}
									sortIndex={index}
									onAlbumClick={onAlbumClick}
									visibleEvent={visibleEvent}
									albumNameEdited={albumNameEdited}
									deleteAlbum={deleteAlbum}
									deletingAlbums={deletingAlbums}
									clickedAlbum={clickedAlbum}
								/>
							))}
						</SortableContext>
					</DndContext>
				)}
			</Col>
			{!guestUploadsLoading && (visibleEvent?.isGuestUploadEnabled || guestImageCount > 0) && (
				<Col
					style={{ marginBottom: '2px', marginTop: '30px', cursor: 'pointer' }}
					onClick={() => {
						onGuestImageClick(visibleEvent, {
							isGuestUploads: true,
							name: 'Guest Uploads',
							id: 'guestUploads'
						});
					}}>
					<div className="event-home-photos-title">
						<div>
							<h2 style={{ marginBottom: '2px', marginTop: '2px' }}>Guest Uploads</h2>
						</div>
					</div>
				</Col>
			)}
		</>
	);
};

AlbumListComp.propTypes = {
	albums: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired
		}).isRequired
	),
	onAlbumClick: PropTypes.func.isRequired,
	onGuestImageClick: PropTypes.func.isRequired,
	createAlbum: PropTypes.func.isRequired,
	albumNameEdited: PropTypes.func.isRequired,
	deleteAlbum: PropTypes.func.isRequired,
	visibleEvent: PropTypes.object.isRequired,
	albumSortOrderChanged: PropTypes.func.isRequired,
	deletingAlbums: PropTypes.arrayOf(PropTypes.string).isRequired,
	clickedAlbum: PropTypes.object,
	guestImageCount: PropTypes.number,
	guestUploadsLoading: PropTypes.bool
};

export default AlbumListComp;
